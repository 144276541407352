import React from "react";
import { useTranslation } from "react-i18next";

import { IntIcon } from "components/Shared/Icon";

import { DATE, STRING } from "constants/constants";

const CustomAttributeOption = ({
  onOptionAttributeRemoveHandler,
  customDateAttributesDelete,
  customDate,
  filtersArray,
  memoizedFilters,
  isFilterEmpty,
  setDateFilter,
  dateFilter,
}) => {
  const { t } = useTranslation();

  const dateFilterKeys = Object.keys(dateFilter);
  let filter = null;

  if (dateFilterKeys?.length) {
    if (dateFilterKeys?.length === 1)
      filter = `${dateFilter[dateFilterKeys[0]]}`;
    else if (dateFilterKeys?.length && dateFilterKeys?.length === 2)
      filter = `${dateFilter[dateFilterKeys[0]]} to ${
        dateFilter[dateFilterKeys[1]]
      }`;
  }

  const date = customDate.length
    ? [{ startDate: customDate?.[0], endDate: customDate?.[1] }]
    : filter;

  const optionsData = [
    {
      title: t("company.companyName"),
      options: memoizedFilters[filtersArray[0]],
      onClick: onOptionAttributeRemoveHandler,
    },
    {
      title: t("company.companyOwner"),
      options: memoizedFilters[filtersArray[1]],
      onClick: onOptionAttributeRemoveHandler,
    },
    {
      title: t("company.companySlug"),
      options: memoizedFilters[filtersArray[2]],
      onClick: onOptionAttributeRemoveHandler,
    },
    {
      title: t("applicantSummaryReport.date"),
      options: date,
      onClick: customDateAttributesDelete,
    },
  ];

  const getRequisitionNumber = (requisitionNum) => {
    if (!requisitionNum) return null;

    return ` (${requisitionNum})`;
  };

  if (isFilterEmpty) return null;

  return (
    <div className="mt-4 d-flex flex-column gap-2">
      {optionsData.map((data, index) => {
        const isDateTitle = data.title === DATE;
        const dateFilterClassName = isDateTitle ? "attributes-container" : "";
        const isDataOptionsString = typeof data.options === STRING;
        const isDataOptions = data.options && data.options.length > 0;

        return (
          <ReportDataFilter
            index={index}
            data={data}
            dateFilterClassName={dateFilterClassName}
            getRequisitionNumber={getRequisitionNumber}
            filtersArray={filtersArray}
            isDataOptionsString={isDataOptionsString}
            setDateFilter={setDateFilter}
            isDateTitle={isDateTitle}
            isVisible={isDataOptions}
          />
        );
      })}
    </div>
  );
};

export default CustomAttributeOption;

export const CloseIcon = ({ onClick, isVisible }) => {
  if (!isVisible) return null;

  return (
    <div className="cursor-pointer" onClick={onClick}>
      <IntIcon name="clearIconAlt" className="attribute-clear-icon" />
    </div>
  );
};

export const ReportDataFilter = ({
  index,
  data,
  dateFilterClassName,
  getRequisitionNumber,
  filtersArray,
  isDataOptionsString,
  setDateFilter,
  isDateTitle,
  isVisible,
}) => {
  if (!isVisible) return null;

  return (
    <div key={index} className="d-flex align-items-center gap-4">
      <div className="text-white attribute-title">{data.title}</div>
      <div className="d-flex gap-2 flex-wrap">
        {typeof data.options === "string" ? (
          <div
            className={`d-inline-flex text-white ${dateFilterClassName} p-2 gap-2`}
          >
            {data.options}
            <CloseIcon
              onClick={() => setDateFilter({})}
              isVisible={isDateTitle}
            />
          </div>
        ) : (
          data.options.map((option, i) => (
            <div
              key={i}
              className="d-flex text-white gap-2 attributes-container p-2 gap-2"
            >
              {option?.label || (
                <span>
                  {option.startDate} to {option.endDate}
                </span>
              )}

              {getRequisitionNumber(option?.requestionsNum)}
              <CloseIcon
                onClick={() =>
                  data.onClick({
                    key: filtersArray[index],
                    valueToRemove: option,
                  })
                }
                isVisible={!isDataOptionsString}
              />
            </div>
          ))
        )}
      </div>
    </div>
  );
};
