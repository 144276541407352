const SIGN_IN = {
  INDEX: "/sign_in",
};

const VERIFICATION = {
  INDEX: "/verification",
};

const OTP_VERIFY = {
  INDEX: "/otp_verify",
};

const FORGOT_PASSWORD = {
  INDEX: "/forgot_password",
};

const NEW_PASSWORD = {
  INDEX: "/new_password",
};

const APPLICANT_SUMMARY_REPORT = {
  INDEX: "/summaryreport",
};

const PROFILE = {
  CHANGE_PASSWORD: "/user/change_password",
};

const SUPER_ADMIN = {
  INDEX: "/",
};

const withId = (url, id) => {
  const route = url.replace(":id", id);

  return route;
};

const withTopicId = (url, id) => {
  const route = url.replace(":topicId", id);

  return route;
};

const withMultipleId = (url, id, subId) => {
  let route = url.replace(":id", id);

  route = route.replace(":subId", subId);

  return route;
};

const withItemsAndPage = (url, id, items, page) => {
  let route = withId(url, id);

  route = route.replace(":items", items);

  route = route.replace(":page", page);

  return route;
};

export {
  SIGN_IN,
  VERIFICATION,
  OTP_VERIFY,
  FORGOT_PASSWORD,
  NEW_PASSWORD,
  PROFILE,
  SUPER_ADMIN,
  APPLICANT_SUMMARY_REPORT,
  withId,
  withTopicId,
  withMultipleId,
  withItemsAndPage,
};

export const replaceIdInURL = (route, id) => {
  const allRoute = route.replace(":id", id);

  return allRoute;
};
