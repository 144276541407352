import React, { useEffect } from "react";

import { t } from "i18next";

import Icon from "components/Shared/Icon";

import { TOASTER_TIMEOUT } from "constants/constants";

const Failed = ({
  showToaster,
  setShowToaster,
  resetToaster,
  text,
  setDroppedRequiredFields,
  droppedRequiredFields,
}) => {
  if (!showToaster) return null;

  const requiredFieldsArray = () => {
    let requiredFieldsArray = [];

    const classSelect = document.querySelectorAll(
      ".required.validated:not(.checked)"
    );

    const requiredFieldsArrayDiv = Array.from(classSelect);

    requiredFieldsArrayDiv.map((e) => requiredFieldsArray.push(e.innerText));

    setDroppedRequiredFields
      ? setDroppedRequiredFields(requiredFieldsArray)
      : "";
  };

  const requiredName = droppedRequiredFields?.map((element) => (
    <span className="required-elements">{element}</span>
  ));

  const textContent = () => requiredName || text;

  useEffect(() => {
    requiredFieldsArray();
  }, []);

  const workflowRequiredMsg = requiredName
    ? t("message.jobs.workflowRequiredPrefix")
    : "";

  const closeToaster = () => {
    const shouldReset = resetToaster?.();

    shouldReset ? resetToaster() : setShowToaster?.(false);
  };

  useEffect(() => {
    setTimeout(() => {
      closeToaster();
    }, TOASTER_TIMEOUT);
  }, [showToaster]);

  return (
    <div
      className="p-3 text-white d-flex align-items-center justify-content-start toasters failed mb-3"
      onClick={() => {
        closeToaster();
      }}
    >
      <Icon name="errorIcon" className="ps-1 status-icon" />
      <section className="toaster-content">
        <h4>{t("attributes.error")}</h4>
        {workflowRequiredMsg}{" "}
        <span className="content-holder">{textContent()}</span>
      </section>
      <Icon
        name="cancellogo"
        onClick={() => {
          closeToaster();
        }}
        onKeyDown={() => {
          closeToaster();
        }}
        className="align-self-start ms-5 d-block"
      />
    </div>
  );
};

export default Failed;
