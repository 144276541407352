import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import SummaryReportApi from "api/Candidate/SummaryReportApi";

import DropdownMultiSelect from "components/DropdownSelect/DropdownMultiSelect";
import { IntIcon } from "components/Shared/Icon";

import ClearFilterButton from "./ClearFilterButton";
import CustomAttributeOption from "./HelperComponent/CustomAttributeOption";

const DataFilterDropdowns = ({
  setApplicantsList,
  setIsFilterCleared,
  customDate,
  setCustomDate,
  setFilters,
  memoizedFilters,
  reset,
  isFilterEmpty,
  setDateFilter,
  dateFilter,
  pageState,
  setSearchedName,
  hasParamsRef,
}) => {
  const { t } = useTranslation();

  const [jobTitles, setJobTitles] = useState([]);
  const [result, setResult] = useState([]);
  const [locations, setLocations] = useState([]);

  // Loading state for each filtering options, fetched from an API.
  const [isJobTitlesLoading, setIsJobTitlesLoading] = useState(false);
  const [isResultsLoading, setIsResultsLoading] = useState(false);
  const [isLocationsLoading, setIsLocationsLoading] = useState(false);

  const ShowClearFilterBtn = !isFilterEmpty;

  const jobTitleChange = (values) => {
    hasParamsRef.current = false;

    setFilters((prev) => ({
      ...prev,
      filteredCompanyName: values,
    }));
  };

  const resultChange = (values) => {
    hasParamsRef.current = false;

    setFilters((prev) => ({
      ...prev,
      filteredResults: values,
    }));
  };

  const locationChange = (values) => {
    hasParamsRef.current = false;

    setFilters((prev) => ({
      ...prev,
      filteredCompanySlug: values,
    }));
  };

  // Get company list
  useEffect(async () => {
    setIsJobTitlesLoading(true);
    const { data } = await SummaryReportApi.getCompanyList();

    const arr = [];

    data?.data?.forEach((companyName) => {
      const obj = {
        label: companyName,
        value: companyName,
      };

      arr.push(obj);
    });

    setJobTitles(arr);
    setIsJobTitlesLoading(false);
  }, []);

  // Get owner list
  useEffect(async () => {
    setIsResultsLoading(true);

    const { data } = await SummaryReportApi.getOwnerList();

    const arr = [];

    data?.data?.forEach((owner) => {
      const obj = {
        label: `${owner?.first_name} ${owner?.last_name}`,
        value: owner?.id,
      };

      arr.push(obj);
    });

    setResult(arr);
    setIsResultsLoading(false);
  }, []);

  // Get company Slug
  useEffect(async () => {
    setIsLocationsLoading(true);
    const { data } = await SummaryReportApi.getCompanySlug();

    const arr = [];

    data?.data?.forEach((slug) => {
      const obj = {
        label: slug,
        value: slug,
      };

      arr.push(obj);
    });

    setLocations(arr);
    setIsLocationsLoading(false);
  }, []);

  const clearFilter = () => {
    hasParamsRef.current = false;

    reset();
    setIsFilterCleared(true);
    setApplicantsList([]);
    setCustomDate([]);
    setToggleMinimizeBtn(false);
    setDateFilter({});
  };

  const data = [
    {
      filterName: t("company.companyName"),
      values: memoizedFilters.filteredCompanyName,
      onChange: jobTitleChange,
      options: jobTitles,
      placeholder: t("company.searchCompanyName"),
      title: t("company.companyName"),
      isDataLoading: isJobTitlesLoading,
    },
    {
      filterName: t("company.companyOwner"),
      values: memoizedFilters.filteredResults,
      onChange: resultChange,
      options: result,
      title: t("company.companyOwner"),
      placeholder: t("company.searchCompanyOwner"),
      isDataLoading: isResultsLoading,
    },
    {
      filterName: t("company.companySlug"),
      values: memoizedFilters.filteredCompanySlug,
      onChange: locationChange,
      options: locations,
      title: t("company.companySlug"),
      placeholder: t("company.searchCompanySlug"),
      isDataLoading: isLocationsLoading,
    },
  ];

  const [toggleMinimizeBtn, setToggleMinimizeBtn] = useState(false);

  useEffect(() => {
    if (ShowClearFilterBtn) {
      setToggleMinimizeBtn(true);
    } else {
      setToggleMinimizeBtn(false);
    }
  }, [ShowClearFilterBtn]);

  const onFilterRemove = ({ key, valueToRemove }) => {
    hasParamsRef.current = false;

    const latestFilter = [...memoizedFilters[key]];

    const latestFilterAfterRemoval = latestFilter.filter(
      (t) => t !== valueToRemove
    );

    setFilters((prev) => ({
      ...prev,
      [key]: latestFilterAfterRemoval,
    }));
  };

  const customDateAttributesDelete = () => {
    hasParamsRef.current = false;

    setCustomDate([]);
  };

  useEffect(() => {
    let initialStages = [];
    let initialResults = [];
    let initialTitles = [];
    let initialLobs = [];
    let initialLocations = [];

    //   //if title is present in url, it is initialized here.
    if (pageState.title && jobTitles.length > 0) {
      const titleList = pageState.title.split(",");

      titleList.forEach((item) => {
        const titleOption = jobTitles.find(
          (jobItem) => jobItem.requestionsNum === item
        );

        if (titleOption) {
          initialTitles.push(titleOption);
        }
      });

      setFilters((prev) => ({
        ...prev,
        filteredCompanyName: initialTitles ? initialTitles : [],
      }));
    }

    // if result is present in url,it is initialized here
    if (pageState.rlevel && result.length > 0) {
      const resultList = pageState.rlevel.split(",");

      resultList.forEach((item) => {
        const intItem = parseInt(item, 10);
        const resultOption = result.find(
          (resultItem) => resultItem.value === intItem
        );

        if (resultOption) {
          initialResults.push(resultOption);
        }
      });

      setFilters((prev) => ({
        ...prev,
        filteredResults: initialResults ? initialResults : [],
      }));
    }

    //if location is present in url, it is initialized here.
    if (pageState.location && locations.length > 0) {
      const locationList = pageState.location.split(",");

      locationList.forEach((item) => {
        const intItem = parseInt(item, 10);
        const locationOption = locations.find(
          (locationItem) => locationItem.value === intItem
        );

        if (locationOption) {
          initialLocations.push(locationOption);
        }
      });

      setFilters((prev) => ({
        ...prev,
        filteredCompanySlug: initialLocations ? initialLocations : [],
      }));
    }

    if (pageState.name) {
      setSearchedName(pageState.name);
    }
  }, [locations, jobTitles, result, pageState.name]);

  const filtersArray = Object.keys(memoizedFilters);

  return (
    <div className="main-dropdown-holder px-4 py-4 mb-5 position-relative">
      <div className="dropdown-container d-flex ">
        {data.map((dataFilter, i) => (
          <div key={i} className={dataFilter.className}>
            <DropdownMultiSelect
              key={i}
              isDataLoading={dataFilter.isDataLoading}
              keyVariable={dataFilter?.keyVariable}
              placeholder={dataFilter.placeholder}
              DropdownTitle={dataFilter.filterName}
              options={dataFilter?.options}
              value={dataFilter?.values}
              isMulti={true}
              onSelectChange={dataFilter.onChange}
              title={dataFilter.title}
            />
          </div>
        ))}
        <ClearFilterButton
          show={ShowClearFilterBtn}
          iconColor="red"
          clearFilter={clearFilter}
        />
      </div>

      {toggleMinimizeBtn ? (
        <CustomAttributeOption
          setDateFilter={setDateFilter}
          dateFilter={dateFilter}
          filtersArray={filtersArray}
          memoizedFilters={memoizedFilters}
          onOptionAttributeRemoveHandler={onFilterRemove}
          customDate={customDate}
          isFilterEmpty={isFilterEmpty}
          customDateAttributesDelete={customDateAttributesDelete}
        />
      ) : null}

      {ShowClearFilterBtn ? (
        <div
          className="minimize-btn-container d-flex justify-content-center align-items-center position-absolute "
          onClick={() => setToggleMinimizeBtn(!toggleMinimizeBtn)}
        >
          <div className={toggleMinimizeBtn ? "maximize" : "minimize"}>
            <IntIcon name="minimizeIcon" className="minimize-icon" />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default DataFilterDropdowns;
