import React from "react";

const FormFieldLabelRender = ({ children, isInput }) => {
  if (!isInput) {
    return <></>;
  }

  return <>{children}</>;
};

export default FormFieldLabelRender;
