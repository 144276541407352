import BaseRequest from "api/BaseApi";

const URL = {
  TOKEN: "/oauth/token",
};

class SignInApi {
  /**
   *
   * @param {string} email
   * @param {string} password
   * @param {string} grant_type
   * @param {string} client_id
   * @param {string} client_secret
   * @returns
   */

  static login(email, password, grant_type, client_id, client_secret) {
    return BaseRequest.post(URL.TOKEN, {
      email,
      password,
      grant_type,
      client_id,
      client_secret,
      scope: process.env.REACT_APP_SUPER_ADMIN_SCOPE,
    });
  }
}

export default SignInApi;
