import React from "react";

import Icon from "components/Shared/Icon";

const Loader = ({ isLoading }) => {
  if (!isLoading) {
    return null;
  }

  return (
    <div className="position-absolute loader-wrapper top-50 start-50 translate-middle">
      <Icon className="loader-green" name="LoaderAnimation" />
    </div>
  );
};

export default Loader;
