import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import "bootstrap/dist/js/bootstrap";

import { PROFILE } from "containers/Routes/routes";

import Down from "assets/images/chevron-down-icon.svg";

import { AuthSetupContext } from "contextApi/AuthSetupContext";

const Header = () => {
  const { t } = useTranslation();
  const { handleLogout, userInfo } = useContext(AuthSetupContext);

  const fullName = userInfo?.first_name + " " + userInfo?.last_name;

  return (
    <div className="dropdown profileInfo">
      <div
        className="dropDownContainer d-flex justify-content-center"
        id="profileInfoDropdown"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {userInfo?.profile_image_url ? (
          <img
            className="profile-image me-2"
            src={userInfo?.profile_image_url}
            alt={fullName}
          />
        ) : (
          <div className="profile-image me-2 text-center">
            {userInfo?.first_name?.split("")[0]}
            {userInfo?.last_name?.split("")[0]}
          </div>
        )}
        <div className="d-flex justify-content-center flex-column me-2">
          <span className="profileName">{fullName}</span>
          <span className="profileRole">{t("attributes.superAdmin")}</span>{" "}
        </div>
        <img src={Down} className="ms-3" />
      </div>
      <ul className="dropdown-menu" aria-labelledby="profileInfoDropdown">
        <li>
          <Link
            to={{
              pathname: PROFILE.CHANGE_PASSWORD,
              state: { id: userInfo?.id },
            }}
            className="dropdown-item mt-1"
          >
            {t("common.changePassword")}
          </Link>
          <span className="getLine"></span>
        </li>
        <li className="d-none">
          <Link
            to={{
              pathname: "/company/edit/1",
            }}
            className="dropdown-item mt-1"
          >
            Edit Company Details
          </Link>
          <span className="getLine"></span>
        </li>
        <li>
          <button className="dropdown-item mt-1" onClick={handleLogout}>
            {t("label.logoutDropdown.logout")}
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Header;
