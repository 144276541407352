const ReleaseBanner = ({ handleLatestVersion }) => (
  <div class="d-flex justify-content-center align-items-center border-0 bg-primary p-1 release-banner">
    <p>
      New Updated Version Available!
      <span
        className="m-2 cursor-pointer text-decoration-underline"
        onClick={() => window.location.replace(window.location)}
      >
        Click here to refresh
      </span>
    </p>

    <button
      className="position-absolute btn btn-close btn-small"
      onClick={handleLatestVersion}
    ></button>
  </div>
);

export default ReleaseBanner;
