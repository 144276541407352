import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { FORGOT_PASSWORD } from "containers/Routes/routes";

import Icon from "components/Shared/Icon";
import LoadingState from "components/SignIn/LoadingState";

import { isCapsLockOn } from "utils/getCapsLockStatus";
import { isInputValid } from "utils/isInputValid";

import { validateEmail } from "./../ResetPassword/PasswordValidator/utils";

const INITIAL_PASSWORD_STATE = {
  value: "",
  capsMessage: "",
  show: false,
  hasError: false,
};

const SignInForm = ({ onSubmit, error, flag, isLoading, userEmail }) => {
  const { t } = useTranslation();

  const [email, setEmail] = useState({
    value: userEmail || "",
    error: {
      isEmpty: false,
      isInvalid: false,
    },
  });
  const [password, setPassword] = useState(INITIAL_PASSWORD_STATE);

  const handleSubmit = (e) => {
    e.preventDefault();

    const emailIsEmpty = !isInputValid(email.value);
    const emailIsValid = validateEmail(email.value);
    const passwordIsValid = isInputValid(password.value);

    setEmail((prevState) => ({
      ...prevState,
      error: {
        isEmpty: emailIsEmpty,
        isInvalid: !emailIsValid,
      },
    }));
    setPassword((prevState) => ({ ...prevState, hasError: !passwordIsValid }));

    if (!emailIsValid || emailIsEmpty || !passwordIsValid) return;

    onSubmit(email.value, password.value);
  };

  const emailChangeHandler = (e) => {
    setEmail((prevState) => ({
      ...prevState,
      value: e.target.value?.toLowerCase(),
      error: {
        isEmpty: !isInputValid(e.target.value?.toLowerCase()),
        isInvalid: false,
      },
    }));
  };

  const passwordChangeHandler = (e) => {
    setPassword((prevState) => ({
      ...prevState,
      value: e.target.value,
      hasError: !isInputValid(e.target.value),
    }));
  };

  const handleCaps = (e) => {
    setPassword((prevState) => ({
      ...prevState,
      capsMessage: isCapsLockOn(e) ? t("auth.warning.capsLockOn") : "",
    }));
  };

  const handleShowPassword = () => {
    setPassword((prevState) => ({ ...prevState, show: !prevState.show }));
  };

  const getInvalidEmailMessage = () => {
    if (email.error.isEmpty) return t("auth.error.emailEmptyMessage");
    if (email.error.isInvalid) return t("auth.error.emailInvalidMessage");

    return "";
  };

  const emailFieldErrorClassName =
    flag || email.error.isEmpty || email.error.isInvalid ? "error" : "";
  const emailFieldContainerErrorClassName =
    flag || email.error.isEmpty || email.error.isInvalid ? "field-error" : "";

  const passwordIcon = password.show ? "EyeOn" : "Eye";
  const passwordType = password.show ? "text" : "password";
  const passwordErrorClassName = flag || password.hasError ? "error" : "";
  const passwordContaineErrorClassName =
    flag || password.hasError ? "field-error" : "";
  const passwordErrorText = password.hasError
    ? t("auth.error.passwordEmptyMessage")
    : "";

  const emailTextError = (email.error.isEmpty || email.error.isInvalid) && (
    <span className="text-error ps-2">{getInvalidEmailMessage()}</span>
  );
  const passwordTextError = passwordErrorText && (
    <span className="text-error ps-2">{passwordErrorText}</span>
  );
  const passwordTextWarning = password.capsMessage && (
    <span className="text-warning ps-2">{password.capsMessage}</span>
  );
  const signInTextError = error && <p className="mb-4 error">{error}</p>;

  return (
    <form className="form row m-0" onSubmit={handleSubmit}>
      <div className="col-12">
        <h2 className="h4 mb-4">{t("auth.signUp.signIn")}</h2>
      </div>

      {signInTextError}

      <div className="col-12 email-input mb-4">
        <div className="input-field form-floating">
          <div
            className={`input form-floating position-relative ${emailFieldContainerErrorClassName}`}
          >
            <input
              type="email"
              name="email"
              className={`form-control ${emailFieldErrorClassName}`}
              placeholder={t("auth.signUp.workEmail")}
              aria-label="Email"
              value={email.value}
              onChange={emailChangeHandler}
              required
              data-testid="sign_in_email_textbox"
            />
            <label className={emailFieldErrorClassName} htmlFor="floatingInput">
              {t("auth.signUp.workEmail")}
            </label>
            <figure className="icon position-absolute">
              <Icon name="mail" />
            </figure>
          </div>
          {emailTextError}
        </div>
      </div>

      <div className="col-12 password-input">
        <div className="input-field form-floating">
          <div
            className={`input form-floating position-relative mb-1 ${passwordContaineErrorClassName}`}
          >
            <input
              type={passwordType}
              name="password"
              className={`form-control ${passwordErrorClassName}`}
              placeholder={t("auth.signUp.password")}
              aria-label="Password"
              value={password.value}
              onChange={passwordChangeHandler}
              onKeyUp={handleCaps}
              required
              data-testid="sign_in_password_textbox"
            />
            <label className={passwordErrorClassName} htmlFor="floatingInput">
              {t("auth.signUp.password")}
            </label>
            <figure className="icon position-absolute">
              <span role="button" tabIndex={0} onClick={handleShowPassword}>
                <Icon name={passwordIcon} />
              </span>
            </figure>
          </div>
          <div className="d-flex flex-column gap-1">
            {passwordTextError}
            {passwordTextWarning}
          </div>
        </div>
      </div>

      <div className="col-12 mt-4 mb-3">
        <Link to={FORGOT_PASSWORD.INDEX} className="btn-link">
          {t("auth.signUp.passwordRecover")}
        </Link>
      </div>

      <div className="col-12 mt-2">
        <button
          type="submit disabled"
          className="btn btn-secondary fw-bold text-uppercase w-100"
          onClick={handleSubmit}
        >
          <LoadingState
            isLoading={isLoading}
            loadingLabel={t("auth.signing")}
            defaultLabel={t("auth.signUp.signIn")}
          />
        </button>
      </div>
    </form>
  );
};

export default SignInForm;
