import React from "react";
import { useTranslation } from "react-i18next";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import ReactTooltip from "react-tooltip";
import "react-loading-skeleton/dist/skeleton.css";

import { IntIcon } from "components/Shared/Icon";

import { WINDOW_TARGET_TYPE } from "constants/constants";

import LocalStorageManager, { CONSTANTS } from "utils/localStorageManager";

const TableBody = ({ company, showSkeleton }) => {
  const { t } = useTranslation();
  const superAdminToken = LocalStorageManager.get(CONSTANTS.ACCESS_TOKEN);

  const convertedDateTime = (date) => {
    const newDate = new Date(date);
    const day = String(newDate.getDate()).padStart(2, "0");
    const month = String(newDate.getMonth() + 1).padStart(2, "0");
    const year = newDate.getFullYear();
    let hours = String(newDate.getHours()).padStart(2, "0");
    const minutes = String(newDate.getMinutes()).padStart(2, "0");
    const seconds = String(newDate.getSeconds()).padStart(2, "0");
    let amOrPm = "AM";

    if (hours >= 12) {
      amOrPm = "PM";
      if (hours > 12) {
        hours -= 12;
      }
    }

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds} ${amOrPm}`;
  };

  const openNewTab = (companySlug) => {
    const url = process.env.REACT_APP_CUSTOMER_BASE_URL.replace(
      ":slug",
      companySlug
    ).replace(":access_token", superAdminToken);

    window.open(url, WINDOW_TARGET_TYPE.BLANK, "noopener,noreferrer");
  };

  return (
    <SkeletonTheme baseColor="#222a29" highlightColor="#373737">
      <tbody>
        <tr className="table-data" key={company?.id}>
          <td className="company-id-column">
            {showSkeleton ? <Skeleton /> : company?.id}
          </td>
          <td>{showSkeleton ? <Skeleton /> : company?.name}</td>
          <td>{showSkeleton ? <Skeleton /> : company?.slug}</td>
          <td>{showSkeleton ? <Skeleton /> : company?.owner_name}</td>
          <td>
            {showSkeleton ? (
              <Skeleton />
            ) : (
              convertedDateTime(company?.created_at)
            )}
          </td>
          <td>
            {showSkeleton ? (
              <Skeleton />
            ) : (
              <div
                className={`d-inline-block report-icon-container`}
                data-html={true}
                data-tip={`${t("company.switchTo")} <strong>${
                  company?.name
                }</strong>`}
                data-place="bottom"
                onClick={() => openNewTab(company?.slug)}
              >
                <IntIcon name="switchPortalIcon" className={`report-icon`} />
                <ReactTooltip />
              </div>
            )}
          </td>
        </tr>
      </tbody>
    </SkeletonTheme>
  );
};

export default TableBody;

{
}
