import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { SIGN_IN } from "containers/Routes/routes";

import ModalMail from "assets/images/recovery-mail.svg";

const PasswordRecoveryModal = ({ isVisible }) => {
  const { t } = useTranslation();
  let history = useHistory();

  if (!isVisible) return null;

  const handleModalSubmit = () => {
    history.push(SIGN_IN.INDEX);
  };

  return (
    <div className="modal fade show d-block" id="successModal">
      <div className="modal-dialog modal-dialog-centered" id="modal-dialog">
        <div
          className="modal-content text-center position-absolute"
          id="modal-content"
        >
          <div className="modal-body">
            <img
              className="modal-title mb-4"
              src={ModalMail}
              alt="Email Icon"
            />
            <h2 className="h5 mb-3">
              {t("auth.passwordRecovery.recoverPassword")}
            </h2>
            <div className="text-white">
              <div className="w-75 m-auto">
                {t("auth.passwordRecovery.recoveryEmailSentMessage")}
              </div>
            </div>
            <div className="text-center mt-4">
              <button
                type="button"
                className="btn btn-secondary px-4 text-uppercase"
                onClick={handleModalSubmit}
              >
                {t("auth.passwordRecovery.backToSignIn")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordRecoveryModal;
