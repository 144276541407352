export const errorHandlerInterceptor = async (err, cb) => {
  if (
    err.response &&
    err.response.status === 401 &&
    window.location.pathname !== "/sign_in"
  ) {
    await cb();
  } else {
    throw err;
  }
};
