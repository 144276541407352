import AuthenticatedApi from "api/AuthenticatedApi";
import urlWithFilters from "api/UrlWithFilters";

const API = {
  GET_COMPANY_LIST: "get_all_company/names",
  GET_OWNER_LIST: "get_all_company/owners",
  GET_SLUG_LIST: "get_all_company/slugs",
};

class SummaryReportApi extends AuthenticatedApi {
  //for Slug
  static getCompanySlug() {
    return AuthenticatedApi.get(API.GET_SLUG_LIST);
  }

  // Owner filter
  static getOwnerList() {
    return AuthenticatedApi.get(API.GET_OWNER_LIST);
  }

  // Company filter
  static getCompanyList() {
    return AuthenticatedApi.get(API.GET_COMPANY_LIST);
  }

  static getDataFromFilteredDropdown(filters) {
    const get_url = urlWithFilters("get_all_company", filters);

    return AuthenticatedApi.get(get_url);
  }
}
export default SummaryReportApi;
