import React from "react";

import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getMonth, getYear } from "date-fns";
import range from "lodash/range";

import SelectDropdown from "components/InputFields/SelectDropdown";

import { RANGE_INITIAL } from "constants/constants";

const CustomHeader = ({
  t,
  decreaseMonth,
  date,
  changeMonth,
  selectYear,
  setSelectYear,
  changeYear,
  increaseMonth,
}) => {
  const years = range(RANGE_INITIAL, getYear(new Date()) + 1, 1);

  const yearsArray = years.map((i) => ({ value: i, label: i }));

  const monthsArray = [
    { value: t("monthsValues.january"), label: t("monthsValues.january") },
    { value: t("monthsValues.february"), label: t("monthsValues.february") },
    { value: t("monthsValues.march"), label: t("monthsValues.march") },
    { value: t("monthsValues.april"), label: t("monthsValues.april") },
    { value: t("monthsValues.may"), label: t("monthsValues.may") },
    { value: t("monthsValues.june"), label: t("monthsValues.june") },
    { value: t("monthsValues.july"), label: t("monthsValues.july") },
    { value: t("monthsValues.august"), label: t("monthsValues.august") },
    { value: t("monthsValues.september"), label: t("monthsValues.september") },
    { value: t("monthsValues.october"), label: t("monthsValues.october") },
    { value: t("monthsValues.november"), label: t("monthsValues.november") },
    { value: t("monthsValues.december"), label: t("monthsValues.december") },
  ];

  const yearChangeHandler = (value) => {
    changeYear(value.value);
    setSelectYear(value);
  };

  const decrementYearHandler = () => {
    setSelectYear((prevYear) => ({
      label: prevYear.value - 1,
      value: prevYear.value - 1,
    }));
    changeYear(selectYear.value - 1);
  };

  const incrementYearHandler = () => {
    setSelectYear((prevYear) => ({
      label: prevYear.value + 1,
      value: prevYear.value + 1,
    }));
    changeYear(selectYear.value + 1);
  };

  const rangeHandleClose = () => {
    document.querySelector(".react-datepicker__tab-loop").style.opacity = "0";
  };

  return (
    <>
      <div className="d-flex date-flex justify-content-between">
        <button className="arrow-holder" onClick={decreaseMonth}>
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>
        <div className="month-holder flex-fill">
          <SelectDropdown
            options={monthsArray}
            value={monthsArray[getMonth(date)]}
            onChange={(value) => {
              changeMonth(monthsArray.indexOf(value));
            }}
          />
        </div>
        <div className="dropdown-wrapper position-relative">
          <SelectDropdown
            options={yearsArray}
            value={selectYear}
            onChange={yearChangeHandler}
          />

          <button
            className="position-absolute interactive-click end-0 bottom-0"
            onClick={decrementYearHandler}
          >
            <FontAwesomeIcon icon={faAngleDown} />
          </button>

          <button
            className="position-absolute interactive-click end-0 top-0"
            onClick={incrementYearHandler}
          >
            <FontAwesomeIcon icon={faAngleUp} />
          </button>
        </div>

        <button className="arrow-holder" onClick={increaseMonth}>
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </div>
      <button
        type="button"
        className="cancel-range btn position-absolute py-2 px-5"
        onClick={rangeHandleClose}
      >
        {t("formValues.cancel")}
      </button>
    </>
  );
};

export default CustomHeader;
