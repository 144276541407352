import AuthenticatedApi from "api/AuthenticatedApi";

const NEW_PASSWORD_URL = "/profile";

class NewPasswordApi {
  static updatePassword(data) {
    return AuthenticatedApi.patch(NEW_PASSWORD_URL, data);
  }
}

export default NewPasswordApi;
