import React, { useEffect, useState, useContext } from "react";

import ReleaseBanner from "pages/ReleaseBanner";

import VerticalLogo from "components/LayoutElements/VerticalLogo";
import Logout from "components/Logout/Logout";
import Icon from "components/Shared/Icon";

import { INTELLIANTELOGOPNG } from "constants/images";

import { AuthSetupContext } from "contextApi/AuthSetupContext";

const Header = () => {
  const { isUpdatedVersionAvailable, handleLatestVersion } =
    useContext(AuthSetupContext);

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
  }, []);

  return (
    <>
      {isUpdatedVersionAvailable && (
        <ReleaseBanner handleLatestVersion={handleLatestVersion} />
      )}
      <div
        data-testid="Top-Main-Header"
        className="container-fluid top-header bg-black px-4"
      >
        <div className="d-flex flex-wrap align-items-stretch justify-content-center justify-content-lg-start">
          <div className="d-flex align-items-center">
            <div className="pr-5 pe-2 pe-xl-5">
              <VerticalLogo
                loader={loader}
                vertical_logo_url={INTELLIANTELOGOPNG.SOURCE}
                name={"Intelliante"}
              />
            </div>
          </div>
          <div className="ms-auto">
            <div className="d-flex align-items-center">
              <div className="dropdown ps-4 cursor-pointer sm-ps-0">
                <Logout />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
