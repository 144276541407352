import React from "react";
import { Link } from "react-router-dom";

import { brandingJson } from "constants/brandingJson";

import Footer from "./Footer";
import Header from "./Header";

export const BreadCrumb = ({ path, noBreadCrumb }) => {
  if (noBreadCrumb) return null;

  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb m-0 p-0">
        {path?.map((breadcrumbPath, pathIndex) => {
          const breadcrumbPathRoute = breadcrumbPath?.route;
          const pathLength = path?.length;
          const breadcrumbLabel = breadcrumbPath?.label;

          return (
            <li className="breadcrumb-item active ps-1" aria-current="page">
              <Link
                className={`${
                  (!breadcrumbPathRoute || pathIndex === pathLength - 1) &&
                  "pe-none"
                } text-decoration-none text-capitalize`}
                to={{ pathname: breadcrumbPathRoute }}
              >
                {breadcrumbLabel}
              </Link>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

const Layout = ({ children, setupComplete, active, editProfileClassName }) => (
  <div className="position-relative min-vh-100 layout-holder d-flex flex-column">
    <Header setupComplete={setupComplete} active={active} />
    <div
      className={editProfileClassName || "container-fluid px-4 overflow-hidden"}
    >
      <main className="layout position-relative">{children}</main>
    </div>
    <Footer data={brandingJson.footer} language={"en"} />
  </div>
);

export default Layout;
