import React, { useState } from "react";

import Icon from "components/Shared/Icon";

const PasswordToggle = ({
  password,
  setPassword,
  placeholder,
  passwordError,
}) => {
  const [value, setValue] = useState({
    showPassword: false,
  });

  const [isShowing, setIsShowing] = useState(false);
  const [message, setMessage] = useState("");

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleShowPassword = () => {
    setValue({ ...value, showPassword: !value.showPassword });
    setIsShowing((showing) => !showing);
  };

  const handleCaps = (e) => {
    if (e.getModifierState("CapsLock")) {
      setMessage(`Caps lock is on`);
    } else {
      setMessage("");
    }
  };

  const togglePassword = () => {
    if (!value.showPassword) {
      return "password";
    } else return "text";
  };

  const activeIcon = isShowing ? "EyeOn" : "Eye";
  const checkError = passwordError?.length ? "error" : "";
  const ifErrorClassName = passwordError?.length ? "field-error" : "";

  return (
    <>
      <div
        className={`input form-floating position-relative ${ifErrorClassName}`}
      >
        <input
          type={togglePassword()}
          name="password"
          className={`form-control ${checkError}`}
          aria-label="Password"
          placeholder={placeholder}
          value={password}
          onChange={handleChangePassword}
          onKeyUp={handleCaps}
          onClick={handleCaps}
        />
        <label className={`${checkError}`} htmlFor="floatingInput">
          {placeholder}
        </label>
        <figure className="icon position-absolute">
          <span role="button" tabIndex={0} onClick={handleShowPassword}>
            <Icon name={activeIcon} />
          </span>
        </figure>
      </div>
      <div className="error">{passwordError}</div>
      <p className="text-warning">{message}</p>
    </>
  );
};

export default PasswordToggle;
