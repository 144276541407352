import { BASE_SLUG_KEY } from "constants/constants";

import { getKey } from "./getKey";

export const CONSTANTS = {
  BEARER: "Bearer",
  CLIENT_ID: process.env.REACT_APP_SUPER_ADMIN_CLIENT_ID,
  CLIENT_SECRET: process.env.REACT_APP_SUPER_ADMIN_CLIENT_SECRET,
  TOKEN: "token",
  PASSWORD: "password",
  ACCESS_TOKEN: "access_token",
  TOKEN_TYPE: "token_type",
  LOGGED_IN: "logged_in",
  SUPER_ADMIN: "super_admin",
  SCOPE: process.env.REACT_APP_SUPER_ADMIN_SCOPE,
};
class LocalStorageManager {
  static storage = window.localStorage;

  static get(key) {
    const data = this.storage.getItem(getKey(key));

    if (data) return JSON.parse(data);

    return data;
  }

  static set(key, value) {
    this.storage.setItem(getKey(key), JSON.stringify(value));
  }

  static remove(key) {
    this.storage.removeItem(getKey(key));
  }

  static clear() {
    const items = { ...this.storage };
    const keys = Object.keys(items);

    keys.map((key) => {
      if (key.indexOf(BASE_SLUG_KEY) > -1) {
        this.storage.removeItem(key);
      }
    });
  }
}

export default LocalStorageManager;
