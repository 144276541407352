import BaseRequest from "api/BaseApi";

const REGENERATE_OTP = "/regenerate_otp";
const VERIFY_OTP = "/verify";

class OtpVerifyApi {
  static post(userData) {
    return BaseRequest.post(REGENERATE_OTP, userData);
  }

  static verifyOtpCode(userData) {
    return BaseRequest.post(VERIFY_OTP, userData);
  }
}

export default OtpVerifyApi;
