import React, { useState, useEffect, useLayoutEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import AppVersionApi from "api/AppVersionApi/AppVersionApi";
import Auth from "api/AuthApi/AuthApi";
import axios from "axios";
import { t } from "i18next";

import { SIGN_IN } from "containers/Routes/routes";

import SessionExpireModal from "components/SignIn/SessionExpireModal";
import Failed from "components/Toasters/Failed";

import { HISTORY_PATH } from "constants/Index";

import LocalStorageManager, { CONSTANTS } from "utils/localStorageManager";

import { errorHandlerInterceptor } from "services/requests/interceptor";

export const AuthSetupContext = React.createContext({});

export const AuthSetup = ({ children }) => {
  const location = useLocation();
  const history = useHistory();

  const [isLoggedIn, setLoggedIn] = useState(null);

  const [userInfo, setUserInfo] = useState(false);

  const [updateUser, setUpdateUser] = useState(Date.now());

  const [isUpdatedVersionAvailable, setIsUpdatedVersionAvailable] =
    useState(false);

  const [previousVersion, setPreviousVersion] = useState(0);

  const [isSetPreviousVersion, setIsPreviousVersion] = useState(false);

  const [newVersion, setNewVersion] = useState(0);
  const [showSessionModal, setShowSessionModal] = useState(false);
  const [showToasterFailed, setShowToasterFailed] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);

  const handleLatestVersion = () => {
    setPreviousVersion(newVersion);
    setIsUpdatedVersionAvailable(false);
  };

  const getLatestVersion = async () =>
    await AppVersionApi.getLatestVersion()
      .then((res) => res.json())
      .then((res) => res?.version)
      .catch((err) => err);

  useEffect(() => {
    getLatestVersion().then((version) => {
      setPreviousVersion(version);
      setNewVersion(version);
      setIsPreviousVersion(true);
    });
  }, []);

  useEffect(() => {
    const timer = window.setInterval(() => {
      getLatestVersion()
        .then((v) => {
          if (isSetPreviousVersion && previousVersion < v) {
            setIsUpdatedVersionAvailable(true);
            setNewVersion(v);
          }
        })
        .catch((err) => err);
    }, 600000); // After every 10 mins

    return () => window.clearInterval(timer);
  }, [isSetPreviousVersion, previousVersion]);

  useEffect(() => {
    LocalStorageManager.set(HISTORY_PATH, location.pathname);
  }, [updateUser]);

  const handleLogout = () => {
    LocalStorageManager.clear();

    setLoggedIn(false);
    setUserInfo(false);
    history.replace(SIGN_IN.INDEX);
  };

  const hideSessionModal = () => {
    setShowSessionModal(false);
  };

  useLayoutEffect(() => {
    axios.interceptors.response.use(null, (err) => {
      if (err?.response?.data?.message === "Unauthorized") {
        setShowToasterFailed(true);
      }

      errorHandlerInterceptor(err, () => {
        setShowSessionModal(
          ![SIGN_IN.INDEX].includes(history.location.pathname) && true
        );
      });

      return Promise.reject(err);
    });
  }, []);

  const validLogin = async () => {
    if (LocalStorageManager.get(CONSTANTS.LOGGED_IN)) {
      try {
        const response = await Auth.validLogin();

        setUserInfo(response?.data);
        setLoggedIn(true);
        setIsPageLoading(false);
      } catch (error) {
        setLoggedIn(false);
        LocalStorageManager.set(CONSTANTS.LOGGED_IN, false);
      }
    }
  };

  useEffect(() => {
    validLogin();
  }, [isLoggedIn, updateUser]);

  return (
    <AuthSetupContext.Provider
      value={{
        isLoggedIn,
        setLoggedIn,
        handleLogout,
        userInfo,
        setUpdateUser,
        isUpdatedVersionAvailable,
        handleLatestVersion,
        isPageLoading,
        setIsPageLoading,
      }}
    >
      {children}
      <SessionExpireModal
        isVisible={showSessionModal}
        handleModalValue={hideSessionModal}
      />
      <Failed
        text={t("common.dontHaveAccess")}
        showToaster={showToasterFailed}
        setShowToaster={setShowToasterFailed}
      />
    </AuthSetupContext.Provider>
  );
};
