import React from "react";

import { t } from "i18next";

import { IntIcon } from "components/Shared/Icon";

const NoFilter = () => (
  <div className="d-flex align-items-center justify-content-center no-filter-container flex-column">
    <IntIcon name="noFilter" className="report-icon" />
    <div className="text-center mt-4">
      <h3>{t("applicantSummaryReport.noResultFound")}</h3>
      <p>
        {t("applicantSummaryReport.thereIsNoResult")} <br />
        {t("applicantSummaryReport.filterWith")}
      </p>
    </div>
  </div>
);

export default NoFilter;
