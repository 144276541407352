import React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import zxcvbn from "zxcvbn";

const PasswordStrengthMeter = ({ password, checkAll }) => {
  const { t } = useTranslation();
  const testResult = zxcvbn(password);

  const num = () => {
    switch (testResult.score) {
      case 0:
        return 20;
      case 1:
        return 60;
      case 2:
        if (checkAll === true) {
          return 100;
        } else return 60;
      case 3:
        if (checkAll === true) {
          return 100;
        } else return 60;
      case 4:
        if (checkAll === true) {
          return 100;
        } else return 60;
    }
  };

  const createPassLabel = () => {
    switch (testResult.score) {
      case 0:
        return "Weak";
      case 1:
        return "Good";
      case 2:
        if (checkAll === true) {
          return "Strong";
        } else return "Good";
      case 3:
        if (checkAll === true) {
          return "Strong";
        } else return "Good";
      case 4:
        if (checkAll === true) {
          return "Strong";
        } else return "Good";
    }
  };

  const funcProgressColor = () => {
    switch (testResult.score) {
      case 0:
        return "#EC7474";
      case 1:
        return "#F8DC5E";
      case 2:
        if (checkAll === true) {
          return "#1DB27E";
        } else return "#F8DC5E";
      case 3:
        if (checkAll === true) {
          return "#1DB27E";
        } else return "#F8DC5E";
      case 4:
        if (checkAll === true) {
          return "#1DB27E";
        } else return "#F8DC5E";
    }
  };

  const changePasswordColor = () => ({
    width: `${num()}%`,
    background: funcProgressColor(),
    height: "7px",
  });

  return (
    <>
      <p>
        {t("attributes.passwordStrength")}:{" "}
        <span style={{ color: funcProgressColor() }}>{createPassLabel()}</span>
      </p>
      <div className="progress">
        <div className="progress-bar" style={changePasswordColor()}></div>
      </div>
    </>
  );
};

export default PasswordStrengthMeter;
