import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import moment from "moment";

import DateIcon from "components/Common/DatePicker/DateIcon";
import CustomHeader from "components/DatePickerRange/CustomHeader";
import DatePickerRange from "components/DatePickerRange/DatePickerRange";

import { TIME_CONSTANTS } from "constants/constants";

const DateFilter = ({
  setShowClearFilterBtn,
  isFilterCleared,
  setApplicantsList,
  setIsFilterCleared,
  setCustomDate,
  customDate,
  setDateFilter,
  dateFilter,
  pageState,
}) => {
  const { t } = useTranslation();
  const [rangeValues, setRangeValues] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [filters, setFilters] = useState({});
  const [activeOptions, setActiveOptions] = useState("");

  const [selectYear, setSelectYear] = useState({
    label: new Date().getFullYear(),
    value: new Date().getFullYear(),
  });

  const conditionalRangeClass = rangeValues == "" ? "default" : "has-value";

  const clearRanges = () => {
    setDateRange([0, 0]);
    setFilters([]);
    setRangeValues("");
  };

  useEffect(() => {
    if (customDate.length === 0) {
      setDateRange([0, 0]);
      setRangeValues("");
    }
  }, [customDate]);

  useEffect(() => {
    if (!Object.keys(dateFilter).length && customDate.length === 0)
      setActiveOptions("");
  }, [dateFilter]);

  useEffect(() => {
    if (isFilterCleared) {
      clearRanges();
      setApplicantsList([]);
      setActiveOptions("");
      setShowClearFilterBtn(false);
    }
  }, [isFilterCleared]);

  const toJSONLocal = (date) => {
    let local = new Date(date);

    if (local) {
      local?.setMinutes(date?.getMinutes() - date?.getTimezoneOffset());

      return local?.toJSON()?.slice(0, 10);
    }
  };

  const dateRangeChange = (update) => {
    setRangeValues(update);
    setDateRange(update);

    const [startDate, EndDate] = update;

    const formattedStartDate = toJSONLocal(startDate);
    const formattedEndDate = toJSONLocal(EndDate);

    if (formattedStartDate && formattedEndDate) {
      setCustomDate([formattedStartDate, formattedEndDate]);
      setDateFilter({});
    }
  };

  const activeDay = activeOptions === TIME_CONSTANTS.DAY ? "active-tab" : null;
  const activeWeek =
    activeOptions === TIME_CONSTANTS.WEEK ? "active-tab" : null;
  const activeMonth =
    activeOptions === TIME_CONSTANTS.MONTH ? "active-tab" : null;
  const activeQuarter =
    activeOptions === TIME_CONSTANTS.QUARTER ? "active-tab" : null;
  const activeCustom =
    activeOptions === TIME_CONSTANTS.CUSTOM ? "active-tab" : null;

  const Input = ({ onClick }) => (
    <div className="d-flex align-items-center" onClick={onClick}>
      <input
        type="button"
        className="date-picker-btn"
        onClick={onClick}
        value="Custom"
      />
      <div className="date-icon">
        <DateIcon
          rangeValues=""
          clearRanges={clearRanges}
          position="position-static"
          color="#b8c1bf"
        />
      </div>
    </div>
  );

  const ShowClearFilterBtn = Object.keys(filters).length > 0;

  useEffect(() => {
    setShowClearFilterBtn(ShowClearFilterBtn);
    setIsFilterCleared(false);
  }, [ShowClearFilterBtn]);

  // filtering applicant date according to day
  const todayDate = moment().startOf("day").toDate();
  const formattedEndDate = toJSONLocal(todayDate);

  const dayTabHandler = async () => {
    setShowClearFilterBtn(true);
    setIsFilterCleared(false);
    setActiveOptions(TIME_CONSTANTS.DAY);

    const formattedStartDate = toJSONLocal(todayDate);

    setDateFilter({
      "[filter[created_at][from]]": formattedStartDate,
      "[filter[created_at][to]]": formattedStartDate,
    });
    setCustomDate([]);
  };

  // filtering applicant date according to week
  const weekTabHandler = async () => {
    setShowClearFilterBtn(true);
    setIsFilterCleared(false);
    setActiveOptions(TIME_CONSTANTS.WEEK);

    const startOfWeek = moment().startOf("week").toDate();

    const formattedStartDate = toJSONLocal(startOfWeek);

    setDateFilter({
      "[filter[created_at][from]]": formattedStartDate,
      "[filter[created_at][to]]": formattedEndDate,
    });
    setCustomDate([]);
  };

  // filtering applicant date according to month
  const monthTabHandler = async () => {
    setShowClearFilterBtn(true);
    setIsFilterCleared(false);
    setActiveOptions(TIME_CONSTANTS.MONTH);

    const startOfWeek = moment().startOf("month").toDate();

    const formattedStartDate = toJSONLocal(startOfWeek);

    setDateFilter({
      "[filter[created_at][from]]": formattedStartDate,
      "[filter[created_at][to]]": formattedEndDate,
    });
    setCustomDate([]);
  };

  // filtering applicant date according to quarter
  const quarterTabHandler = async () => {
    setShowClearFilterBtn(true);
    setIsFilterCleared(false);
    setActiveOptions(TIME_CONSTANTS.QUARTER);

    const startOfQuarter = moment().startOf("quarter").toDate();

    const formattedStartDate = toJSONLocal(startOfQuarter);

    setDateFilter({
      "[filter[created_at][from]]": formattedStartDate,
      "[filter[created_at][to]]": formattedEndDate,
    });
    setCustomDate([]);
  };

  //initialize the date filters from query params
  useEffect(() => {
    if (pageState.datefrom && pageState.dateto) {
      setDateFilter({
        "[filter[created_at][from]]": pageState.datefrom,
        "[filter[created_at][to]]": pageState.dateto,
      });
    }

    if (pageState.datefrom && !pageState.dateto) {
      setDateFilter({
        "[filter[created_at][from]]": pageState.datefrom,
      });
    }

    if (pageState.datefromCustom && pageState.datetoCustom) {
      setCustomDate([pageState.datefromCustom, pageState.datetoCustom]);
    }
  }, [
    pageState.datefrom,
    pageState.dateto,
    pageState.datefromCustom,
    pageState.datetoCustom,
  ]);

  return (
    <div className="d-flex text-white date-filter-container align-items-center justify-content-center">
      <div className={`option ${activeDay}`} onClick={dayTabHandler}>
        Day
      </div>
      <div className={`option ${activeWeek}`} onClick={weekTabHandler}>
        Week
      </div>
      <div className={`option ${activeMonth}`} onClick={monthTabHandler}>
        Month
      </div>
      <div className={`option ${activeQuarter}`} onClick={quarterTabHandler}>
        Quarter
      </div>
      <div
        className={`sort-filter datepicker-range d-flex align-items-center position-relative range-date-picker ${conditionalRangeClass} ${activeCustom}`}
        onClick={() => setActiveOptions(TIME_CONSTANTS.CUSTOM)}
      >
        <DatePickerRange
          dateFormat="yyyy-MM-dd"
          className="date-range text-center"
          placeholder={TIME_CONSTANTS.CUSTOM}
          selectsRange={true}
          startDate={startDate}
          CustomInput={Input}
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <CustomHeader
              t={t}
              decreaseMonth={decreaseMonth}
              prevMonthButtonDisable={prevMonthButtonDisabled}
              date={date}
              changeMonth={changeMonth}
              selectYear={selectYear}
              setSelectYear={setSelectYear}
              changeYear={changeYear}
              increaseMonth={increaseMonth}
              nextMonthButtonDisabled={nextMonthButtonDisabled}
            />
          )}
          maxDate={new Date()}
          onChangeRaw={(e) => e.preventDefault()}
          endDate={endDate}
          onChange={dateRangeChange}
          isClearable={true}
        />
      </div>
    </div>
  );
};

export default DateFilter;
