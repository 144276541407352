import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import { SKELETON_UI } from "constants/skeletonColors";

const CompanyFilterSkeleton = () => (
  <SkeletonTheme
    baseColor={SKELETON_UI.PAGINATION_BASE}
    highlightColor={SKELETON_UI.PAGINATION_HIGHLIGHT}
  >
    <div className="d-flex flex-column">
      <div className="px-3 py-2">
        <Skeleton className="mb-2" width={`30%`} height={20} />
        <Skeleton className="mb-1" width={`100%`} height={32} />
      </div>
      {[1, 2, 3, 4, 5].map((skeletonItem) => (
        <>
          <div key={skeletonItem} className="px-3 py-1">
            <Skeleton width={`80%`} height={25} />
          </div>
          <Skeleton width={`100%`} height={1} />
        </>
      ))}
    </div>
  </SkeletonTheme>
);

export default CompanyFilterSkeleton;
