import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import Modal from "components/Shared/Modal/Modal";

import { SessionExpireIcon } from "constants/images";

import { AuthSetupContext } from "contextApi/AuthSetupContext";

const SessionExpireModal = ({ isVisible, handleModalValue }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { handleLogout } = useContext(AuthSetupContext);

  const handleModal = () => {
    handleModalValue();
    handleLogout();
    history.push("/sign_in");
  };

  return (
    <Modal
      isVisible={isVisible}
      className="custom-modal session-modal d-flex justify-content-center flex-column align-items-center"
    >
      <div className="d-flex justify-content-center flex-column align-items-center p-2  z-2">
        <SessionExpireIcon className="mb-3 " />
        <h5>{t("message.auth.sessionExpiryMessage")}</h5>
        <p>{t("message.auth.loginAgainMessage")}</p>
        <button
          type="button"
          className="col-lg-4 col-md-12 mx-lg-0 mt-3 btn btn-yellow shadow-none"
          onClick={handleModal}
        >
          {t("label.login")}
        </button>
      </div>
    </Modal>
  );
};

export default SessionExpireModal;
