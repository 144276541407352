import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import SignInApi from "api/AuthApi/SignInApi";

import {
  OTP_VERIFY,
  FORGOT_PASSWORD,
  SIGN_IN,
  APPLICANT_SUMMARY_REPORT,
} from "containers/Routes/routes";

import Icon from "components/Shared/Icon";

import LocalStorageManager, { CONSTANTS } from "utils/localStorageManager";

import { AuthSetupContext } from "contextApi/AuthSetupContext";

import SignInForm from "./SignInForm";

const SignIn = () => {
  const { t } = useTranslation();
  let history = useHistory();
  const location = useLocation();
  const isLoggedIn = LocalStorageManager.get(CONSTANTS.LOGGED_IN);

  const userEmail = location?.state?.state?.email || "";
  const invitationId = location?.state?.state?.invitationId;

  const { handleLogout } = useContext(AuthSetupContext);

  const [error, setError] = useState("");
  const [flag, setFlag] = useState(false);
  const [unlockTime, setUnlockTime] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const navigateToOTPVerification = (response, email, password) => {
    const encryptedOtpToken = encodeURIComponent(
      response?.data?.encrypted_otp_token
    );

    history.push({
      pathname: OTP_VERIFY.INDEX,
      state: {
        email,
        password,
        historyPath: SIGN_IN.INDEX,
        invitationId,
      },
      search: `?email=${encodeURIComponent(
        email
      )}&otp_token=${encryptedOtpToken}`,
    });
  };

  const handleSubmit = async (email, password) => {
    setIsLoading(true);

    try {
      const response = await SignInApi.login(
        email,
        password,
        CONSTANTS.PASSWORD,
        CONSTANTS.CLIENT_ID,
        CONSTANTS.CLIENT_SECRET,
        CONSTANTS.SCOPE
      );

      navigateToOTPVerification(response, email, password);
    } catch ({ response: { data } }) {
      const message = data?.message;
      const failedAttempts = data?.total_failed_attempts;
      const unlockTime = data?.unlocks_in;

      if (failedAttempts >= 30) {
        history.push({ pathname: FORGOT_PASSWORD.INDEX });
      }

      if (message) {
        setFlag(true);
        setIsLoading(false);
        setUnlockTime(unlockTime);

        let messages = [];

        for (let i = 0; i < message.length; i++) {
          messages.push(message[i]);
        }

        setError(
          typeof message === "string"
            ? t("auth.error.emailOrPasswordInvalid")
            : `${messages.join(" ")}`
        );
      }
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      history.push(APPLICANT_SUMMARY_REPORT.INDEX);
    } else {
      handleLogout();
    }
  }, [isLoggedIn]);

  return (
    <div className="sign-in h-100 position-relative">
      <div className="top-circle position-absolute top-0 start-0">
        <Icon name="TopCircle" />
      </div>
      <div className="bottom-circle position-absolute bottom-0 end-0">
        <Icon name="BottomCircle" />
      </div>
      <div className="container-fluid h-100">
        <div className="row justify-content-center align-items-center h-100">
          <div className="col-md-11 col-lg-9">
            <div className="login-form row justify-content-center align-items-center m-0">
              <div className="bg-image left col-md-6 col-sm-12 p-0">
                <figure className="m-0 bg">
                  <Icon name="bg_icon" />
                </figure>
                <div className="logo">
                  <div className="text-center mb-0 create-account">
                    <figure className="text-center">
                      <h1>
                        <Icon name="LogoIcon" />
                      </h1>
                    </figure>
                    <a
                      className="btn-link ms-1 px-5 btn-lg rounded-2 d-none"
                      href={process.env.REACT_APP_ONBOARDING_SIGNUP_URL}
                    >
                      {t("auth.signUp.createAccount")}
                    </a>
                  </div>
                </div>
              </div>

              <div className="form-container p-4 right col-md-6 col-sm-12">
                <SignInForm
                  userEmail={userEmail}
                  onSubmit={handleSubmit}
                  error={error}
                  flag={flag}
                  isLoading={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
