export const SUPER_ADMIN_FILTER = {
  ITEMS: "items",
  PAGE: "page",
  STATUS: "status",
  TITLE: "title",
  RECOMMENDATION_LEVEL: "rlevel",
  LINE_OF_BUSINESS: "lob",
  LOCATION: "location",
  DATE_FROM: "datefrom",
  DATE_TO: "dateto",
  DATE_FROM_CUSTOM: "datefromCustom",
  DATE_TO_CUSTOM: "datetoCustom",
  NAME: "name",
};

export const TIME_CONSTANTS = {
  DAY: "Day",
  WEEK: "Week",
  MONTH: "Month",
  QUARTER: "Quarter",
  CUSTOM: "Custom",
};

export const PAGE_LOAD_TIME = 2000;

export const RANGE_INITIAL = 1900;

export const LOGO_MAX_WIDTH = "140px";

export const BlUR_SET_TIME = 200;

export const TOASTER_TIMEOUT = 5000;

export const PAGE_TYPES = {
  FORM_PAGE: "form-page",
  TRANSITION_PAGE: "transition-page",
  GAME_PAGE: "assessment-page",
};

export const SIDEBAR_TIMEOUT = 500;

export const BASE_SLUG_KEY = "super_admin_";

export const ENTER_KEY_CODE = 13;

export const TOP = "top";

export const DATE = "Date";

export const STRING = "string";

export const WINDOW_TARGET_TYPE = {
  BLANK: "_blank",
  SELF: "_self",
};

export const PAGINATION = {
  DEFAULT_ITEMS_PER_PAGE: 3,
  DEFAULT_INITIAL_PAGE: 1,
  OPTIONS: [
    { label: 3, value: 3 },
    { label: 6, value: 6 },
    { label: 9, value: 9 },
    { label: 12, value: 12 },
  ],
};
