import React from "react";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";

import Icon from "components/Shared/Icon";

import { ENTER_KEY_CODE } from "constants/constants";

import FormFieldLabelRender from "./FormFieldLabelRender";

export const FormArrayError = ({ name }) => (
  <Field
    name={name}
    subscribe={{ touched: true, error: true }}
    render={({ meta: { dirty, error } }) =>
      dirty && error && typeof error === "string" ? (
        <div className="error-text mt-1 ms-1">{error}</div>
      ) : null
    }
  />
);

FormArrayError.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
};

//input icon is sent on input fields of new password,formats the validation errors
export const FormError = ({ name, label, submitError, inputIcon }) => (
  <Field
    name={name}
    subscribe={{ touched: true, error: true }}
    render={({ meta: { touched, error } }) => {
      const errorClassName = !submitError ? "error-label" : "";
      const errorLabel = !inputIcon ? label : "";

      return (touched && error && typeof error === "string") || submitError ? (
        <div className="error-text mt-1 ms-1">
          {!submitError ? error : ""}
          <span className={` ${errorClassName}`}>{` ${errorLabel}`}</span>
        </div>
      ) : null;
    }}
  />
);

FormError.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
};

export const IconWithButton = ({ inputIcon, handleShowPassword }) => {
  if (!inputIcon) {
    return null;
  }

  return (
    <figure className="icon position-absolute m-0">
      <span role="button" tabIndex={0} onClick={handleShowPassword}>
        <Icon name={inputIcon} />
      </span>
    </figure>
  );
};

export const FormWarning = ({ warningText }) => {
  if (!warningText) {
    return null;
  }

  return <span className="text-warning ms-1">{warningText}</span>;
};

const FormField = ({
  name,
  containerClassName,
  inputClassName,
  labelClassName,
  placeholder,
  validate,
  label,
  textArea,
  rows,
  cols,
  disabled,
  onKeyPress,
  submitError,
  customError,
  onChangeCallBack = () => {},
  onEnterKeyPress = () => {},
  type,
  value,
  inputIcon,
  handleShowPassword,
  onFocus,
  onKeyUp = () => {},
  warningText,
  dataTestID,
}) => {
  const { t } = useTranslation();

  return (
    <Field
      FieldValue={value}
      type={type}
      submitError={submitError}
      name={name}
      containerClassName={containerClassName}
      inputClassName={inputClassName}
      labelClassName={labelClassName}
      placeholderText={t(placeholder)}
      validate={validate}
      fieldLabel={t(label)}
      fieldDisabled={disabled}
      customError={customError}
      inputIcon={inputIcon}
      handleShowPassword={handleShowPassword}
      onFocus={onFocus}
      warningText={warningText}
      format={(value = "") => {
        if (typeof value !== "string") return value;

        return value.trim();
      }}
      formatOnBlur
      render={({
        input: { onChange, ...input },
        inputClassName,
        labelClassName,
        containerClassName,
        placeholderText,
        meta,
        fieldLabel,
        fieldDisabled,
        inputIcon,
        handleShowPassword,
        onFocus,
        warningText,
      }) => {
        const inputClassNames = [];
        const labelClassNames = [];
        const error =
          (meta.touched && meta.error) || submitError ? "error" : null;
        const errorsnew =
          (meta.touched && meta.error) || submitError ? "error-new" : null;

        if (inputClassName) inputClassNames.push(inputClassName);
        if (labelClassName) labelClassNames.push(labelClassName);
        if (error) inputClassNames.push(error);
        if (error) labelClassNames.push(errorsnew);

        const isInput = !textArea;

        const handleOnChange = (e) => {
          onChange(e);
          onChangeCallBack(e);
        };

        const handleKeyDown = (e) => {
          if (e.keyCode === ENTER_KEY_CODE) {
            onEnterKeyPress(e);
          }
        };

        return (
          <div className={containerClassName}>
            <FormFieldLabelRender isInput={!isInput}>
              <textarea
                {...input}
                onChange={handleOnChange}
                onKeyDown={handleKeyDown}
                className={inputClassNames.join(" ")}
                id={input.name}
                placeholder={placeholderText}
                disabled={fieldDisabled}
                rows={rows}
                cols={cols}
                autocomplete={`new-${name}`}
                data-testid={dataTestID}
              />
            </FormFieldLabelRender>

            <FormFieldLabelRender isInput={isInput}>
              <input
                {...input}
                autocomplete={`new-${name}`}
                onChange={handleOnChange}
                onKeyDown={handleKeyDown}
                className={inputClassNames.join(" ")}
                id={input.name}
                placeholder={placeholderText}
                disabled={fieldDisabled}
                onKeyPress={onKeyPress}
                onFocus={onFocus}
                onKeyUp={(e) => onKeyUp(e)}
                data-testid={dataTestID}
              />
            </FormFieldLabelRender>

            <FormFieldLabelRender isInput={!isInput && label}>
              <label htmlFor={input.name} className={labelClassNames.join(" ")}>
                {fieldLabel}
              </label>
            </FormFieldLabelRender>

            <FormFieldLabelRender isInput={isInput && label}>
              <label htmlFor={input.name} className={labelClassNames.join(" ")}>
                {fieldLabel}
              </label>
            </FormFieldLabelRender>

            <FormFieldLabelRender isInput={isInput}>
              <IconWithButton
                inputIcon={inputIcon}
                handleShowPassword={handleShowPassword}
              />
            </FormFieldLabelRender>

            <FormError
              name={input.name}
              submitError={submitError}
              customError={customError}
              label={
                submitError ? submitError : customError ? customError : label
              }
              placeholder={placeholderText ?? input.name}
              inputIcon={inputIcon}
            />

            <FormWarning warningText={warningText} />
          </div>
        );
      }}
    />
  );
};

FormField.defaultProps = {
  containerClassName: "",
  inputClassName: "",
  labelClassName: "",
  placeholder: "",
  disabled: false,
  textArea: false,
};

FormField.propTypes = {
  name: PropTypes.string.isRequired,
  containerClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  placeholder: PropTypes.string,
  validate: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  textArea: PropTypes.bool,
};

export default FormField;
