import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TableHead = ({ showSkeleton, handleSorting }) => {
  const { t } = useTranslation();
  const [sortConfig, setSortConfig] = useState({
    key: "created_at",
    direction: "desc",
  });

  const headings = [
    { title: t("company.companyId"), fixWidth: "13%" },
    { title: t("company.companyName"), fixWidth: "25%" },
    { title: t("company.companySlug"), fixWidth: "17%", sortingKey: "slug" },
    {
      title: t("company.companyOwner"),
      fixWidth: "15%",
      sortingKey: "owner_name",
    },
    {
      title: t("company.onboardingAt"),
      fixWidth: "15%",
      sortingKey: "created_at",
    },
    { title: t("company.action"), fixWidth: "10%" },
  ];

  const onSort = (key) => {
    if (showSkeleton) return;

    let direction = "desc";

    if (sortConfig.key === key && sortConfig.direction === "desc") {
      direction = "asc";
    }

    setSortConfig({ key, direction });
    handleSorting(key, direction);
  };

  const renderSortIcon = (heading) => {
    if (showSkeleton) return null;

    return sortConfig.direction === "desc" &&
      heading.sortingKey === sortConfig.key ? (
      <FontAwesomeIcon className="ms-3" icon={"arrow-down"} color="white" />
    ) : (
      <FontAwesomeIcon
        className={`ms-3 ${
          heading.sortingKey !== sortConfig.key ? "opacity-50" : ""
        }`}
        icon={"arrow-up"}
        color="white"
      />
    );
  };

  return (
    <SkeletonTheme baseColor="#222a29" highlightColor="#373737">
      <thead>
        <tr className="table-head">
          {headings.map((heading, i) => (
            <th
              key={i}
              className={`${heading.sortingKey ? "cursor-pointer" : ""}`}
              style={{ width: heading.fixWidth }}
              onClick={() => heading.sortingKey && onSort(heading.sortingKey)}
            >
              {showSkeleton ? <Skeleton /> : heading.title}
              {heading.sortingKey && renderSortIcon(heading)}
            </th>
          ))}
        </tr>
      </thead>
    </SkeletonTheme>
  );
};

export default TableHead;
