export const MAX_FAILED_ATTEMPTS = 30;

export const RESPONSE_MESSAGES = {
  PASSWORD_SUCCESSFULLY_CHANGED: "Password is successfully updated",
  CONFIRM_ACCOUNT_BEFORE_SIGN_IN:
    "you need to confirm your account before sign in",
  EMAIL_ALREADY_TAKEN: "has already been taken",
  EMAIL_IS_INVALID: "is invalid",
  EMAIL_IS_EMPTY: "can't be blank",
  OTP_LIMIT_EXCEEDED: "Your attempt has exceeded the maximum limits",
  OTP_CODE_EXPIRED: "OTP code expired",
};
