import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, Redirect } from "react-router-dom";

import OtpVerifyApi from "api/AuthApi/OtpVerfiyApi";

import { OTP_VERIFY } from "containers/Routes/routes";

import Icon from "components/Shared/Icon";

const Verify = () => {
  const { t } = useTranslation();
  const [flag, setFlag] = useState(false);
  const [secretKey, setOtpKey] = useState(null);

  let data = useLocation();

  const handleSubmit = async () => {
    const filters = {
      email: encodeURIComponent(data?.state?.email),
    };
    const {
      data: { otp_token: otpToken },
    } = await OtpVerifyApi.get(filters);

    setOtpKey(otpToken);
    setFlag(true);
  };

  return (
    <div className="container-fluid sign-in sign-up verify-page h-100 position-relative">
      <div className="top-circle  position-absolute top-0 start-0">
        <Icon name="TopCircle" />
      </div>
      <div className="bottom-circle position-absolute bottom-0 end-0">
        <Icon name="BottomCircle" />
      </div>
      <div className="logo">
        <figure className="text-center m-0">
          <Icon name="Logo" />
        </figure>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-6 col-lg-5">
          <div className="mt-4 p-4 bg-gray">
            <div className="text-center verify">
              <figure className="mb-5">
                <Icon name="MailIcon" />
              </figure>
              <strong>{t("auth.signUp.verifyEmail")}</strong>
              <p className="mt-4 para">{t("auth.signUp.verifyEmailInfo")}</p>
              <button
                className="mt-4 send-btn btn text-uppercase"
                onClick={handleSubmit}
              >
                {t("label.sendVerificationCode")}
                {flag ? (
                  <Redirect
                    to={{
                      pathname: OTP_VERIFY.INDEX,
                      state: {
                        secretKey: secretKey,
                        email: data.state.email,
                        password: data.state.password,
                      },
                      search: `?email=${encodeURIComponent(data.state.email)}`,
                    }}
                    onClick={handleSubmit}
                    className="mt-4 send-btn btn text-uppercase"
                  />
                ) : (
                  ""
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verify;
