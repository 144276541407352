import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import Layout from "components/Layout/Layout";
import Icon from "components/Shared/Icon";
import ChangePassword from "components/Users/ChangePassword";
import ProfileSidebar from "components/Users/ProfileSidebar";

const ChangePasswordPage = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Layout>
      <div className="applicant-report-wrapper position-relative">
        <div className="hero-section mt-4" id="1">
          <div
            onClick={history.goBack}
            className="d-inline-flex gap-2 align-items-center back-btn mb-5 cursor-pointer"
          >
            <div className="d-flex justify-content-center align-items-center rounded-circle previous-icon-container">
              <Icon name="previous" className="previous-icon" />
            </div>
            <div className="text-white back-btn-text m-0">
              {t("common.back")}
            </div>
          </div>
          <div className="position-absolute download-top"></div>
          <div>
            <ChangePassword />
          </div>
        </div>
        <div className="d-flex mt-5 pt-5 mb-5 justify-content-center"></div>
      </div>
    </Layout>
  );
};

export default ChangePasswordPage;
