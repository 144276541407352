import React from "react";

import { WINDOW_TARGET_TYPE } from "constants/constants";

const FooterContent = ({ prefix, secondText, icon, iconclassName, url }) => (
  <div className="my-1 mx-4 my-sm-2 mx-sm-5 my-lg-3  d-flex align-items-center text-center">
    <a
      href={url}
      target={WINDOW_TARGET_TYPE.BLANK}
      rel="noopener noreferrer"
      style={{
        color: "inherit",
        textDecoration: "none",
        display: "flex",
        alignItems: "center",
      }}
    >
      <img src={icon} alt="icons" className={`${iconclassName} mx-2`} />
      <p className="mb-1 mb-sm-0">
        {prefix} {secondText}
      </p>
    </a>
  </div>
);

const Footer = ({ data, language }) => (
  <footer className="w-100 start-0 bottom-0 mt-auto">
    <div className="footer no-border my-0 px-2 d-flex align-items-center">
      {data.map((item, index) => (
        <FooterContent
          key={index}
          icon={item.icon.url}
          prefix={item.label.locales?.[language]}
          secondText={item.date}
          iconclassName={`${item.className} ${item.customClass}`}
          url={item.link.url}
        />
      ))}
    </div>
  </footer>
);

export default Footer;
