import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch, Redirect } from "react-router-dom";

import { createStore } from "little-state-machine";

import PrivateRoute from "containers/Routes/privateroute";
import {
  SIGN_IN,
  VERIFICATION,
  OTP_VERIFY,
  FORGOT_PASSWORD,
  NEW_PASSWORD,
} from "containers/Routes/routes";
import {
  HOME,
  PROFILE,
  APPLICANT_SUMMARY_REPORT,
} from "containers/Routes/routes";

import CompanyList from "pages/CompanyList/CompanyList";
import PasswordChange from "pages/PasswordReset/PasswordChange";
import PasswordReset from "pages/PasswordReset/PasswordReset";
import OtpPage from "pages/SignIn/OtpPage";
import VerificationPage from "pages/SignIn/VerificationPage";
import ChangePassword from "pages/Users/ChangePasswordPage";

import SignIn from "components/SignIn/SignIn";

import GameContext from "contextApi";

import ErrorPage from "./Error/ErrorPage";

const App = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage("en");
  }, []);

  createStore({});

  return (
    <GameContext>
      <Switch>
        {/* Public Routes */}
        <Route path={SIGN_IN.INDEX}>
          <SignIn />
        </Route>
        <Route path={VERIFICATION.INDEX}>
          <VerificationPage />
        </Route>
        <Route path={OTP_VERIFY.INDEX}>
          <OtpPage />
        </Route>
        <Route path={FORGOT_PASSWORD.INDEX}>
          <PasswordReset />
        </Route>
        <Route path={NEW_PASSWORD.INDEX}>
          <PasswordChange />
        </Route>

        <PrivateRoute
          path={PROFILE.CHANGE_PASSWORD}
          component={ChangePassword}
        />
        <PrivateRoute
          exact
          path={APPLICANT_SUMMARY_REPORT.INDEX}
          component={CompanyList}
        />
        <PrivateRoute exact path="/">
          <Redirect to={APPLICANT_SUMMARY_REPORT.INDEX} />
        </PrivateRoute>
        <Route component={ErrorPage} />
      </Switch>
    </GameContext>
  );
};

export default App;
