import BaseRequest from "api/BaseApi";

const NEW_PASSWORD_URL = "/users/password";

class NewPasswordApi {
  static changePassword(data) {
    return BaseRequest.put(NEW_PASSWORD_URL, data);
  }
}

export default NewPasswordApi;
