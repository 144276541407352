import { COPY_RIGHT, LOGO, SHIELD_EXCLAMATION } from "constants/images";

const currentYear = new Date().getFullYear();

export const brandingJson = {
  header: {
    logo: {
      url: LOGO.SOURCE,
      className: "",
      customClass: "",
      style: {},
    },
    languages: [
      {
        id: 1,
        code: "en",
        label: {
          locales: {
            en: "english",
            es: "spanish english",
          },
        },
      },
      {
        id: 2,
        code: "es",
        label: {
          locales: {
            en: "spanish",
            es: "spanish",
          },
        },
      },
    ],
  },
  footer: [
    {
      id: 1,
      icon: {
        url: COPY_RIGHT.SOURCE,
        className: "",
        customClass: "",
        style: {},
      },
      label: {
        locales: {
          en: `Intelliante, Inc. ${currentYear} `,
          es: `Intelliante, Inc. ${currentYear} `,
        },
      },
      link: {
        url: "https://www.intelliante.com/intelliante-copyright-policy",
      },
      date: "",
    },

    {
      id: 2,
      icon: {
        url: SHIELD_EXCLAMATION.SOURCE,
        className: "",
        customClass: "",
        style: {},
      },
      label: {
        locales: {
          en: "Privacy policy",
          es: "Privacy policy",
        },
      },
      link: {
        url: "https://www.intelliante.com/intelliante-privacy-policy",
      },
      date: "",
    },

    {
      id: 3,
      icon: {
        url: SHIELD_EXCLAMATION.SOURCE,
        className: "",
        customClass: "",
        style: {},
      },
      label: {
        locales: {
          en: "Terms of Use",
          es: "Terms of Use",
        },
      },
      link: {
        url: "https://www.intelliante.com/intelliante-terms-of-use-policy",
      },
      date: "",
    },
  ],
};
