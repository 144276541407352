/**
 *
 * NOTE: Please maintain by alphabetical order; it will be easier to search and look through the file.
 * Add the values according to following labels
 *
 * 1. attributes - This will include site wide attributes values eg: (Email, Name, Password, etc);
 * 2. auth - This will include all the labels for the auth with nested objects;
 *         - Include all the translations inside respective object (eg: login, signUp, passwordReset, etc);
 * 3. common - This will include common translation in app eg: (Job, Candidate, Games, etc);
 * 4. label - This will include all the labels for the buttons eg: (Login, Sign Up, etc);
 * 5. message - This will include common messages that needs to be displayed in app eg: (Email verified, etc);
 * 6. modal - This will include all the labels, description, subheadings for the modal eg: (SignUp modal, Launch setup modal, etc);
 *
 * Translation can be done from english to spanish from: https://translate.google.com/?sl=en&tl=es
 **/

const translation = {
  attributes: {
    action: "Acción",
    address1: "Dirección 1",
    city: "Ciudad",
    email: "Correo electrónico",
    name: "Nombre",
    location: "Ubicación",
    role: "Papel",
    sn: "S.N.",
    user: "Usuario",
  },
  auth: {
    otp: {
      resend: "Reenviar un código de verificación",
      loadingResend: "Enviando código de verificación",
      subHeading: "Ingrese el código OTP de su correo electrónico",
      title: "Ingrese el código OTP aquí",
      invalidOTPMessage: "La OTP ingresada no es válida.",
      otpLimitExceededMessage: "Tu intento ha superado los límites máximos",
      otpCodeExpired: "Código OTP caducado",
    },
    signUp: {
      createAccount: "Crear una nueva cuenta",
      sendVerificationCode: "Send Verification Code",
      verifyEmail: "Awesome! Let's verify your email",
      verifyEmailInfo:
        "To start using intelliante platform you need to verify your email address.",
      termsOfUse: "Condiciones de uso.",
      privacyPolicy: "política de privacidad",
      policyErrorMsg:
        "Debe aceptar nuestra(s) política(s) con la casilla de verificación anterior para continuar.",
    },
    error: {
      emailInvalidMessage:
        "La dirección de correo electrónico no es válida (tú@ejemplo.com)",
    },
  },
  common: {
    add: "Agregar",
    cancel: "Cancelar",
    filter: "Resultado del filtro",
    save: "AHORRAR",
    saveChange: "GUARDAR CAMBIOS",
    withdraw: "RETIRAR",
    delete: "ELIMINAR",
  },

  message: {
    auth: {
      emailVerified: "Correo verificado",
    },

    roles: {
      noRoles: "No se agregaron roles",
      noRolesDetail:
        "No se han agregado roles hasta ahora. Haga clic en el botón de abajo para agregar nuevos roles y obtenerlo en la lista aquí.",
      selection: "Seleccionar todas las gestiones",
    },

    users: {
      add: "AÑADIR NUEVOS USUARIOS",
      bulkUpload: "O agregue varios usuarios con la función de carga masiva",
      edit: "Editar detalles del usuario",
      noUsers: "No hay usuarios añadidos",
      noUsersDetail:
        "No se ha agregado ningún usuario hasta ahora. Haga clic en el botón de abajo para agregar un nuevo usuario y que aparezca aquí",
    },

    jobs: {
      add: "Agregar nuevo trabajo",
      allJobs: "Todos los trabajos",
      noJobs: "No se encontraron trabajos",
      resultsFound: "{{count}} resultados fundar",
      noJobsDetail:
        "No podemos encontrar los trabajos que está buscando. Intenta agregar nuevos trabajos",
      withdraw: {
        confirm: "¿Estás segura de que quieres retirar el trabajo?",
        message: "Haciendo clic en el botón de retirar",
        pointOne: "Los solicitantes que ya están invitados están inhabilitados",
        pointTwo:
          "Solo puede ver los detalles, pero no puede realizar ninguna acción",
      },

      delete: {
        confirm: "¿Estás segura de que quieres eliminar este trabajo?",
        message: "Al hacer clic en el botón eliminar",
        pointOne: "Su trabajo se eliminará de forma permanente.",
        pointTwo: "Después de eliminar el trabajo, no puede restaurarlo.",
      },
    },
  },
  label: {
    launchSetup: "Iniciar configuración",
    otpVerify: "Verifica Ahora",
    roles: {
      add: "Agregar rol",
      addNew: "AÑADIR NUEVOS PAPELES",
    },

    users: {
      add: "Agregar usuario",
    },
  },
  formValues: {
    company: {
      company: "Compañía",
      name: "Nombre",
      url: "URL",
      about: "Acerca de",
      general: "General",
      officeLocations: "Ubicaciones de oficinas",
      branding: "Marca",
      rolesAndPermissions: "Roles y permisos",
      userManagement: "Gestión de usuarios",
      billingAndPlans: "Facturación y planes",
      settings: "Ajustes",
      platformAdminAccount: "Cuenta de administrador de plataforma",
    },
  },
  modal: {
    otpSuccess: {
      createDashboard: "Creemos tu tablero",
    },
  },
  analytics: {
    dontHaveAccess:
      "Esta función no está disponible para usted en este momento.",
  },
};

export default {
  translation,
};
