import React, { useState, useEffect, useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";

import SummaryReportApi from "api/Candidate/SummaryReportApi";
import { t } from "i18next";

import Layout from "components/Layout/Layout";
import Pagination from "components/Shared/Pagination";

import { SUPER_ADMIN_FILTER } from "constants/constants";

import { getQuery } from "utils/getQuery";

import DataFilterDropdowns from "./DataFilterDropdowns";
import DateFilter from "./DateFilter";
import ReportTable from "./ReportTable";
import SearchCompany from "./SearchCompany";

const initialFilterState = {
  filteredCompanyName: [],
  filteredResults: [],
  filteredCompanySlug: [],
};

const CompanyList = () => {
  const [companysList, setApplicantsList] = useState([]);
  const location = useLocation();
  const [showClearFilterBtn, setShowClearFilterBtn] = useState(false);
  const [isFilterCleared, setIsFilterCleared] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [searchedName, setSearchedName] = useState("");
  const [dateFilter, setDateFilter] = useState({});
  const [customDate, setCustomDate] = useState([]);
  const [loadingPage, setLoadingPage] = useState(false);
  const [pageState, setPageState] = useState({});
  const [sortingDirection, setSortingDirection] = useState("desc");
  const [sortingKey, setSortingKey] = useState("created_at");
  const [companysPerPage, setApplicantsJobsPerPage] = useState(
    getQuery(SUPER_ADMIN_FILTER.ITEMS) ? getQuery("items") : 9
  );
  const [currentPage, setCurrentPage] = useState(
    getQuery(SUPER_ADMIN_FILTER.PAGE) ? getQuery("page") : 1
  );

  const hasParams = useRef(false);

  const [pages, setPages] = useState();

  const [
    { filteredCompanyName, filteredResults, filteredCompanySlug },
    setFilters,
  ] = useState(initialFilterState);

  const memoizedFilters = useMemo(
    () => ({
      filteredCompanyName,
      filteredResults,
      filteredCompanySlug,
    }),
    [filteredCompanyName, filteredResults, filteredCompanySlug]
  );

  //checks if the url has query params and disables the history push if
  useEffect(() => {
    if (
      getQuery(SUPER_ADMIN_FILTER.STATUS) ||
      getQuery(SUPER_ADMIN_FILTER.TITLE) ||
      getQuery(SUPER_ADMIN_FILTER.RECOMMENDATION_LEVEL) ||
      getQuery(SUPER_ADMIN_FILTER.LINE_OF_BUSINESS) ||
      getQuery(SUPER_ADMIN_FILTER.LOCATION) ||
      getQuery(SUPER_ADMIN_FILTER.DATE_FROM) ||
      getQuery(SUPER_ADMIN_FILTER.DATE_TO) ||
      getQuery(SUPER_ADMIN_FILTER.DATE_FROM_CUSTOM) ||
      getQuery(SUPER_ADMIN_FILTER.DATE_TO_CUSTOM) ||
      getQuery(SUPER_ADMIN_FILTER.NAME)
    ) {
      hasParams.current = true;
    }
  }, []);

  // getting companys list from filtered dropdowns
  useEffect(async () => {
    setShowSkeleton(true);
    setLoadingPage(true);

    const companyName = filteredCompanyName.map((item) => item.label);
    const companyOwner = filteredResults?.map((item) => item.label);
    const companySlug = filteredCompanySlug?.map((item) => item?.label);

    let filters = {
      items: companysPerPage,
      page: currentPage,
      "sorts[order_by]": sortingKey,
      "sorts[direction]": sortingDirection,
    };

    if (companyName.length > 0) {
      filters = {
        ...filters,
        "filter[name]": companyName?.join(","),
      };
    }

    if (companyOwner.length > 0) {
      filters = {
        ...filters,
        "[filter[owner_name]]": companyOwner.join(","),
      };
    }

    if (companySlug.length > 0) {
      filters = {
        ...filters,
        "filter[slug]": companySlug?.join(","),
      };
    }

    if (customDate.length > 1) {
      filters = {
        ...filters,
        "[filter[created_at][from]]": customDate[0],
        "[filter[created_at][to]]": customDate[1],
      };
    }

    if (searchedName.length > 0) {
      filters = {
        ...filters,
        search_query: searchedName,
      };
    }

    if (Object.keys(dateFilter).length) {
      filters = {
        ...filters,
        ...dateFilter,
      };
    }

    const { data } = await SummaryReportApi.getDataFromFilteredDropdown(
      filters
    );

    setApplicantsList(data?.companies);
    setPages(data?.pagination?.pages);
    setShowSkeleton(false);
    setLoadingPage(false);
  }, [
    filteredCompanyName,
    filteredResults,
    filteredCompanySlug,
    customDate,
    searchedName,
    dateFilter,
    companysPerPage,
    currentPage,
    sortingKey,
    sortingDirection,
  ]);

  const isFilterEmpty =
    filteredCompanyName.length <= 0 &&
    filteredResults.length <= 0 &&
    filteredCompanySlug.length <= 0 &&
    customDate.length <= 0 &&
    !Object.keys(dateFilter)?.length;

  const reset = () => {
    setFilters(initialFilterState);
  };

  useEffect(() => {
    if (!location.state) return;
    const { label, id } = location?.state?.job;
    const values = [
      {
        label: label,
        value: id,
      },
    ];

    setFilters((prev) => ({
      ...prev,
      filteredCompanyName: values,
    }));
  }, []);

  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  useEffect(() => {
    setPageState((prev) => ({
      ...prev,
      title: params[SUPER_ADMIN_FILTER.TITLE] || null,
      rlevel: params[SUPER_ADMIN_FILTER.RECOMMENDATION_LEVEL] || null,
      status: params[SUPER_ADMIN_FILTER.STATUS] || null,
      lob: params[SUPER_ADMIN_FILTER.LINE_OF_BUSINESS] || null,
      location: params[SUPER_ADMIN_FILTER.LOCATION] || null,
      datefrom: params[SUPER_ADMIN_FILTER.DATE_FROM] || null,
      dateto: params[SUPER_ADMIN_FILTER.DATE_TO] || null,
      datefromCustom: params[SUPER_ADMIN_FILTER.DATE_FROM_CUSTOM] || null,
      datetoCustom: params[SUPER_ADMIN_FILTER.DATE_TO_CUSTOM] || null,
      name: params[SUPER_ADMIN_FILTER.NAME] || null,
    }));
  }, []);

  const handleSorting = (key = "created_at", direction = "desc") => {
    setSortingKey(key);
    setSortingDirection(direction);
  };

  return (
    <Layout>
      <div className="my-3 d-flex justify-content-between align-items-center ">
        <h4 className="text-white m-0">{t("company.companyList")}</h4>
        <div className="d-flex gap-3 align-items-center">
          <DateFilter
            setApplicantsList={setApplicantsList}
            setShowClearFilterBtn={setShowClearFilterBtn}
            isFilterCleared={isFilterCleared}
            setIsFilterCleared={setIsFilterCleared}
            setShowSkeleton={setShowSkeleton}
            setCustomDate={setCustomDate}
            customDate={customDate}
            setDateFilter={setDateFilter}
            dateFilter={dateFilter}
            pageState={pageState}
          />
          <SearchCompany
            setShowSkeleton={setShowSkeleton}
            setSearchedName={setSearchedName}
            hasParamsRef={hasParams}
          />
        </div>
      </div>

      <DataFilterDropdowns
        setFilters={setFilters}
        memoizedFilters={memoizedFilters}
        setApplicantsList={setApplicantsList}
        showClearFilterBtn={showClearFilterBtn}
        setIsFilterCleared={setIsFilterCleared}
        setShowSkeleton={setShowSkeleton}
        customDate={customDate}
        setCustomDate={setCustomDate}
        reset={reset}
        isFilterEmpty={isFilterEmpty}
        searchedName={searchedName}
        dateFilter={dateFilter}
        setDateFilter={setDateFilter}
        pageState={pageState}
        setSearchedName={setSearchedName}
        hasParamsRef={hasParams}
      />

      <ReportTable
        companysList={companysList}
        showSkeleton={showSkeleton}
        filteredResults={filteredResults}
        handleSorting={handleSorting}
      />

      {companysList.length > 0 ? (
        <div className="mt-3 overflow-hidden">
          <Pagination
            loading={loadingPage}
            itemsPerPage={companysPerPage}
            setItemsPerPage={setApplicantsJobsPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPage={pages}
            text={t("company.companyPerPages")}
          />
        </div>
      ) : null}
    </Layout>
  );
};

export default CompanyList;
