import React from "react";
import Select from "react-select";

const SelectDropdown = ({
  options,
  onChange,
  placeholder,
  isClearable,
  value,
  defaultValue,
  disableDropdownIndicator,
  className,
}) => {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "#45504D !important",
      color: state.isSelected ? "#FAFEFD" : "#B8C1BF",
      borderBottom: "1px solid #535756",
      textTransform: "capitalize",
      whiteSpace: "nowrap",
      position: "relative",
      display: "flex",
      justifyContent: "start",
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "transparent",
      borderColor: "#B8C1BF",
      boxShadow: "none",
      borderRadius: "0.625em",
      color: state.isSelected ? "#FAFEFD" : "#B8C1BF",
      lineHeight: "1",
      border: "none",
    }),
    menu: (provided) => ({
      ...provided,
      padding: "0",
      borderRadius: "0.625em",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: "0",
      borderRadius: "0.625em",
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      color: "#FAFEFD !important",
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: 0,
      display: "flex",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#FAFEFD",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      opacity: 1,
      color: "#FAFEFD !important",
      display: disableDropdownIndicator ? disableDropdownIndicator : "",
    }),
    placeholder: (base) => ({
      ...base,
      color: "#FAFEFD",
    }),
  };

  return (
    <>
      <Select
        placeholder={placeholder}
        value={value}
        options={options}
        styles={customStyles}
        isClearable={isClearable}
        onChange={onChange}
        isSearchable={false}
        defaultValue={defaultValue}
        classNamePrefix="react-select"
        className={className || ""}
      />
    </>
  );
};

export default SelectDropdown;
