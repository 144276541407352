import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import ResetPasswordApi from "api/AuthApi/ResetPasswordApi";

import PasswordRecoveryModal from "components/Modal/PasswordRecoveryModal";
import Icon from "components/Shared/Icon";
import LoadingState from "components/SignIn/LoadingState";

import { isInputValid } from "utils/isInputValid";
import { CONSTANTS } from "utils/localStorageManager";

import { validateEmail } from "./PasswordValidator/utils";

const INITIAL_EMAIL_STATE = {
  value: "",
  error: {
    isEmpty: false,
    isInvalid: false,
  },
};

const ResetPassword = () => {
  const { t } = useTranslation();

  const [email, setEmail] = useState(INITIAL_EMAIL_STATE);
  const [error, setError] = useState("");
  const [showPasswordRecoveryModal, setShowPasswordRecoveryModal] =
    useState(false);
  const [isSending, setIsSending] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const emailIsEmpty = !isInputValid(email.value);
    const emailIsValid = validateEmail(email.value);

    setEmail((prevState) => ({
      ...prevState,
      error: {
        isEmpty: emailIsEmpty,
        isInvalid: !emailIsValid,
      },
    }));

    if (emailIsEmpty || !emailIsValid) return;

    setIsSending(true);

    try {
      const body = {
        user: {
          email: email.value,
          client_id: CONSTANTS.CLIENT_ID,
        },
      };

      await ResetPasswordApi.resetPassword(body);

      setIsSending(false);
    } catch (err) {
      setIsSending(false);
      setError(err?.response?.data?.error);
    }
    setShowPasswordRecoveryModal(true);
  };

  const emailChangeHandler = (e) => {
    setEmail((prevState) => ({
      ...prevState,
      value: e.target.value?.toLowerCase(),
      error: {
        isEmpty: !isInputValid(e.target.value?.toLowerCase()),
        isInvalid: false,
      },
    }));
  };

  const getEmailMessage = () => {
    if (email.error.isEmpty) return t("auth.error.emailEmptyMessage");
    else if (email.error.isInvalid) return t("auth.error.emailInvalidMessage");
    else return "";
  };

  const emailFieldErrorClassName =
    email.error.isEmpty || email.error.isInvalid ? "field-error" : "";

  const emailFieldContainerErrorClassName =
    email.error.isEmpty || email.error.isInvalid ? "field-error-container" : "";

  return (
    <div className="sign-in sign-up h-100 position-relative">
      <div className="top-circle  position-absolute top-0 start-0">
        <Icon name="TopCircle" alt="Top Circle" />
      </div>
      <div className="bottom-circle position-absolute bottom-0 end-0">
        <Icon name="BottomCircle" alt="Bottom Circle" />
      </div>

      <div className="container-fluid h-100">
        <div className="row justify-content-center align-items-center h-100">
          <div className="col-md-11 col-lg-9">
            <div className="login-form mt-4 row m-0">
              <div className="bg-image left col-md-6 col-sm-12 p-0">
                <figure className="m-0 bg">
                  <Icon name="bg_icon" />
                </figure>
                <div className="logo">
                  <div className="text-center mb-0 create-account">
                    <figure className="text-center">
                      <h1>
                        <Icon name="LogoIcon" />
                      </h1>
                    </figure>
                    <a
                      className="btn-link ms-1 px-5 btn-lg rounded-2 d-none"
                      href={process.env.REACT_APP_ONBOARDING_SIGNUP_URL}
                    >
                      {t("auth.signUp.createAnAccount")}
                    </a>
                  </div>
                </div>
              </div>
              <div className="form-container p-4 right col-md-6 col-sm-12">
                <div className="d-flex flex-column justify-content-center h-100">
                  <form className="form row m-0" onSubmit={handleSubmit}>
                    <div className="col-12">
                      <h2 className="h5 mb-3">
                        {t("auth.passwordRecovery.recoverPassword")}
                      </h2>
                      <p className="mb-4">
                        {t("auth.passwordRecovery.userMessage")}
                      </p>
                      <div
                        className={`input-field form-floating mb-1 ${emailFieldContainerErrorClassName}`}
                      >
                        <input
                          type="email"
                          name="email"
                          className={`bg-transparent form-control ${emailFieldErrorClassName}`}
                          placeholder={t("auth.signUp.email")}
                          aria-label="Email"
                          value={email.value}
                          onChange={emailChangeHandler}
                          required
                          data-testid="recover_password_email_textbox"
                        />
                        <label htmlFor="floatingInput">
                          {t("auth.signUp.email")}
                        </label>
                        <figure className="icon position-absolute">
                          <Icon name="mail" />
                        </figure>
                      </div>
                      <div className="text-error ps-2">
                        {error ? error : getEmailMessage()}
                      </div>
                    </div>

                    <div className="col-12 mt-4">
                      <button
                        type="submit"
                        className="btn btn-secondary text-uppercase w-100"
                        onClick={handleSubmit}
                        data-testid="recover_password_submit_button"
                      >
                        <LoadingState
                          isLoading={isSending}
                          loadingLabel={t("auth.passwordRecovery.sending")}
                          defaultLabel={t(
                            "auth.passwordRecovery.emailRecoveryLink"
                          )}
                        />
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PasswordRecoveryModal isVisible={showPasswordRecoveryModal} />
    </div>
  );
};

export default ResetPassword;
