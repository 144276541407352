import React from "react";
import OtpInput from "react-otp-input";

const OtpInputs = ({ otp, setOtp }) => {
  const handleChange = (otp) => {
    setOtp(otp);
  };

  return <OtpInput value={otp} onChange={handleChange} numInputs={6} />;
};

export default OtpInputs;
