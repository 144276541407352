import React from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";

import "react-datepicker/dist/react-datepicker.css";
const DatePickerRange = ({
  startDate,
  endDate,
  className,
  placeholder,
  onChange,
  dateFormat,
  maxDate,
  onChangeRaw,
  renderCustomHeader,
  CustomInput,
  onCalendarOpen = () => {},
}) => {
  const { t } = useTranslation();

  return (
    <>
      <DatePicker
        customInput={CustomInput ? <CustomInput /> : null}
        dateFormat={dateFormat}
        className={`srem-input ${className}`}
        placeholderText={placeholder ? placeholder : t("attributes.date")}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        onChangeRaw={onChangeRaw}
        maxDate={maxDate}
        renderCustomHeader={renderCustomHeader}
        selectsRange
        isClearable={false}
        showMonthDropdown
        showYearDropdown
        onCalendarOpen={onCalendarOpen}
      />
    </>
  );
};

export default DatePickerRange;
