import React, { useEffect } from "react";

import { t } from "i18next";

import Icon from "components/Shared/Icon";

import { TOASTER_TIMEOUT } from "constants/constants";

const Warning = ({ showToaster, setShowToaster, text }) => {
  if (!showToaster) return null;

  const closeToaster = () => {
    setShowToaster(false);
  };

  useEffect(() => {
    setTimeout(() => {
      closeToaster();
    }, TOASTER_TIMEOUT);
  }, [showToaster]);

  return (
    <div
      onClick={() => closeToaster()}
      className="warning-toast p-3 text-white d-flex align-items-center justify-content-start toasters mb-3"
    >
      <Icon name="Warn" className="ps-1 status-icon" />
      <section className="toaster-content">
        <h4 className="text-light">{t("message.internalError")}</h4>
        {text ? text : t("message.subError")}
      </section>
      <Icon
        name="cancellogo"
        onClick={() => closeToaster(false)}
        onKeyDown={() => closeToaster(false)}
        className="align-self-start ms-5 d-block"
      />
    </div>
  );
};

export default Warning;
