import { useState, useEffect } from "react";

import JobListFooterSkeleton from "components/Skeleton/JoblistFooterSkeleton";

import Icon from "./Icon";

const Pageindices = ({ currentPage, setCurrentPage, totalPage }) => {
  const [pageRange, setPageRange] = useState([]);

  useEffect(() => {
    let array = [];

    for (let i = 0; i < totalPage; i++) {
      array.push(i + 1);
    }
    setPageRange(array);
  }, [totalPage]);

  return (
    <div className="d-flex justfy-content-between px-2 pe-0 indices">
      <span
        key={`0${parseInt(currentPage)}`}
        className="mx-2 my-1"
        onClick={() =>
          setCurrentPage(parseInt(currentPage) === 1 ? 1 : currentPage - 1)
        }
      >
        <Icon name="lessthan" />
      </span>
      <span
        key={`1${parseInt(currentPage)}`}
        className={`mx-2 my-1 cursor-pointer ${
          currentPage == 1 ? "text-muted" : ""
        }`}
        onClick={() =>
          setCurrentPage((currentPage) => Math.max(1, currentPage - 1))
        }
      >
        Prev
      </span>
      <span
        key={`2${parseInt(currentPage)}`}
        className={`pagination-item ${currentPage < 3 ? "d-none" : ""}`}
        onClick={() => setCurrentPage(1)}
      >
        1
      </span>
      <div className={`py-2 ${currentPage <= 3 ? "d-none" : ""}`}>
        <span key={`3${parseInt(currentPage)}`}>...</span>
      </div>
      {currentPage >= 3
        ? pageRange
            .slice(currentPage - 2, currentPage + 1)
            .map((page, index) => (
              <span
                key={index}
                id={page}
                className={`pagination-item ${
                  parseInt(currentPage) === page ? "active" : ""
                }`}
                onClick={() => setCurrentPage(page)}
              >
                {page}
              </span>
            ))
        : pageRange.slice(0, 3).map((item, _index) => (
            <span
              key={`4${item}`}
              id={item}
              className={`pagination-item ${
                parseInt(currentPage) === item ? "active" : ""
              }`}
              onClick={() => setCurrentPage(item)}
            >
              {item}
            </span>
          ))}
      <div className={`py-2 ${currentPage > totalPage - 2 ? "d-none" : ""}`}>
        <span key={`5${parseInt(currentPage)}`}>...</span>
      </div>
      <span
        key={`5${parseInt(currentPage)}`}
        className={`pagination-item ${
          currentPage > totalPage - 3 ? "d-none" : "non-active-job"
        }`}
        onClick={() => setCurrentPage(totalPage)}
      >
        {totalPage}
      </span>
      <span
        key={`6${parseInt(currentPage)}`}
        className={`mx-2 my-1 cursor-pointer ${
          currentPage == totalPage ? "text-muted" : ""
        }`}
        onClick={() =>
          setCurrentPage((currentPage) => Math.min(currentPage + 1, totalPage))
        }
      >
        Next
      </span>
      <span
        key={`7${parseInt(currentPage)}`}
        className="mx-2 my-1"
        onClick={() =>
          setCurrentPage(
            parseInt(currentPage) === totalPage ? totalPage : currentPage + 1
          )
        }
      >
        <Icon name="greatthan" />
      </span>
    </div>
  );
};

const Pagination = ({
  itemsPerPage,
  setItemsPerPage,
  currentPage,
  setCurrentPage,
  totalPage,
  loading,
  text,
  totalApplicantCount,
}) => {
  if (loading) {
    return <JobListFooterSkeleton />;
  }

  return totalApplicantCount <= 3 ? (
    ""
  ) : (
    <div className="d-flex flex-wrap justify-content-between align-items-center white pagination">
      <div>
        <h6 className="white">
          View{" "}
          <select
            className="job-page-select p-1 mx-2 white"
            value={itemsPerPage}
            onChange={(e) => setItemsPerPage(+e.target.value)}
          >
            <option value="3">3</option>
            <option value="6">6</option>
            <option value="9">9</option>
            <option value="12">12</option>
          </select>{" "}
          {text}
        </h6>
      </div>
      {totalApplicantCount <= itemsPerPage ? (
        ""
      ) : (
        <div>
          <Pageindices
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPage={totalPage}
          />
        </div>
      )}
    </div>
  );
};

export default Pagination;
