const renameBaseBath = (basePath) => {
  if (process.env.REACT_DEVELOPER_MODE) {
    basePath = basePath.replace("http://0.0.0.0:3000/", "http://0.0.0.0:3000/");
  }

  return basePath;
};

class ApiUrlConfig {
  static endPoint() {
    let basePath = process.env.REACT_APP_API_BASE_URL;

    return renameBaseBath(basePath);
  }
}

export default ApiUrlConfig;
