import React from "react";
import { useTranslation } from "react-i18next";

import Icon from "components/Shared/Icon";

const ClearFilterButton = ({ show, clearFilter }) => {
  const { t } = useTranslation();

  if (!show) return null;

  return (
    <div
      className="clear-filter d-flex align-items-center px-1 cursor-pointer"
      onClick={clearFilter}
    >
      <p>{t("message.jobs.clearFilter")}</p>
      <Icon name="company_delete" className="ms-2 clear-icon" />
    </div>
  );
};

export default ClearFilterButton;
