import React from "react";
import { NavLink } from "react-router-dom";

import { APPLICANT_SUMMARY_REPORT } from "containers/Routes/routes";

import { LOGO_MAX_WIDTH } from "constants/constants";

const VerticalLogo = ({ loader, vertical_logo_url, name }) => {
  if (loader) {
    return (
      <NavLink to={APPLICANT_SUMMARY_REPORT.INDEX}>
        <img
          src={vertical_logo_url}
          alt={name}
          max-width={LOGO_MAX_WIDTH}
          className="main-logo"
        />
      </NavLink>
    );
  }

  return null;
};

export default VerticalLogo;
