import React, { useState, useEffect } from "react";

import Icon from "components/Shared/Icon";

const SearchCompany = ({ setSearchedName, hasParamsRef }) => {
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      hasParamsRef.current = false;

      setSearchedName(searchTerm);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  return (
    <div className="search-company position-relative h-100">
      <input
        className="pe-4"
        type="text"
        placeholder="Search by company slug"
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <Icon name="search" className="position-absolute search-icon" />
    </div>
  );
};

export default SearchCompany;
