import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const JobListFooterSkeleton = () => (
  <SkeletonTheme baseColor="#222a29" highlightColor="#373737">
    <div className="row justify-content-between footer-skeleton mt-2">
      <div className="left col-6 d-flex align-items-center">
        <Skeleton width={30} height={13} />
        <div className="icon-holder1 me-2 d-flex px-2">
          <Skeleton circle height={15} width={15} className="me-1" />
          <Skeleton circle height={15} width={15} />
        </div>
        <Skeleton width={60} height={13} className="me-2" />
      </div>
      <div className="right col-2 d-flex align-items-center">
        <Skeleton width={30} height={13} className="me-2" />
        <Skeleton width={60} height={13} className="me-3" />
        <Skeleton width={20} height={13} className="me-2" />
        <Skeleton width={20} height={13} className="me-4" />
        <Skeleton width={20} height={13} className="me-3" />
        <Skeleton width={60} height={13} className="me-2" />
        <Skeleton width={30} height={13} />
      </div>
    </div>
  </SkeletonTheme>
);

export default JobListFooterSkeleton;
