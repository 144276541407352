import React, { useState } from "react";
import Select, { components } from "react-select";

import { faAngleDown, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import CompanyFilterSkeleton from "components/Skeleton/Company/CompanyFilterSkeleton";

import { BlUR_SET_TIME } from "constants/constants";

import { selectStyles } from "utils/DropDownTheme";

const { Option } = components;

const IconOption = (props) => (
  <Option {...props}>
    <div className="option-container">
      {props.data.label}{" "}
      {props.data.secondaryText ? (
        <span className="secondary-option-text">
          ({props.data.secondaryText})
        </span>
      ) : null}
    </div>
    {props.isSelected === true && (
      <span className="position-absolute d-inline-block option-icon end-0 me-2 top-50 p-1 rounded-circle">
        <FontAwesomeIcon icon={faCheck} />
      </span>
    )}
  </Option>
);

const DropdownMultiSelect = ({
  title,
  options,
  DropdownTitle,
  placeholder,
  onSelectChange,
  value,
  keyVariable,
  formatOptionLabel,
  defaultValue,
  isMulti,
  isDataLoading,
  style,
  dropdownIndicator = true,
  isHomePage = false,
  menuClass = "",
}) => {
  const [open, setOpen] = useState(false);

  const selectChangeValue = (values) => {
    onSelectChange(values);
  };

  const focusSelect = () => {
    setTimeout(() => {
      setOpen(false);
    }, BlUR_SET_TIME);
  };

  return (
    <>
      <Dropdown
        isOpen={open}
        target={
          <Button
            isHomePage={isHomePage}
            isOpen={open}
            onClick={() => setOpen(!open)}
          >
            {DropdownTitle}

            {(!value || !value.length) && isHomePage && <Span>-</Span>}
            {value && !isHomePage && value.length > 0 && ": "}
            {value && value.length > 0 && (
              <>
                <Span
                  className={`${isHomePage ? "" : "ms-1"}`}
                >{`${value.length} selected`}</Span>
                {dropdownIndicator ? <ChevronDown /> : null}
              </>
            )}
            {dropdownIndicator ? <ChevronDown /> : null}
          </Button>
        }
        menuClass={menuClass}
      >
        {isDataLoading ? (
          <CompanyFilterSkeleton />
        ) : (
          <>
            {title ? <div className="px-3 pt-3">{title}</div> : null}
            <Select
              key={JSON.stringify(keyVariable)}
              autoFocus
              backspaceRemovesValue={false}
              components={{
                Option: IconOption,
                DropdownIndicator,
                IndicatorSeparator: null,
              }}
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              isClearable={false}
              menuIsOpen
              onChange={selectChangeValue}
              options={options}
              placeholder={placeholder}
              styles={style || selectStyles}
              value={value}
              onBlur={focusSelect}
              isMulti={isMulti}
              formatOptionLabel={formatOptionLabel}
              defaultValue={defaultValue}
            />
          </>
        )}
      </Dropdown>
    </>
  );
};

const Menu = ({ menuClass, ...props }) => (
  <div
    className={`menu-dropdown ${menuClass} position-absolute top-100 start-0 mt-3 text-white`}
    {...props}
  />
);

const Dropdown = ({ children, isOpen, target, menuClass }) => (
  <div className="filterholder position-relative d-flex">
    {target}
    {isOpen ? <Menu menuClass={menuClass}>{children}</Menu> : null}
  </div>
);

const Button = ({ isHomePage, children, isOpen, ...props }) => (
  <button
    type="button"
    {...props}
    className={`bg-transparent border-0 ${
      isHomePage ? "" : "px-2 py-1 align-items-center"
    } d-flex justify-content-between w-100 ${isOpen ? "active" : "non-active"}`}
  >
    {children}
  </button>
);

const Span = ({ children, ...props }) => <span {...props}>{children}</span>;

const Svg = (p) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    focusable="false"
    role="presentation"
    {...p}
  />
);
const DropdownIndicator = () => (
  <div className="opacity-0">
    <Svg>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Svg>
  </div>
);

const ChevronDown = () => (
  <span className="ms-2 dropdown-arrows">
    <FontAwesomeIcon icon={faAngleDown} />
  </span>
);

export default DropdownMultiSelect;
