import appVersion from "assets/images/app-version.png";
import bottom from "assets/images/bottom.svg";
import calender from "assets/images/calender.png";
import check from "assets/images/check.svg";
import Checked from "assets/images/checked-icon.svg";
import copyRight from "assets/images/copy-right.png";
import correct from "assets/images/correct.svg";
import info from "assets/images/info.svg";
import intellianteLogoPng from "assets/images/intelliantelogo.png";
import intellianteLogo from "assets/images/intelliantelogo.svg";
import Prev from "assets/images/LeftArrow.svg";
import locked from "assets/images/locked.svg";
import logo from "assets/images/logo-white.png";
import max from "assets/images/maximize.svg";
import pointer from "assets/images/pointer.svg";
import Next from "assets/images/RightArrow.svg";
import shieldExclamation from "assets/images/shield_exclamation.png";
import checkWhite from "assets/images/tick-white.svg";
import uploadImage from "assets/images/Upload-file.svg";
import close from "assets/images/xCircle-icon.svg";

const LOGO = {
  SOURCE: logo,
};

const LockedIcon = {
  SOURCE: locked,
};

const SessionExpireIcon = ({ className }) => (
  <svg
    className={className}
    width="196"
    height="151"
    viewBox="0 0 196 151"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="19.6367"
      y="0.703125"
      width="151"
      height="150"
      rx="75"
      fill="#1A1D1E"
    />
    <g clip-path="url(#clip0_24231_218003)">
      <rect
        x="32.9648"
        y="20.7031"
        width="127"
        height="119"
        rx="8"
        fill="#45504D"
        fill-opacity="0.2"
      />
      <mask id="path-4-inside-1_24231_218003" fill="white">
        <path d="M33.4961 28.7031C33.4961 24.2848 37.0778 20.7031 41.4961 20.7031H123.965C128.383 20.7031 131.965 24.2848 131.965 28.7031V34.296H33.4961V28.7031Z" />
      </mask>
      <path
        d="M33.4961 28.7031C33.4961 24.2848 37.0778 20.7031 41.4961 20.7031H123.965C128.383 20.7031 131.965 24.2848 131.965 28.7031V34.296H33.4961V28.7031Z"
        fill="#0F0F0F"
      />
      <path
        d="M33.4961 20.7031H131.965H33.4961ZM131.965 35.296H33.4961V33.296H131.965V35.296ZM33.4961 34.296V20.7031V34.296ZM131.965 20.7031V34.296V20.7031Z"
        fill="#45504D"
        mask="url(#path-4-inside-1_24231_218003)"
      />
      <ellipse
        cx="43.8155"
        cy="29.7701"
        rx="2.70217"
        ry="2.55914"
        fill="#1A1D1E"
      />
      <ellipse
        cx="52.8196"
        cy="29.7701"
        rx="2.56566"
        ry="2.55914"
        fill="#1A1D1E"
      />
      <ellipse
        cx="62.0967"
        cy="29.7701"
        rx="2.70217"
        ry="2.55914"
        fill="#1A1D1E"
      />
    </g>
    <rect
      x="33.4648"
      y="21.2031"
      width="126"
      height="118"
      rx="7.5"
      stroke="#45504D"
      stroke-opacity="0.5"
    />
    <g clip-path="url(#clip1_24231_218003)">
      <rect
        x="38.9648"
        y="24.7031"
        width="126"
        height="119"
        rx="8"
        fill="#0F0F0F"
        fill-opacity="0.5"
      />
      <mask id="path-11-inside-2_24231_218003" fill="white">
        <path d="M39.4961 32.7031C39.4961 28.2848 43.0778 24.7031 47.4961 24.7031H156.965C161.383 24.7031 164.965 28.2848 164.965 32.7031V38.296H39.4961V32.7031Z" />
      </mask>
      <path
        d="M39.4961 32.7031C39.4961 28.2848 43.0778 24.7031 47.4961 24.7031H156.965C161.383 24.7031 164.965 28.2848 164.965 32.7031V38.296H39.4961V32.7031Z"
        fill="#0F0F0F"
      />
      <path
        d="M39.4961 24.7031H164.965H39.4961ZM164.965 39.296H39.4961V37.296H164.965V39.296ZM39.4961 38.296V24.7031V38.296ZM164.965 24.7031V38.296V24.7031Z"
        fill="#45504D"
        mask="url(#path-11-inside-2_24231_218003)"
      />
      <ellipse
        cx="50.1802"
        cy="31.9304"
        rx="2.52"
        ry="2.53191"
        fill="#1A1D1E"
      />
      <ellipse
        cx="59.2505"
        cy="31.9304"
        rx="2.52"
        ry="2.53191"
        fill="#1A1D1E"
      />
      <ellipse cx="68.313" cy="31.9304" rx="2.52" ry="2.53191" fill="#1A1D1E" />
      <g opacity="0.2">
        <path
          d="M85.0423 100.167C85.0423 100.167 84.8178 100.007 84.459 99.7327C79.0836 95.5765 75.7148 89.5535 75.7148 82.8481C75.7148 70.3473 87.469 60.2031 101.959 60.2031C116.455 60.2031 128.215 70.3473 128.215 82.8481C128.215 95.3548 116.461 105.149 101.965 105.149C100.728 105.149 98.6982 105.067 95.8748 104.904C92.194 107.296 89.8215 112.259 85.1198 112.259C83.6644 112.259 82.979 111.063 83.9123 109.844C85.3298 108.105 84.284 102.32 85.0423 100.164V100.167Z"
          stroke="#45504D"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M86.6965 95.9688V94.6911L91.7774 87.5618H86.6865V85.7869H94.482V87.0646L89.396 94.1939H94.4919V95.9688H86.6965Z"
          fill="#45504D"
        />
        <path
          d="M96.0841 91.2656V89.6229L102.617 80.4567H96.0713V78.1747H106.094V79.8175L99.555 88.9837H106.107V91.2656H96.0841Z"
          fill="#45504D"
        />
        <path
          d="M107.834 85.5V83.8572L114.367 74.6911H107.821V72.4091H117.844V74.0518L111.305 83.218H117.857V85.5H107.834Z"
          fill="#45504D"
        />
      </g>
    </g>
    <rect
      x="39.4648"
      y="25.2031"
      width="125"
      height="118"
      rx="7.5"
      stroke="#45504D"
    />
    <circle cx="19.205" cy="30.0292" r="4.06829" fill="#1A1D1E" />
    <circle cx="7.73287" cy="98.9008" r="7.40865" fill="#1A1D1E" />
    <circle cx="187.733" cy="84.9008" r="7.40865" fill="#1A1D1E" />
    <path
      d="M89.0423 98.1673C89.0423 98.1673 88.8178 98.0069 88.459 97.7327C83.0836 93.5765 79.7148 87.5535 79.7148 80.8481C79.7148 68.3473 91.469 58.2031 105.959 58.2031C120.455 58.2031 132.215 68.3473 132.215 80.8481C132.215 93.3548 120.461 103.149 105.965 103.149C104.728 103.149 102.698 103.067 99.8748 102.904C96.194 105.296 93.8215 110.259 89.1198 110.259C87.6644 110.259 86.979 109.063 87.9123 107.844C89.3298 106.105 88.284 100.32 89.0423 98.1644V98.1673Z"
      stroke="#45504D"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M90.6965 93.9688V92.6911L95.7774 85.5618H90.6865V83.7869H98.482V85.0646L93.396 92.1939H98.4919V93.9688H90.6965Z"
      fill="#45504D"
    />
    <path
      d="M100.084 89.2656V87.6229L106.617 78.4567H100.071V76.1747H110.094V77.8175L103.555 86.9837H110.107V89.2656H100.084Z"
      fill="#45504D"
    />
    <path
      d="M111.834 83.5V81.8572L118.367 72.6911H111.821V70.4091H121.844V72.0518L115.305 81.218H121.857V83.5H111.834Z"
      fill="#45504D"
    />
    <defs>
      <clipPath id="clip0_24231_218003">
        <rect
          x="32.9648"
          y="20.7031"
          width="127"
          height="119"
          rx="8"
          fill="white"
        />
      </clipPath>
      <clipPath id="clip1_24231_218003">
        <rect
          x="38.9648"
          y="24.7031"
          width="126"
          height="119"
          rx="8"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);

const CheckCircleIcon = ({ className }) => (
  <svg
    className={className}
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.9173 11.5424V12.5008C22.916 14.747 22.1887 16.9327 20.8437 18.7318C19.4987 20.5309 17.6082 21.8471 15.4541 22.484C13.3001 23.1209 10.9978 23.0444 8.89073 22.2659C6.78365 21.4875 4.98467 20.0488 3.76207 18.1644C2.53947 16.28 1.95877 14.0508 2.10656 11.8094C2.25436 9.56801 3.12274 7.43443 4.58219 5.72687C6.04164 4.01932 8.01397 2.82928 10.205 2.33424C12.3961 1.8392 14.6884 2.06569 16.7402 2.97992"
      stroke="#FAFEFD"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.9167 4.16797L12.5 14.5951L9.375 11.4701"
      stroke="#FAFEFD"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const CALENDER = {
  SOURCE: calender,
};

const NEXT = {
  SOURCE: Next,
};

const PREV = {
  SOURCE: Prev,
};

const CHECKED = {
  SOURCE: Checked,
};

const UPLOADIMAGE = {
  SOURCE: uploadImage,
};

const MAX = {
  SOURCE: max,
};

const COPY_RIGHT = {
  SOURCE: copyRight,
};

const APP_VERSION = {
  SOURCE: appVersion,
};

const SHIELD_EXCLAMATION = {
  SOURCE: shieldExclamation,
};

const CORRECT = {
  SOURCE: correct,
};

const POINTER = {
  SOURCE: pointer,
};

const CHECK = {
  SOURCE: check,
};

const INFO = {
  SOURCE: info,
};

const BOTTOM = {
  SOURCE: bottom,
};

const CLOSE = {
  SOURCE: close,
};

const INTELLIANTELOGO = {
  SOURCE: intellianteLogo,
};

const TICKWHITE = {
  SOURCE: checkWhite,
};

const INTELLIANTELOGOPNG = {
  SOURCE: intellianteLogoPng,
};

export {
  TICKWHITE,
  INTELLIANTELOGO,
  CLOSE,
  BOTTOM,
  LOGO,
  CALENDER,
  COPY_RIGHT,
  APP_VERSION,
  SHIELD_EXCLAMATION,
  CORRECT,
  POINTER,
  CHECK,
  INFO,
  MAX,
  NEXT,
  PREV,
  CHECKED,
  UPLOADIMAGE,
  LockedIcon,
  SessionExpireIcon,
  CheckCircleIcon,
  INTELLIANTELOGOPNG,
};
