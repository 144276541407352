import React, { useState, useEffect, useContext } from "react";
import { withTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";

import OtpVerifyApi from "api/AuthApi/OtpVerfiyApi";

import { APPLICANT_SUMMARY_REPORT } from "containers/Routes/routes";

import Loader from "components/Common/Loader";
import Icon from "components/Shared/Icon";
import LoadingState from "components/SignIn/LoadingState";

import { RESPONSE_MESSAGES } from "constants/authentication";
import { CheckCircleIcon } from "constants/images";

import LocalStorageManager, { CONSTANTS } from "utils/localStorageManager";
import { getQueryParams } from "utils/queryParams";

import { AuthSetupContext } from "contextApi/AuthSetupContext";

import OtpInputs from "./OtpInput";

const OtpVerify = ({ t }) => {
  const { setLoggedIn, isPageLoading, setIsPageLoading } =
    useContext(AuthSetupContext);

  let key = useLocation();

  const { otp_token: otpToken } = getQueryParams(key.search);

  const history = useHistory();

  const [email] = useState(key?.state?.email);
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [opt_key, setOptKey] = useState(key?.state?.secretKey);
  const [showOTPMessage, setShowOTPMessage] = useState(false);
  const [loadingValue, setLoadingValue] = useState(false);
  const [isVerifyButtonLoading, setIsVerifyButtonLoading] = useState(false);

  const handleOtpVerify = async (e) => {
    setIsVerifyButtonLoading(true);
    const filters = {
      otp: otp,
      otp_token: opt_key,
      client_id: CONSTANTS.CLIENT_ID,
    };

    try {
      const res = await OtpVerifyApi.verifyOtpCode(filters);

      const { token } = res?.data?.access_token;

      if (token) {
        LocalStorageManager.set(CONSTANTS.LOGGED_IN, true);
        LocalStorageManager.set(CONSTANTS.ACCESS_TOKEN, token);
        LocalStorageManager.set(CONSTANTS.TOKEN_TYPE, CONSTANTS.BEARER);

        setIsVerifyButtonLoading(false);
        setLoggedIn(true);
        setIsPageLoading(true);
        history.push(APPLICANT_SUMMARY_REPORT.INDEX);
      }
    } catch (error) {
      setOtp("");
      document
        ?.querySelector("#form > div > div:nth-child(1) > input")
        ?.focus();
      setIsVerifyButtonLoading(false);
      const errorMessage = error?.response?.data?.message;

      if (errorMessage === RESPONSE_MESSAGES.OTP_LIMIT_EXCEEDED) {
        setError(RESPONSE_MESSAGES.OTP_LIMIT_EXCEEDED);
      } else if (errorMessage === RESPONSE_MESSAGES.OTP_CODE_EXPIRED) {
        setError(RESPONSE_MESSAGES.OTP_CODE_EXPIRED);
      } else {
        setError(`${t("auth.otp.invalidOTPMessage")}`);
      }
    }
  };

  const resendOtp = async () => {
    setLoadingValue(true);
    let filters = {
      email: email,
      client_id: CONSTANTS.CLIENT_ID,
    };
    const res = await OtpVerifyApi.post(filters);

    setShowOTPMessage(true);
    setTimeout(() => {
      setShowOTPMessage(false);
    }, 10 * 1000);
    setOptKey(res?.data?.otp_token);
    setLoadingValue(false);
  };

  useEffect(() => {
    if (otpToken) {
      setOptKey(otpToken);
    }
  }, []);

  useEffect(() => {
    if (otp?.length === 6) {
      handleOtpVerify();
    }
  }, [otp]);

  if (isPageLoading) {
    return <Loader isLoading={isPageLoading} />;
  }

  return (
    <div className="container-fluid sign-in sign-up verify-page h-100 position-relative">
      <div className="top-circle position-absolute top-0 start-0">
        <Icon name="TopCircle" />
      </div>
      <div className="bottom-circle position-absolute bottom-0 end-0 ">
        <Icon name="BottomCircle" />
      </div>
      <div className="logo">
        <figure className="text-center m-0">
          <Icon name="Logo" />
        </figure>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-6 col-lg-5">
          <div className="p-4 bg-gray">
            {showOTPMessage && (
              <div className="d-flex justify-content-center mx-auto mb-4 align-items-center otp-message">
                <CheckCircleIcon className="color-icon" />
                <p>{t("auth.otp.resendVerificationCodeSuccessful")}</p>
              </div>
            )}
            <div className="text-center verify position-relative">
              <figure className="mb-4">
                <Icon name="OTP" />
              </figure>
              <strong>{t("auth.otp.title")}</strong>
              <p className="mt-2 para">{t("auth.otp.subHeading")}</p>
              {error && <p className="error-message mt-3">{error}</p>}
              <div
                className="form d-flex justify-content-center"
                id="form"
                data-testid="otp_verify_inputs"
              >
                <OtpInputs otp={otp} setOtp={setOtp} />
              </div>
              <button
                className="mt-4 send-btn btn text-uppercase"
                onClick={handleOtpVerify}
                data-testid="otp_verify_button"
              >
                <LoadingState
                  isLoading={isVerifyButtonLoading}
                  loadingLabel={t("label.verifying")}
                  defaultLabel={t("label.otpVerify")}
                />
              </button>
              <div className="mt-4">
                <span className="resend" onClick={resendOtp}>
                  <LoadingState
                    isLoading={loadingValue}
                    loadingLabel={t("auth.otp.loadingResend")}
                    defaultLabel={t("auth.otp.resend")}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <OtpModal isVisible={OTPVerifiedModal} onLaunchSetup={handleSuccess} /> */}
    </div>
  );
};

export default withTranslation()(OtpVerify);
