import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { APPLICANT_SUMMARY_REPORT, HOME } from "containers/Routes/routes";

import Loader from "components/Common/Loader";

import Error from "assets/images/errorpage.svg";

import { PAGE_LOAD_TIME } from "constants/constants";

const ErrorPage = () => {
  let history = useHistory();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);

  const backHome = () => {
    history.push(APPLICANT_SUMMARY_REPORT.INDEX);
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, PAGE_LOAD_TIME);
  }, []);

  if (loading) {
    return <Loader isLoading={loading} />;
  }

  return (
    <div className="d-flex justify-content-center align-items-center position-relative error-page d-flex container">
      <h1 className="error-status position-absolute text-center">
        {t("message.errorPage.status")}
      </h1>
      <span className="forbidden-image text-center position-absolute">
        <img src={Error} />
      </span>
      <div className="forbidden-text text-center position-absolute mt-2">
        {t("message.errorPage.error")}
      </div>
      <div className="forbidden-text1 text-center position-absolute mt-1">
        {t("message.errorPage.errorMessage")}
      </div>
      <button
        className="mt-5 error-button d-flex justify-content-center align-items-center position-absolute"
        onClick={backHome}
      >
        {t("common.backToHome")}
      </button>
    </div>
  );
};

export default ErrorPage;
