import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DateIcon = ({ rangeValues, clearRanges, position, color }) => {
  if (rangeValues == "") {
    return (
      <FontAwesomeIcon
        icon={"calendar-alt"}
        className={` top-50 end-0 me-3 ${
          position ? position : "position-absolute"
        }`}
        color={color}
      />
    );
  }

  return (
    <FontAwesomeIcon
      icon={"times"}
      className={` top-50 end-0 me-2 rounded-circle ${
        position ? position : "position-absolute"
      }`}
      onClick={clearRanges}
      color={color}
    />
  );
};

export default DateIcon;
