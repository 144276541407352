import React, { useState } from "react";
import { useEffect } from "react";

import axios from "axios";

import Warning from "pages/Error/Warning";

import { TOASTER_TIMEOUT } from "constants/constants";

export const OfflineContextApi = React.createContext({});

const OfflineContext = ({ children }) => {
  const [isOffline, setIsOffline] = useState(false);
  const [isServerError, setIsServerError] = useState(false);

  const [showToasterInvalid, setShowToasterInvalid] = useState(false);

  const toastersShowInvalid = () => {
    setShowToasterInvalid(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setShowToasterInvalid(false);
    }, TOASTER_TIMEOUT);
  }, [showToasterInvalid]);

  const errorHandlerInterceptor = (err) => {
    if (
      window.navigator.onLine &&
      err?.response &&
      err?.response?.status === 500
    ) {
      toastersShowInvalid();
      setIsServerError(true);
    } else if (!window.navigator.onLine && !err?.response) {
      setIsOffline(true);
    } else {
      throw err;
    }
  };

  useEffect(() => {
    axios.interceptors.response.use(null, errorHandlerInterceptor);
  }, [window.navigator.onLine]);

  return (
    <>
      <OfflineContextApi.Provider
        value={{
          isOffline,
          isServerError,
        }}
      >
        {children}
        <Warning
          showToaster={showToasterInvalid}
          setShowToaster={setShowToasterInvalid}
        />
      </OfflineContextApi.Provider>
    </>
  );
};

export default OfflineContext;
