import NoFilter from "./NoFilter";
import TableBody from "./TableComponents/TableBody";
import TableHead from "./TableComponents/TableHead";

const ReportTable = ({
  companysList,
  showSkeleton,
  filteredResults,
  handleSorting,
}) => {
  if (companysList.length === 0 && !showSkeleton)
    return <NoFilter showDescription={true} />;

  return (
    <div className="table-container ">
      <table className="w-100 main-table">
        <TableHead showSkeleton={showSkeleton} handleSorting={handleSorting} />
        {companysList?.map((company, i) => (
          <TableBody
            key={i}
            company={company}
            showSkeleton={showSkeleton}
            filteredResults={filteredResults}
          />
        ))}
      </table>
    </div>
  );
};

export default ReportTable;
