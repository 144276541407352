export const SKELETON_UI = {
  DARK_HEADER_BASE: "rgba(0, 0, 0, 0.3)",
  DARK_HEADER_HIGHLIGHT: "rgba(87, 83, 83, 0.1)",
  PAGINATION_BASE: "#222a29",
  PAGINATION_HIGHLIGHT: "#373737",
  LIGHT_HEADER_BASE: "rgba(255, 255, 255, 0.1)",
  LIGHT_HEADER_HIGHLIGHT: "rgba(219, 219, 219, 0.2)",
  LIGHT_BODY_BASE: "rgba(175, 175, 175, 0.1)",
  LIGHT_BODY_HIGHLIGHT: "rgba(219, 219, 219, 0.2)",
};

export const TABLE_SKELETON_UI = {
  DARK_HEADER_BASE: "rgba(0, 0, 0, 0.3)",
  DARK_HEADER_HIGHLIGHT: "rgba(87, 83, 83, 0.1)",
  PAGINATION_BASE: "#222a29",
  PAGINATION_HIGHLIGHT: "#373737",
  LIGHT_HEADER_BASE: "rgba(255, 255, 255, 0.1)",
  LIGHT_HEADER_HIGHLIGHT: "rgba(219, 219, 219, 0.2)",
  LIGHT_BODY_BASE: "rgba(175, 175, 175, 0.1)",
  LIGHT_BODY_HIGHLIGHT: "rgba(219, 219, 219, 0.2)",
};
