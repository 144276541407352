/**
 *
 * NOTE: Please maintain by alphabetical order; it will be easier to search and look through the file.
 * Add the values according to following labels
 *
 * 1. attributes - This will include site wide attributes values eg: (Email, Name, Password, etc);
 * 2. auth - This will include all the labels for the auth with nested objects;
 *         - Include all the translations inside respective object (eg: login, signUp, passwordReset, etc);
 * 3. common - This will include common translation in app eg: (Job, Candidate, Games, etc);
 * 4. label - This will include all the labels for the buttons eg: (Login, Sign Up, etc);
 * 5. message - This will include common messages that needs to be displayed in app eg: (Email verified, etc);
 * 6. modal - This will include all the labels, description, subheadings for the modal eg: (SignUp modal, Launch setup modal, etc);
 *
 *
 **/

const translation = {
  assessmentNames: {
    MCCS: "Multi Channel Customer Support",
    tenKey: "Ten Key",
    typingTest: "Typing Test",
    businessWriting: "Business Writing",
    overcomingAdversity: "Overcoming Adversity Skills",
    communicationSkills: "Communication Skills",
    basicComputer: "Basic Computer Skills",
    responsibilityAndDependability: "Responsibility and Dependability Skills",
  },
  attributes: {
    createWorkflow: "Create Workflow",
    skillReview: "Skill Review",
    recommendedAssessments: "Recommended Assessments",
    inviting: "Inviting",
    exists: "Already Invited",
    unknown: "Unknown",
    action: "Action",
    address1: "Address 1",
    city: "City",
    email: "Email",
    name: "Name",
    location: "Location",
    role: "Role",
    emailRole: "Email / Role",
    user: "User",
    sn: "S.N.",
    user: "User",
    note: "Note",
    details: "Details",
    invite: "Invite",
    invite_user: "Invite User",
    profile: "Profile",
    games: "Games",
    status: "Status",
    disable: "Disable",
    reminder: "Reminder",
    score: "Score",
    applied: "Applied date",
    lastActive: "Last Active on",
    back: "Back",
    next: "Next",
    prev: "Prev",
    invited: "Invited",
    confirm: "Confirm",
    cancel: "Cancel",
    delete: "Delete",
    assign: "Assign",
    a: "a",
    assignUser: "Assign User",
    fetch: "Fetch",
    profile: "Profile",
    update: "Update",
    reset: "Reset",
    save: "Save",
    saving: "Saving",
    loading: "Loading..",
    close: "Close",
    all: "All",
    published: "Published",
    draft: "Draft",
    withdrawn: "Withdrawn",
    expired: "Expired",
    from: "From:",
    to: "To:",
    jobs: "Jobs",
    assessments: "Assessments",
    date: "Date",
    upload: "Upload",
    error: "Error",
    success: "Success",
    errors: "Errors",
    warnings: "Warnings",
    duplicate: "Duplicate",
    browse: "browse",
    logo: "logo",
    inverted: "(inverted)",
    preview: "Preview",
    theme: "Theme",
    livePreview: "View Live Preview",
    minimizePreview: "Minimize Preview Mode",
    minLength: "minLength",
    capital: "capital",
    specialChar: "specialChar",
    number: "number",
    match: "match",
    lowercase: "lowercase",
    passwordStrength: "Password Strength",
    jobTitle: "Job Title",
    onetJobs: "Onet Job Titles",
    jobType: "Job Type",
    workerLocation: "Worker Location",
    communicationChannel: "Communication Channel",
    reviewCompetencies: "Review Competencies",
    blockers: "Blockers",
    workflow: "Workflow",
    or: "or",
    country: "Country",
    state: "State",
    city: "City",
    addressLine: "Address Line",
    zipPostalCode: "ZIP/ Postal Code",
    actions: "Actions",
    customPage: "Custom Page",
    height: "Height",
    width: "Width",
    displayText: "Display Text",
    heading1: "Heading 1",
    heading2: "Heading 2",
    heading3: "Heading 3",
    heading4: "Heading 4",
    heading5: "Heading 5",
    normalText: "Normal Text",
    left: "Left",
    right: "Right",
    requiredRoles: "Roles*",
    requiredEmail: "Email Address *",
    center: "Center",
    welcomePage: "Welcome Page",
    pageBreak: "Page Break",
    configuration: "Configuration",
    language: "Language",
    userId: "User ID",
    pendingChanges: "Pending changes",
    enableValidations: "Enable Validations",
    dashboard: "Dashboard",
    addUsers: "Add Users",
    briefDescription: "Brief description of duties",
    applicantDataNotFilled: "Applicant has not added",
    view: "View",
    edit: "Edit",
    create: "Create",
    editRolePermission: "Edit Roles & Permissions",
    viewUsers: "View Users",
    editUsers: "Edit Users",
    superAdmin: "Super Admin",
  },

  previewValues: {
    games: "Games",
    reports: "Reports",
    achievements: "Achievements",
    training: "Training",
    multiTasking: "Multitasking",
    empathy: "Empathy",
    technicalSupport: "Technical Support",
    customerService: "Customer Service",
    computerSkills: "Computer Skills",
    typingTest: "Typing Test",
    keyTest: "10 Key Test",
    writingComp: "Writing Compositiion",
    activeListening: "Active Listening",
    completed: "Completed",
    inProgress: "In Progress",
    notStart: "Not Started",
    estimatedTime: "Estimated Completion Time:",
    estimatedTimeValue: "3 min",
    en: "EN",
    mg: "MG",
    melinda: "Melinda Gates",
    applicant: "applicant",
    value: "1",
    chooseCustomBranding: "Choose Custom Branding",
    intellianteLogo: "Intelliante Logo",
    drillDownReport: "Drill Down Report",
    applicantResume: "Applicant Resume",
  },

  monthsValues: {
    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",
  },

  modalValues: {
    reset: {
      modalTitle: "Are you sure you want to reset the workflow?",
      modalDelete: "Are you sure you want to delete the reminder?",
      resetIntructions:
        " If you reset your workflow, all items will be deleted.",
    },
    areYouSure: "Are you sure you want to delete",
    deleteThis: "Are you sure you want to delete this",
    deleteInstruction: "If you delete this",
    allThisItems: "All items inside this",
    willBeDeleted: "will be deleted.",
    userWithAccess: "Users with access",
    pendingInvitations: "Invited",
  },

  formValues: {
    defaultSearch: "Search",
    searchApplicantEmail: "Search by Applicant's Email",
    logoRequired: "Please upload the company logo.",
    voluntaryToggleText: "Voluntary Self Identification",
    disabilityToggleText: "Disability Disclosure",
    propSettings: "Properties/Settings",
    configureGames: "Configure Games",
    jobTitle: "Job Title",
    updateProfileImage: "Update Profile Image",
    deleteProfileImage: "Delete Profile Image",
    editProfile: "Edit Profile",
    phoneNumber: "Phone Number",
    saveChange: "Save",
    saveChanges: "Save Changes",
    email: "Email",
    cancel: "Cancel",
    dialingCode: "Dialing Code",
    customErrorUser: "username",
    companyName: "Company Name",
    company: {
      company: "Company",
      name: "Name",
      website: "Website",
      url: "URL",
      about: "About",
      us: "Us",
      slug: "Slug",
      general: "General",
      officeLocations: "Office Locations",
      officeLocation: "Office Location",
      branding: "Branding",
      rolesAndPermissions: "Roles and Permissions",
      userManagement: "User Management",
      billingAndPlans: "Billing and Plans",
      settings: "Settings",
      platformAdminAccount: "Platform Admin Account",
    },
    searchUser: "Search User",
  },

  brandingInfo: {
    brandingDetails: "Branding Details",
    logoOption: "Logo option",
  },

  noOptions: "No Options",

  auth: {
    signing: "Signing",
    otp: {
      resend: "Resend a verification code",
      loadingResend: "Sending verification code",
      subHeading: "Please enter the OTP code from your email",
      title: "Enter OTP code here",
      invalidOTPMessage: "The OTP entered is invalid.",
      otpLimitExceededMessage: "Your attempt has exceeded the maximum limits",
      otpCodeExpired: "OTP code expired",
      resendVerificationCodeSuccessful: "Successfully resent verification code",
    },
    signUp: {
      createAccount: "Create new Account",
      createAnAccount: "Create an Account",
      email: "Enter your valid Email Address*",
      enterEmailPassword: "Enter your work email and pasword.",
      workEmail: "Your Work Email",
      password: "Password",
      confirmPassword: "Confirm Password",
      verifyEmail: "Awesome! Let's verify your email",
      verifyEmailInfo:
        "To start using intelliante platform you need to verify your email address.",
      toolTip: {
        pw: "Click here to know what you should avoid while setting up password",
        onBoarding_title:
          "To know what information is needed when onboarding, ",
        password: {
          pwTitle: "You should avoid using:",
          tip1: "A significant portion of your email address",
          tip2: "Passwords from this ",
          tip2_link: " list of common passwords ",
          tip3: "Passwords from ",
          tip3_link: " Have | Been Pwned ",
          tip3_end: " breach database ",
          tip4: "Words from a dictionary or the name of person, character, product, or organization",
          tip5: "A password you use for another account",
        },
        onBoarding: {
          obTitle: "Information",
          tip1: "Tell us about yourself",
          tip2: "Set your company information",
          tip3: "Choose your own brand and customize theme",
          tip4: "Created an integrated report",
        },
      },
      enterValidEmail: "Please enter a valid",
      passwordMustMatch: "The passwords must match.",
      signUp: "Sign Up",
      signIn: "Sign In",
      signInNow: " Sign in now",
      passwordRecover: "Recover Password?",
      notStrong: "You password strength must be strong",
      BREACHED: {
        CHECKING: "Checking the Security of this password...",
        SAFE: "This password is safe to use and appeared in no known data breaches.",
        DANGER:
          "We have detected your password on Have I Been Pwned Breach database. Please use different password.",
      },
      createNewPassword: "Create new password",
      termsOfUse: "Terms Of Use.",
      privacyPolicy: "Privacy Policy",
      policyErrorMsg:
        "You must agree to our policy(s) with the above check box to proceed.",
    },
    signIn: {
      accountLocked: "Please be patient. Account will be unlocked in",
      retryLeft_1: "You have ",
      retryLeft_2: " login attempts left out of",
      blankEmail: "Email address is required*",
      blankPassword: "Password is required*",
    },
    passwordRecovery: {
      recoverPassword: "Recover Password",
      userMessage: "Don't worry. It happens to all of us.",
      emailRecoveryLink: "Email my recovery link",
      backToSignIn: "Back to sign in",
      sending: "Sending",
      recoveryEmailSentMessage:
        "Hooray! We will send a recovery link to  email address if an account exists. Just click the link when you get it.",
      letsGoToSignIn: "Let's go to sign in",
      newPasswordCongratulationMessage:
        "Congratulations! you have new password",
      passwordCreationMessage:
        "You can start using this newly password from next time. Keep in mind that you should not share this with anyone else",
      createNewPassword: "Create New Password",
      iKnowMyPassword: "I know my password. Go to login.",
    },
    error: {
      emailError: "Please enter your email",
      duplicateEmail: "User with this email already exists",
      invalidEmail: "Please enter a valid email",
      emailAlreadyTaken: "This email has already been taken",
      passwordError: "Please enter your password",
      confirmPasswordError: "Password and confirm password must match",
      strengthError: "You password strength must be strong",
      emailOrPasswordInvalid: "Email or password invalid",
      emailEmptyMessage: "Email cannot be empty",
      emailInvalidMessage: "Email address is invalid (you@example.com)",
      passwordEmptyMessage: "Password cannot be empty",
      confirmPasswordEmptyMessage: "Confirm password cannot be empty",
    },
    warning: {
      capsLockOn: "Caps lock is on",
    },
  },

  companyInfo: {
    title: "Company Information",
    companyDetail: "Company Details",
    companyInfo: "Company Info",
    companyDetailTab: "Company Detail",
    companyLocation: "Company Locations",
    companyBranding: "Company Branding",
    companyLogoSetting: "Logo Setting",
    companyLogoOption: "Logo Option",
    placeholderTheme: "Please select the theme",
    companyChooseTheme: "Choose a branding theme for the applicant portal",
    companyBrandingTitle: "Theme option for applicant platform",
    companyLogo: "Company logo",
    inverted: "inverted",
    logoSetting: "Logo Settings",
    editTooltip: "Edit Company Logo",
    editImage: "Edit Image",
    tellMeAboutYourself: "Tell me about yourself",
    logoWarning: {
      background:
        "Please upload the logo with transparent background for Best experience.",
      dimension: "Logo must not be larger than 400 px width and 100 px height",
    },
    logoUpload: "Drag & Drop PNG or SVG (5 MB max)",
    logoClick: "Click to",
    invertedOption:
      "This is your logo inverted for light mode. You can also upload the inverted logo and replace it",
    invertedQueries:
      "Do you want to upload the inverted logo and replace the above inverted logo?  ",
    companyProfileInfo:
      "This information will be displayed your company profile.",
    sizeError: "Logo size exceeds 400px width and 100px height",
    uploadError: "Logo size must be less than 5MB",
    oldLogoWarning:
      "Unchecking it will remove the upload you have uploaded and the deafult inverted logo will be displayed. Do you want to proceed?",
    logoEditTitle:
      "This is your company logo for dark mode. You can also click the edit icon and  upload the company logo and replace it",
    logoEditTitleInverted:
      "This is your company logo for light mode. You can also click the edit icon and  upload the company logo and replace it",
    themeCardHeaderText:
      "This theme will be shown to the applicant when completing the assessments.",
  },

  number: {
    twoHundred: "200",
    twentyfiveHundred: "2500",
    fiveThousand: "5000",
    tenThousand: "10000",
    fiveHundred: "500",
  },

  common: {
    reset: "reset",
    status: "Status",
    seeMore: " more ...",
    seeLess: "View less",
    add: "Add",
    skip: "Skip",
    newest: "Newest",
    oldest: "Oldest",
    first: "First",
    select: "Select",
    assignUser: "Assign User",
    cancel: "Cancel",
    back: "Back",
    backToHome: "Return to Home Page",
    filter: "Filter Result",
    disable: "Disable",
    clickHere: "Click here",
    save: "Save",
    savePassword: "Save Password",
    saveChange: "Save change",
    withdraw: "Withdraw",
    delete: "Delete",
    learnMore: "Learn More",
    next: "Next",
    browse: "Browse",
    continue: "Continue",
    published: "Published",
    edit: "Edit",
    changePassword: "Change Password",
    details: "Details",
    sortBy: "Sort by:",
    name: "Name",
    atoZ: "A to Z",
    edit_details: "Edit Details",
    seeDetails: "See Details",
    viewReport: "View Report",
    found: "Found",
    location: "Location",
    previewWorkflow: "Preview Workflow",
    computerVision: "Applicant Facial Recognition",
    corporateAddress: "Corporate Address",
    or: "Or",
    city: "City",
    state: "State",
    country: "Country",
    cantBeBlank: "can't be blank",
    invalid: "Invalid",
    successCsvUpload:
      "Well done ! You have successfully uploaded the CSV file.",
    fixErrorsToProceed:
      "You need to fix the errors and upload file again to proceed.",
    successSaved: "Well done! You have successfully saved",
    warningSaved: "Kindly review the recommended assessments in the workflow.",
    successUpdated: "Access Updated Successfully",
    failedSaved: "Sorry! It couldn't be saved",
    companySavedSuccess: "Company details updated successfully",
    companySavedFailed: "Failed to update company details",
    noLocationAdded: "No Location Added",
    closeTutorial: "Close Tutorial",
    selectType: "Select Type",
    textAlign: "Text Align",
    image: "image",
    job: "Job",
    billing: "Billing",
    assignToUsers: "Assign to Users",
    viewSummaryReports: "View Summary Reports",
    viewDrillDownReports: "View Drill down Reports",
    manageTemplates: "Manage Templates",
    inviteCandidate: "Invite Candidate",
    viewAll: "View All",
    userAndRoles: "Users & Roles",
    dontHaveAccess: "You don't have access to use this feature currently",
  },

  filterTexts: {
    location: "Location",
    search: "Search",
    dateRange: "Start Date - End Date",
  },

  timeAttributes: {
    month: "Month",
    monthly: "Monthly",
    yearly: "Yearly",
  },

  message: {
    mccsOptionQuestion: "What contact center skills do you want to test?",
    assignLocationPlaceholder: "Type Location and/or select from the Dropdown",
    internalError: "Warning",
    subError: "Internal server error",
    backToHome: "Back to home",
    searchElements: "Search Elements",
    timeOfDay: "Time Of Day",
    reqNo: " is required *",
    requireJob: "Job detail cannot be unchecked to clone the job",
    successCompanyBranding: "Color Branding successfully updated",
    failedCompanyBranding: "Sorry, Color Branding can't be updated",
    uploadToast: {
      headerLeft: "Invited ",
      headerRight: " applicants",
      invited:
        " applicants that you have added was already invited for this job. Those applicants will not receive the invitation again.",
      failed: "Failed to upload",
      success_left: "Successfully Invited ",
      single_success: "applicant",
      multiple_success: "applicants",
      exists: "Applicants already invited",
    },
    invite: {
      invalidApplicant: "Invalid Applicant",
      invalid: "Please enter valid email address.",
      invite: "Invite Applicant",
      tip: "You can enter multiple email address by pressing the enter button",
      multipleEmail:
        "You can enter multiple email adress by separating them with space or comma (,)",
      addApplicant: "Add applicant email",
      invite_head: {
        headStart: "Invite ",
        headEnd: " to this job",
      },
      emailBody: "Email body message",
      applicant: "Applicant",
      applicants: "Applicants",
      error: {
        noEmail: "Please enter at least one email address",
        userExist: "The applicant is already added.",
        invalid: " Invalid emails found",
        duplicate: " Duplicate emails found",
        downloadLeft: "You can download the ",
        downloadLink: "full report",
        downloadRight: " to view more details",
        upload: "Upload valid data only",
        followingErrors:
          "Following Error(s) has been occurred during uploading process: ",
        errorLimit: "Only the first 30 errors are shown.",
      },
      modal: "You can import applicants",
      records: {
        duplicate: "Duplicate Records",
        invalid: "Invalid Records",
        duplicateData: "Duplicate Data",
        invalidData: "Invalid Data",
      },
    },
    email: {
      tip: "Click here to know accepted email variables",
      template: "Select email template",
    },
    addUser: "Add user manually",
    uploadUser: "Upload multiple User",
    gameSetup: {
      assignGames: "Assign Games",
      alreadyOnList: "Game is already on the list",
      noBlockers: "No Blockers",
      noGames:
        "No game selected. Please click on the setting icon of any game to set or view its configuration",
    },

    auth: {
      emailVerified: "Email verified",
      sessionExpiryMessage: "Your session has expired",
      loginAgainMessage: "Please log in again.",
    },

    plan: {
      mostPopular: "Most Popular",
      selectedPlan: "Selected Plan",
      fullFeature: "Expand Full Feature Comparision",
      offer: "Get 5% discount on yearly subscription",
    },

    workflowSetup: {
      mccsConfig: {
        voice: "Voice",
        chat: "Chat",
        both: "Both voice and chat",
        chooseVoice: "Choose up to 3 voices",
        chooseChat: "Choose up to 3 chats",
        chooseBoth: "You can choose 1 voice call and 2 chats",
      },
      configurationUpdateMessage:
        "Configuration updated successfully. Click Save button in workflow to save the update.",
      editWorkflowConfirmation:
        "  Are you sure you want to update the workflow?",
      edit: "By clicking the update button",
      editWorkflowConfirmationDetails:
        " The workflow version will be updated if the workflow is updated. And all future applicants will have access to the updated workflow.",
      configureApplicantDetails: " Configure applicant details workflow",
      configureWelcomePage: "Edit and configure welcome message",
      saveEmptyPage: "You cannot save the empty page",
      configureComponent: "Configure / Components",
      tooltipSave: "Workflow not created to Save",
      tooltipNext:
        "You must create and save the workflow to go to the next step",
      tooltipSaveTesting:
        "At least one test should be selected to create and save the workflow",
      createWorkflow: "Go to dashboard",
      workflowSetup: "Workflow Setup",
      workflowDesign: "Workflow Design",
      extraSettings: "Extra settings for workflow",
      reviewJobInfo:
        " Review the job you have recently created and publish if everything looks good",
      enabled: "Enabled",
      disabled: "Disabled",
      closingMessage: {
        closingMessage: "Closing Message",
        closingMessageInfo:
          "Customize closing message which appears at the last when applicant complete test",
        closingMessageExample:
          "Eg: Thank you for taking your time to fill this form",
      },

      evaluateResult: {
        evaluateResult: "Evaluate the incomplete result?",
        evaluateResultInfo:
          "  Even if customer want to evaluate the incomplete results, candidate must have to complete at least 1 test/ game.",
      },

      scheduleTest: {
        scheduleTest: "Schedule Test",
        startTheTestDetail:
          " Start the test from your convinience time by sheduling it. You can set start date / time and end date / time.",
        fromDateTime: "From (date & time)",
        toDateTime: "To (date & time)",
      },
      timeBound: {
        timeBound: "Time Bound",
        timeBoundInfo:
          " By specifying time bound, the candidate are automatically disabled, if they do not start the test within the specified number of days after onboarding",
        noOfDays: "No. of Days",
        afterSignup: "After Sign up",
        timeBoundWarning: "Time bound must not exceed {{count}} days",
        enterTimeBound: "Please enter bound time",
      },
      tooltip: {
        invitationNotAcceptedYet:
          "Applicant has not accepted the invitation yet.",
        workflowVersion: "Workflow Version:",
        profileNotSetupYet: "Applicant has not setup their profile yet.",
      },

      resumeUpload: {
        dragAndDrop: "Drag & drop a .doc, .docx, or .pdf (5MB max)",
        or: "or",
        clickTo: "Click to",
        browse: "browse",
      },
    },

    welcomePage: {
      configureWelcomePage: "Configure welcome custome page",
      configureDetails:
        "Configure the welcome page for the applicant before setup applicant details workflow.",
    },

    forbiddenPage: {
      status: "403",
      forbidden: "Forbidden",
      forbiddenMessage: "You do not have permission to view this page.",
    },

    errorPage: {
      status: "404",
      error: "Page not found",
      errorMessage: "The page you were looking for does not exist.",
    },

    roles: {
      role: "Role *",
      screener: "Screener",
      hiringManager: "Hiring Manager",
      recruiter: "Recruiter",
      recruitingManager: "Recruiting Manager",
      recruiterAdmin: "Recruiter Admin",
      platformAdmin: "Platform Admin",
      customerAdministrator: "Customer Administrator",
      customerSuperUser: "Customer Super User",
      description: "Description *",
      noRoles: "No Roles Added",
      noRolesDetail:
        "No roles has been added till now. Please click on the button below to add new roles and get it listed here.",
      selection: "Select all Permissions",
      rolesPerPage: "roles per page",
      roleAlreadyTaken: "Role has already been taken",
      requiredRoleName: "Role Name *",
      validationRoleName: "Role Name",
      requiredRoleDescription: "Description *",
      validationRoleDescription: "Description",
    },

    branding: {
      label: "Branding",
    },

    users: {
      invitation: "You have been invited for a particular role",
      manageUsers: "Manage users",
      next: "Subscription",
      addUser: "Users",
      label: "Users",
      or: "OR",
      roles: "Roles *",
      locations: "Locations *",
      add: "Add New Users",
      addUser_tooltip:
        "You can enter multiple email address by separating it with comma (,)",
      prepare: "Prepare user for invitation",
      manualAdd: "Add user manually",
      manuallyAddUser: "Manually Add User",
      bulkAdd: "Add multiple users with bulk upload",
      fillOutDetails: "Fill out details for each User you want to add",
      uploadUser: "Upload multiple user",
      bulkUpload: "Or add multiple users with bulk upload feature",
      edit: "Edit User’s Detail",
      noUsers: "No Users Added",
      addBulkUser: "Add multiple users at once using CSV template upto 100MB.",
      fileExceed: "The file exceeds 100MB size",
      formatError: "Please use the correct format for uploading applicants.",
      search: "Search by email id",
      roleInvalid: " role id is invalid",
      roleMustExist: " role id must exist",
      locationInvalid: " location id is invalid",
      locationMustExist: " location id must exist",
      emailInvalid: " email is invalid",
      locationName: "Location Name",
      discardInvalidData: " Discard invalid data",
      downloadSampleUserTemplate: "Download the sample User Template",
      noUsersDetail:
        "No users has been added till now. Please click on the button below to add new user and get it listed here",
      error: {
        enterName: "Enter your full name",
        enterJob: "Enter your current job",
        enterNickname: "Enter what should we call you when we talk",
        enterNumber: "Enter your work phone number",
        duplicateEmail: "The email is already registered",
        emailError: "You must enter atleast one email",
        emailInvalid: "Please enter valid email",
        rolesError: "You must enter at least one role",
        locationError: "You must enter at least one location",
      },
      prev: "Company information",
      or: "OR",
      location: "Company Location",
      roleTip:
        "A recruiting manager works jointly with recruiters and oversees the sourcing, interviewing and hiring processs of employees. As a recruiting manager your will make sure the recruiting strategies used by the team are up to date and help them implement new ones.",
    },

    candidates: {
      noCreatedDetails:
        "The applicant workflow is not yet created. So you cannot invite applicant to the job",
      active: "Active Applicants",
      disable: "Confirmation about disable the applicant",
      disableConfirm: "Are you sure you want to disable the applicant ?",
      total: "Total Applicants",
      invite: "Invite Applicants",
      noApplicant: "No Applicants Invited",
      invite: "Invite Applicants",
      noApplicantAsSearched:
        "There is no applicant invited as searched.Try to invite the applicant",
      noApplicantDetail:
        "There is no applicants added yet. Click on Invite applicant to invite the applicants for this job",
    },

    locations: {
      label: "Locations",
      invalidLocations: "Invalid Locations",
      add: "Add Location",
      noLocation: "No Location Added",
      isCorporate: "This is Corporate Address",
      corporateOffice: "This is a Corporate Office",
      addNewLocation: "Add new location",
      noLocations: "No Location found as searched!",
      noLocationsDetails:
        "You can add new location by clicking the button below.",
      saveLocation: "Save Location",
      noLocationAdded: "No Location Added",
      noLocationAddedDetails:
        "No locations added till now. Try to add locations manually and save them.",
      corporateAddressDelete: "Corporate address can't be deleted.",
      uploadLocationBulk: "Upload location in Bulk.",
      uploadMultipleLocations: "Upload multiple locations",
      addLocationManually: "Add locations manually",
      addMultipleInstruction:
        "Add multiple locations at once using a CSV template up to 100MB.",
      editLocation: "Edit Location",
      uploadLocations: "Upload Locations",
      manuallyAddLocation: "Manually Add Locations",
      manuallyInstructions:
        "Fill out details for each location you want to add.",
      locationsPerPage: "locations per page",
      deleteLocationConfirmation:
        "Are you sure that you want to delete this location?",
      deleteLocationConfirmationMsg:
        "Are you sure you would like to delete this Location?",
      deleteLocationSuccess: "Location deleted successfully.",
      atLeastOneocationRequired:
        "At least one location is required, you cannot delete this location.",
      discardLocationConfirmation: "Discard changes and continue?",
      usersPerPage: "users per page",

      templateModal: {
        keepInMindDetails:
          "Keep in mind the following while using the template.",
        columnHeadersRequired: "Column headers are required.",
        columnHeadersMustMatch:
          "Column header names must match with the sample download template.",
        instructions: "Instructions",
        downloadSampleLocation: "Download the sample Location Template.",
        downloadTemplate: "Download template",
        downloadAndMerge:
          "Download template Merge your data into the template.",
        uploadImportLocations: "Upload your file and import locations.",
      },
      errorCard: {
        correctErrors: "Please correct these errors and try again:",
        fieldRequired: "field is required for all rows.",
        row: "Row",
        the: "The",
        rowsDuplicateFound: "Rows duplicate found",
        followingErrorsOccurred:
          "Following Error(s) has been occurred during uploading process: Only the first 30 errors are shown.",
        continueWithInvalidData: "Continue with invalid data",
      },
    },
    lob: {
      lineOfBusiness: "Line of business",
      associateWithLob: "Associate with LOB",
    },

    files: {
      drop: "Drop your file here or Click to upload",
      maxSize: "You can upload upto 100MB. Download template file here",
      clickHere: "Click here ",
      toUpload: "to upload",
      dropFileOr: "Drop your file or ",
      fileHere: "file here",
      template: "template ",
      download: " Download ",
    },

    applicants: {
      invite: {
        inviteApplicants: "Invite Applicants",
        inviteTitle: "Invite Applicants to this job",
        inviteInfo:
          " Your applicants will get an email of this invitation and they can sign up and start using intelliante plaform.",
        addUser:
          "  Add user to roles (you can add multiple emails seperated with comma(,) )",
        invalidEmail: "The email is invalid",
        multipleEmail: "to upload the multiple email at once with CSV Files",
        selectEmail: "Please select atleast one email.",
        inviteConfirm: "Are you sure you want to invite all the applicants?",
        inviteConfirmAlreadyInvited:
          "These applicants have already been invited to this job and cannot be reinvited.",
        exclusionAlert: "Exclusion Alert",
        inviteOnlyInvited:
          "Applicants that are already invited in a job cannot be invited again in the same job. All the applicants that you have added have already been invited.",
        inviteInfo:
          "The applicants will get an email invitation. They can sign up/ sign in for the assessment.",
        remainingApplicantInviteMessage:
          "Do you want to invite remaining applicants to the job?",
        receiveInvite: "Your applicants will get an email of this invitation",
        startPlatform:
          "Applicants can signup and start using intelliante plaform.",
      },
    },

    confirmation: {
      paymentConfirmation: "Payment Confirmation",
    },

    thankMessage: {
      forChoosingIntelliante: "Thank you for choosing Intelliante",
    },

    jobs: {
      skillFinder:
        "After reviewing six government-sponsored occupational databases, our intelligent automation identified the following competencies as most important for your job.",
      skillReview: "Review these competencies and then click 'NEXT'.",
      editSkillReview: "Review these competencies",
      recommendedAssessments:
        "Rank the importance of these requirements for a new hire on the first day of work. We recommend discussing the rankings with internal experts who manage and supervise the job. ",
      skillRanking: "Click 'NEXT' after making your rankings.",
      editSkillRanking: "Click 'UPDATE' after making your rankings.",
      noCompetencies:
        "Currently there is no competencies are matching with this job type.",
      noRanking:
        "Currently there is no recommendedAssessments are matching with this job.",
      recommendedChange:
        "Use the arrows to move the most important requirement to the top and the least important to the bottom.",
      jobsPerPage: "Jobs per page",
      competenciesPePage: "Competencies per page",
      jobTitle: "Job Title *",
      jobDetails: "Job Details",
      applicantWorkflow: "Applicant Detail Workflow",
      testingWorkflow: "Testing Workflow",
      workflowRequiredPrefix: "Workflow must include",
      shouldNotPageBreak: "atleast one element between two page break",
      previewNotCreated:
        "The workflow for applicant detail is not yet created.",
      jobCreationDate: "Job Creation Date",
      lastUpdated: "Last Updated",
      jobType: "Job Type *",
      jobRequisitionNo: "Requisition number *",
      noRequisitionNO: "No Requisition Number",
      jobReview: "Review Job Information",
      updateChanges: "Update",
      updating: "Updating",
      jobReviewInfo:
        "Review the job you have recently created and publish if everything looks good",
      editJob: "Edit Job Details",
      edit: "Edit",
      onetJob: "Onet Jobs",
      jobType: "What type of job are you adding? *",
      communication_channel:
        "What is the job's main communication channel with the customer? *",
      workers_perform: "Where will workers perform the job? *",
      clearFilter: "Clear",
      createdDate: "Created Date",
      onetJob: "Onet Job *",
      onetInfo:
        " ONET jobs can help you find out the closest job title from ONET.",
      onetJobInfo:
        " The Occupational Information Network (ONET) is a free online database that contains hundreds of occupational definitions to help businesses and workforce development professionals to understand today's world of work.",
      jobDescription: "Job description",
      createJobDescription: "Job Description",
      fetchDescription: "Fetch Job description from oNet",
      selectOne: " Please select one of ONET job tag to fetch its description:",
      add: "Add new job",
      allJobs: "All Jobs",
      noJobsFound: "No Jobs Found",
      noJobsAdded: "No Jobs Added",
      reqNumberTaken: "Requisition number already taken",
      resultsFound: "{{count}} results found",
      jobAnalysisSurvey: "Job Analysis Survey",
      jobAnalysisSurveyInfo:
        " Based on the ONET Job title you selected, here are two survey link for you",
      taskSurvey: "Task Survey",
      workSurvey: "Work Survey",
      noJobsSearch:
        "We cannot find the jobs you are searching. Try to add new jobs",
      noJobsYet: "There are no jobs added yet. Try to add new jobs",
      withdraw: {
        label: "Withdraw Job",
        confirm: "Are you sure you want to withdraw the job?",
        message: "By clicking the withdraw button",
        applicantDisabled: "Applicant that are already invited are disabled",
        limitActions: "You can only view details but cannot take any actions",
        withrawSuccess: "Job withdrawn sucessfully",
      },

      delete: {
        confirm: "Are you sure that you want to delete this job",
        message: "By clicking the delete button",
        deletePermanently: "Your job will be delete permanently.",
        cannotRestore: "After deleting the job you cannot restore the job.",
        deleteSuccess: "Job Deleted successfully",
      },

      clone: {
        clone: "Clone ",
        job: " Job",
        desc_left: "The details will be copied from ",
        desc_right: " Job which you can adjust as per your need.",
        choose: "Please select the details that you want to get copied:",
        req_label: "Please add a unique requisition number while cloning jobs.",
        req_placeholder: "Job Requisition No*",
        cloneJob: "Clone",
        cloneField: "Check the fields you want to get cloned",
        req_no: "Requisition number ",
      },
    },

    checklist: {
      minLength: "Use 8 or more characters (14+ is better)",
      capital: "Include uppercase letters",
      lower: "Include lowercase letters",
      specialChar: "Include at least one Symbol",
      number: "Include at least one number",
      match: "Password and confirm password must match",
    },
    changePassword: {
      currentPass: "Current Password",
      currentPassword: "Please enter your current password",
      newPassword: "New Password",
      passwordError: "Please enter new password",
      confirmPasswordError: "Confirm password must match new password",
      passwordChangedSuccessfully: "Password changed successfully",
      incorrectCurrentPassword: "Incorrect current password",
    },

    workflowGuide: {
      fullName:
        "Full name is the first thing that should be known about the applicants. You cannot save the onboarding flow and move forward without including this section as some of its fields are required to get the applicant platform functional",
      contactDetails:
        "Contact Details is the second thing that should be known about the applicants. You cannot save the onboarding flow and move forward without including this section as some of its fields are required to get the applicant platform functional",
      pageBreak:
        "You can end the current page and begin the new  page by selecting this element. Just drag and drop the element and give a name to the page ",
      educationDetails:
        "Do you want to know about the academic history of applicants?",
      employmentHistory:
        "Do you want to know about the employment history of applicants?",
      skills:
        "The ONET database contains a set of skills required for any kind of job. Do you want to know what kind of skills the applicants have by letting them select the ONET Skills?",
      saveButton: "Click the button to save the workflow.",
      previewButton:
        "You can view how your applicants will see the onboarding workflow created by you.",
    },
    dropzone: {
      canNotAddForm: "You cannot add this element after a test.",
      canNotAddTask: "You cannot add this element before the form element.",
      dragAndDrop: "Drag and drop a component",
    },

    rolesPermission: {
      // Add Role
      successToAddRole: "Role added successfully",
      failedToAddRole: "Failed to add role",

      // Update Role
      successToUpdateRole: "Role updated successfully",
      failedToUpdateRole: "Failed to update role",

      // Delete Role
      successToDeleteRole: "Role deleted successfully",
      failedToDeleteRole: "Failed to delete role",
      cannotDeleteRole: "We can not delete the role which has lowest hierarchy",

      // Fetch Role Details
      failedToFetchRoleDetail: "Unable to fetch Role details",
    },

    userManagement: {
      // Add User
      successToAddUser: "User invited successfully",
      failedToAddUser: "Failed to invite user",

      // Update User
      successToUpdateUser: "User updated successfully",
      failedToUpdateUser: "Failed to update user",

      // Delete User
      successToDeleteUser: "User deleted successfully",
      failedToDeleteUser: "Failed to delete user",

      // Permission selection
      noPermissionSelected: "Please select atleast one permission",
    },
  },

  label: {
    applicant: "Applicant",
    addApplicant: "Add Applicant",
    launchSetup: "Launch Setup",
    sendVerificationCode: "Send Verification Code",
    otpVerify: "Verify Now",
    verifying: "Verifying",
    palette1: "Palette 1",
    palette2: "Palette 2",
    palette3: "Palette 3",
    palette4: "Palette 4",
    roles: {
      add: "Add Role",
      addNew: "Add New Roles",
    },
    login: "Login",
    label: {
      launchSetup: "Launch Setup",
      sendVerificationCode: "Send Verification Code",
      otpVerify: "Verify Now",
      companyDetail: "Company Detail",
      roles: {
        add: "Add Role",
        addNew: "Add New Roles",
      },

      users: {
        add: "Add user",
      },
    },

    tenKeyTest: {
      computerVision: "Computer Vision",
    },

    applicants: {
      sendInvitation: "Send Invitation",
      howMany: "how many Applicants?",
      inviteApplicants: "Invite Applicants",
      manualUpload: "Upload applicants in bulk",
      manualInvite: "Add applicants manually",
    },

    profile: {
      tellAboutYourself: "Tell us about yourself",
      fullName: "Full Name",
      whatShouldWeCall: "What should we call you when we talk?",
      jobTitle: "Current Job Title",
      phoneNumber: "Work Phone Number",
      uploadProfile: "Upload profile image",
      imageInfo: "The image must be a file of type: jpg, png, jpeg, gif, svg.",
      editProfile: "Edit Profile",
      uploadPicture: "Upload Picture",
      error: {
        enterName: "Enter your full name",
        enterJob: "Enter your current job",
        enterNickname: "Enter what should we call you when we talk",
        enterNumber: "Enter your work phone number",
      },
    },

    stepsIndicator: {
      jobDetails: "Job Details",
      applicantDetail: "Applicant Workflow",
      testingWorkflow: "Testing Workflow",
      workflowSetup: "Workflow Setup",
      inviteApplicant: "Invite Applicant",
      skillFinder: "Competencies",
      recommendedAssessments: "Recommended Assessments",
    },

    locations: {
      form: {
        zipPostal: "Zip/ Postal code *",
        postalCode: "Postal Code *",
        country: "Country *",
        state: "State *",
        city: "City *",
        officeName: "Office Name *",
        addressLine1: "Address Line 1 *",
        addressLine2: "Address Line 2",
      },
    },
    logoutDropdown: {
      editProfile: "Edit Profile",
      editCompanyDetails: "Edit Company Details",
      planAndBilling: "PLan and Billing",
      accountSettings: "Account Setting",
      manageRoles: "Manage Roles",
      logout: "Logout",
    },
    users: {
      email: "Email / Role",
      location: "Location",
      action: "Action",
    },
    rolesPermission: {},
  },

  modal: {
    otpSuccess: {
      createDashboard: "Let's create your dashboard",
    },
  },

  applicantSummaryReport: {
    jobTitle: "Job Title(s)",
    result: "Result",
    stage: "Stage",
    lineOFBusiness: "Line of Business",
    location: "Location",
    locations: "Location(s)",
    allLocations: "All Locations",
    allJobs: "All Jobs",
    searchLOB: "Search Line of Business",
    searchLocations: "Search Locations",
    kpi: "KPI",
    applicantName: "Applicant Name",
    job: " Job",
    jobs: " Job(s)",
    startDate: "Start Date",
    Stage: "Stage",
    result: "Result",
    noOption: "NA",
    applicantSummary: "Applicant Summary",
    noResultFound: "No Result Found",
    thereIsNoResult: "There is not result as search. Please try to",
    filterWith: "filter with different attribute",
    applicantSummaryReporter: "Applicant Summary Report",
    pleaseSelectThe: "Please select the attributes to view the",
    summaryReportOf: "summary report of applicants",
    inProgress: "In Progress",
    completed: "Completed",
    disabled: "Disabled",
    expired: "Expired",
    date: "Date",

    jobTitleAlt: "Job Title",
    resultAlt: "Results",
    action: "Action",
    applicantsPerPages: "Applications Per Pages",
  },

  workflow: {
    tenkeyConfig: "Ten Key configuration",
    typingTestConfig: "Typing Test Configuration",
    addATestTimer: "Add a test timer",
    tenkeyValidation:
      "Key should only contain numerical values, return key (↵), decimal point(.) and operators (+, -, *, /) as",
    typingTestValidation:
      "Text should not contain multiple paragraphs or numerical value as",
    pleaseEnter: "Please enter",
    your: "your",
    between: "between",
    to: "to",
    keys: "keys",
    words: "words",
    selectOneJob: "Please select atleast one option of the",
    selectOneOption: "Please select any option of the",
    transitionPage: {
      transitionImageDimensionError:
        "Transition image should be of at least 1380px width and 706px height",
      transitionImageSizeError: "Transition image size should be less than 5MB",
      transitionImageInfo: "Transition Image",
      invalidLength:
        "The transition text should not be more than 100 characters",
      textTitle: "Title",
      imageRequired: "Please upload the transition image",
    },
    tenKeyLastSymbolValidation: "Last key cannot be return symbol (↵) as",
    typingTestLastSymbolValidation: "Last character cannot be whitespace as",
    onlyWhiteSpaceNotAllowed: "The first character cannot be whitespace",
    maximumTenCharacters: "Please enter a maximum of 10 characters as",
  },
  analytics: {
    dontHaveAccess: "This feature is not available to you at this time.",
  },
  homePage: {
    applicantsInvited: "Applicants Invited",
    applicantsInProgress: "Applicants in Progress",
    applicantsCompleted: "Applicants Completed",
    completionRate: "Completion Rate",
    averageTestTime: "Average Test Time",
    inMinutes: "In minute(s)",
    chart: {
      daily: "Daily",
      weekly: "Weekly",
      monthly: "Monthly",
    },
    totalSummaryOfApplicants: "Applicant Summary",
    allCategories: "All Categories",
    invited: "Invited",
    inProgress: "In Progress",
    completed: "Completed",
    avhTestTime: "Avh Test Time",
  },
  company: {
    companyId: "Company ID",
    companyName: "Company Name",
    companySlug: "Company Slug",
    companyOwner: "Company Owner",
    onboardingAt: "Onboarded At",
    action: "Action",
    companyList: "Company List",

    searchCompanyName: "Search Company Name",
    searchCompanyOwner: "Search Company Owner",
    searchCompanySlug: "Search Company Slug",

    switchTo: "Swith to",
    company: "Company",
    location: "Location",
    locations: "Location(s)",
    allLocations: "All Locations",
    searchLocations: "Search Locations",
    noOption: "NA",
    applicantSummary: "Applicant Summary",
    noResultFound: "No Result Found",
    thereIsNoResult: "There is not result as search. Please try to",
    filterWith: "filter with different attribute",
    companyPerPages: "Companies Per Pages",
  },
};

export default { translation };
