import { ReactComponent as _billing } from "assets/images/_billing.svg";
import { ReactComponent as _branding } from "assets/images/_branding.svg";
import { ReactComponent as _general } from "assets/images/_general.svg";
import { ReactComponent as _location } from "assets/images/_location.svg";
import { ReactComponent as _rolesAndPermission } from "assets/images/_rolesandpermission.svg";
import { ReactComponent as _users } from "assets/images/_users.svg";
import { ReactComponent as tenKey } from "assets/images/10key.svg";
import activeListeningIcon from "assets/images/active_listening.svg";
import AddMoreIcon from "assets/images/add-more.svg";
import addIcon from "assets/images/add.svg";
import addMoreIcon from "assets/images/addmore.svg";
import addressDetails from "assets/images/addressDetails.svg";
import addUserIcon from "assets/images/adduser.svg";
import ageVerification from "assets/images/ageVerification.svg";
import alertIcon from "assets/images/alert-icon.svg";
import AnchorYellowLeft from "assets/images/anchorLeftYellow.svg";
import AnchorYellowRight from "assets/images/anchorRightYellow.svg";
import applicantDataNotFilled from "assets/images/applicant-data-not-filled.svg";
import { ReactComponent as ArmIconAlt } from "assets/images/arm.svg";
import { ReactComponent as arrowDown } from "assets/images/arrow-down.svg";
import { ReactComponent as arrowUp } from "assets/images/arrow-up.svg";
import BackArrow from "assets/images/arrowBack.svg";
import assignUser from "assets/images/assign.svg";
import assignUserIcon from "assets/images/assignUser.svg";
import availabilityForOtherJob from "assets/images/availabilityForOtherJob.svg";
import backIcon from "assets/images/back.svg";
import { ReactComponent as basicComputerSkill } from "assets/images/basiccomputerskill.svg";
import bellIcon from "assets/images/bell.svg";
import bigArrowIcon from "assets/images/big-arrow.svg";
import BlueInfoIcon from "assets/images/blue-info.svg";
import BottomCircleIcon from "assets/images/bottom-circle.svg";
import bottomIcon from "assets/images/bottom.svg";
import branchIcon from "assets/images/branch.svg";
import brandingIcon from "assets/images/branding.svg";
import briefEmploymentHistory from "assets/images/brief-employment-history.svg";
import { ReactComponent as businessWriting } from "assets/images/business-writing.svg";
import BusinessWritingIcon from "assets/images/businessWriting.svg";
import CancelSquareIcon from "assets/images/cancel-red.svg";
import cancelButtonIcon from "assets/images/cancelButton.svg";
import cancelLogoIcon from "assets/images/cancelLogo.svg";
import { ReactComponent as candyBoxIcon } from "assets/images/candy-box-icon.svg";
import { ReactComponent as chatAndVoiceIcon } from "assets/images/chat-and-voice.svg";
import { ReactComponent as chatBubbleIcon } from "assets/images/chatBubble.svg";
import checkbox from "assets/images/checkbox.svg";
import checkedRight from "assets/images/checked_right.svg";
import checkedWrong from "assets/images/checked_wrong.svg";
import checkedIcon from "assets/images/checked-icon.svg";
import Checked from "assets/images/Checked.svg";
import chevrondownIcon from "assets/images/chevron-down-icon.svg";
import chevronLeftCircleIcon from "assets/images/chevron-left-circle.svg";
import leftarrowIcon from "assets/images/chevron-left.svg";
import chevronRightIcon from "assets/images/chevron-right-icon.svg";
import { ReactComponent as clearIconAlt } from "assets/images/clear-x.svg";
import clearIcon from "assets/images/clear-x.svg";
import CloseGrayIcon from "assets/images/close-gray.svg";
import smallGrayCloseIcon from "assets/images/closeGraySmall.svg";
import column from "assets/images/column.svg";
import communicationIcon from "assets/images/communication.svg";
import company_assign_user from "assets/images/company_assign_user.svg";
import company_close from "assets/images/company_close.svg";
import company_delete from "assets/images/company_delete.svg";
import company_edit from "assets/images/company_edit.svg";
import completedIcon from "assets/images/completedIcon.svg";
import computerSkillIcon from "assets/images/computer_skills.svg";
import computerSkill from "assets/images/computerSkill.svg";
import contactDetail from "assets/images/contactDetail.svg";
import copyIcon from "assets/images/copy-icon.svg";
import copingIcon from "assets/images/copy.svg";
import copyWhite from "assets/images/copyWhite.svg";
import CrossCancelIcon from "assets/images/cross-cancel.svg";
import CancelIcon from "assets/images/cross.svg";
import CsvIcon from "assets/images/Csv.svg";
import customerServiceIcon from "assets/images/customer_service.svg";
import dashboardLogo from "assets/images/dashboardlogo.png";
import enrolledDate from "assets/images/date_icon.svg";
import date from "assets/images/date.svg";
import dateIcon from "assets/images/dateIcon.svg";
import dateOfBirth from "assets/images/dateOfBirth.svg";
import delIcon from "assets/images/del.svg";
import deleteIcon from "assets/images/delete.svg";
import designationIcon from "assets/images/designation_icon.svg";
import detailIcon from "assets/images/detail.svg";
import disability from "assets/images/disability.svg";
import disabledIconAlt from "assets/images/disable-icon.svg";
import disabledIcon from "assets/images/disabled-icon.svg";
import dndIcon from "assets/images/dnd_icon.svg";
import downloadIcon from "assets/images/download.svg";
import draftIcon from "assets/images/draft.svg";
import dropIcon from "assets/images/drop-here.svg";
import dropdownIcon from "assets/images/dropdown.svg";
import dropdown from "assets/images/dropdownField.svg";
import ErrorIcon from "assets/images/duplicate-error.svg";
import duplicate from "assets/images/duplicate.svg";
import duplicateAlertIcon from "assets/images/duplicatealert.svg";
import DustbinWhiteIcon from "assets/images/dustbin-white.svg";
import editIconNew from "assets/images/edit-non.svg";
import EditSquareIcon from "assets/images/edit-square.svg";
import editIcon from "assets/images/edit.svg";
import EditWhiteIcon from "assets/images/editWhite.svg";
import educationInformation from "assets/images/educationInformation.svg";
import emailIcon from "assets/images/email.svg";
import empathyIcon from "assets/images/empathy.svg";
import employerIcon from "assets/images/employer_icon.svg";
import employmentHistory from "assets/images/employmentHistory.svg";
import ErrorSmallIcon from "assets/images/error-small.svg";
import Warn from "assets/images/error500.svg";
import essentialSkills from "assets/images/essentialSkills.svg";
import expiredIcon from "assets/images/expired.svg";
import expiredIconAlt from "assets/images/expiredIcon.svg";
import externalLinkIcon from "assets/images/external-link-icon.svg";
import EyeOn from "assets/images/eye-on.svg";
import eyeSquareIcon from "assets/images/eye-square.svg";
import Eye from "assets/images/eye.svg";
import PreviewDarkIcon from "assets/images/eyes-dark.svg";
import fiCheckButton from "assets/images/fi_check-circle.svg";
import FileUpload from "assets/images/File-upload.svg";
import files from "assets/images/files.svg";
import { ReactComponent as filter_light } from "assets/images/filter-light.svg";
import filterIcon from "assets/images/filter.svg";
import filterLogo from "assets/images/filter.svg";
import fullName from "assets/images/fullName.svg";
import cogIcon from "assets/images/gear-icon.svg";
import greaterThanIcon from "assets/images/greaterthan.svg";
import heading from "assets/images/heading.svg";
import highestEducationAttainment from "assets/images/highest-education-attainment.svg";
import IIcon from "assets/images/i-icon.svg";
import image from "assets/images/image.svg";
import infoBlue from "assets/images/info_blue.svg";
import InfoWhiteIcon from "assets/images/info-white.svg";
import infoIcon from "assets/images/info.svg";
import infoBlueIcon from "assets/images/infoBlue.svg";
import inprogressIcon from "assets/images/inprogressIcon.svg";
import intellianteLogoHome from "assets/images/intelliante-logo.svg";
import intellianteLogo from "assets/images/intelliantelogo.png";
import Invalid from "assets/images/invalid.svg";
import InvalidLocation from "assets/images/InvalidLocation.svg";
import inviteWhiteIcon from "assets/images/invite-white.svg";
import inviteIcon from "assets/images/invite.svg";
import language from "assets/images/language.svg";
import layoutIcon from "assets/images/layout.svg";
import LeftArrow from "assets/images/LeftArrow.svg";
import lessthanIcon from "assets/images/lessthan.svg";
import LoaderAnimation from "assets/images/LoaderAnimation.png";
import locationsIcon from "assets/images/location_icon.svg";
import locationIcon from "assets/images/location.svg";
import { ReactComponent as lockedGray } from "assets/images/lockedGray.svg";
import LogoIcon from "assets/images/logo-white.svg";
import Logo from "assets/images/logo1.png";
import IconMail from "assets/images/mail.svg";
import Mail from "assets/images/mail.svg";
import { ReactComponent as mcc } from "assets/images/mcc.svg";
import MCCS from "assets/images/MCCS.svg";
import { ReactComponent as minimizeIcon } from "assets/images/minimize-icon.svg";
import moreIcon from "assets/images/more.svg";
import multiDotsIcon from "assets/images/multi-dots-icon.svg";
import multitaskingIcon from "assets/images/multitasking.svg";
import nextIcon from "assets/images/next.svg";
import { ReactComponent as noFilter } from "assets/images/no-filter.svg";
import { ReactComponent as noList } from "assets/images/no-list.svg";
import noneSelectIcon from "assets/images/none-select.svg";
import notFoundIcon from "assets/images/notfound.svg";
import LocationNotFoundLogo from "assets/images/NotFoundLogo.svg";
import OTPIcon from "assets/images/otp-lock.svg";
import { ReactComponent as overcommingAdversity } from "assets/images/overcoming-adversity.svg";
import overComingAdversity from "assets/images/OvercomingAdversity.svg";
import pageBreak from "assets/images/pageBreak.svg";
import paginationLeftArrow from "assets/images/pagination-left-arrow.svg";
import paginationRightArrow from "assets/images/pagination-right-arrow.svg";
import Pencil from "assets/images/pencil.svg";
import preview from "assets/images/preview.svg";
import previousIcon from "assets/images/previous-icon.svg";
import profilePic from "assets/images/profilepic.png";
import publishedIcon from "assets/images/published.svg";
import questionIcon from "assets/images/question.svg";
import questionMark from "assets/images/questionMark.svg";
import radioButton from "assets/images/radioButton.svg";
import removeIcon from "assets/images/remove.svg";
import RequiredIcon from "assets/images/Required.svg";
import { ReactComponent as responsiblility } from "assets/images/responsibility.svg";
import Responsibilty from "assets/images/Responsibilty.svg";
import resumePdf from "assets/images/resume-pdf.svg";
import resume from "assets/images/resume.svg";
import rollbackIcon from "assets/images/roll-back.svg";
import salesProficiencyIcon from "assets/images/sales_profiency.svg";
import searchIcon from "assets/images/search-icon.svg";
import searchLight from "assets/images/search-light.svg";
import section from "assets/images/section.svg";
import transitionPageIcon from "assets/images/section.svg";
import shareIcon from "assets/images/share.svg";
import Bg from "assets/images/sign-in.svg";
import BgIcon from "assets/images/signUpBg.svg";
import starIcon from "assets/images/star-icon.svg";
import { ReactComponent as starIconAlt } from "assets/images/star.svg";
import SuccessCircle from "assets/images/success-circle.svg";
import successIcon from "assets/images/success.svg";
import { ReactComponent as switchPortalIcon } from "assets/images/switch-portal.svg";
import technicalSupportIcon from "assets/images/technical_support.svg";
import tenKeyIcon from "assets/images/tenKeyTest.svg";
import termsAndConditions from "assets/images/terms-and-conditions.svg";
import textArea from "assets/images/textArea.svg";
import textInput from "assets/images/textInput.svg";
import { ReactComponent as tickIcon } from "assets/images/tick.svg";
import TickRight from "assets/images/tickRight.svg";
import TickWrong from "assets/images/tickWrong.svg";
import TimerIcon from "assets/images/timer.svg";
import TopCircleIcon from "assets/images/top-circle.svg";
import topIcon from "assets/images/top.svg";
import candidateCountIcon from "assets/images/totalcandidate.svg";
import trashIcon from "assets/images/trash-icon.svg";
import trashRed from "assets/images/trashRed.svg";
import { ReactComponent as typing } from "assets/images/typing.svg";
import typingtestIcon from "assets/images/typingTest.svg";
import UploadBulkIcon from "assets/images/upload-bulk.svg";
import UploadFileIcon from "assets/images/Upload-file.svg";
import userIcon from "assets/images/user_icon.svg";
import UserEmail from "assets/images/userEmail.svg";
import { ReactComponent as usersIcon } from "assets/images/users.svg";
import Valid from "assets/images/valid.svg";
import vectorIcon from "assets/images/Vector.svg";
import MailIcon from "assets/images/verifyMail.svg";
import { ReactComponent as versionIcon } from "assets/images/version-icon.svg";
import viewDetailIcon from "assets/images/view-detail.svg";
import { ReactComponent as viewReport } from "assets/images/view-report.svg";
import viewIcon from "assets/images/view.svg";
import viewmoreIcon from "assets/images/viewmore.svg";
import { ReactComponent as viewProfileIcon } from "assets/images/viewProfileIcon.svg";
import voluntaryIdentification from "assets/images/voluntaryIdentification.svg";
import Warning from "assets/images/warning.svg";
import withdrawIcon from "assets/images/withdraw.svg";
import workTime from "assets/images/work-time.svg";
import workAuthorization from "assets/images/workAuthorization.svg";
import { ReactComponent as workspace } from "assets/images/workspace.svg";
import writingCompositionIcon from "assets/images/writing_composition.svg";
import xCancel from "assets/images/xCancel.svg";
import xCircleCloseIcon from "assets/images/xCircle-icon.svg";

const ICONS = {
  assign: assignUser,
  usersIcon,
  starIconAlt,
  copy: copyIcon,
  trash: trashIcon,
  gear: cogIcon,
  xclose: xCircleCloseIcon,
  externalLink: externalLinkIcon,
  search: searchIcon,
  previous: previousIcon,
  "menu-dots": multiDotsIcon,
  "chevron-down": chevrondownIcon,
  "chevron-right": chevronRightIcon,
  files: files,
  alert: alertIcon,
  duplicatealert: duplicateAlertIcon,
  duplicate: duplicate,
  required: starIcon,
  checked: checkedIcon,
  disabled: disabledIcon,
  Multitasking: multitaskingIcon,
  "10 key test": tenKeyIcon,
  "Typing test": typingtestIcon,
  "Technical Support": technicalSupportIcon,
  "Sales Proficiency": salesProficiencyIcon,
  "Writing Composition": writingCompositionIcon,
  "Active Listening": activeListeningIcon,
  "Computer Skill": computerSkillIcon,
  "Computer vision": computerSkillIcon,
  "Customer Service": customerServiceIcon,
  "none-select": noneSelectIcon,
  back: backIcon,
  share: shareIcon,
  chatBubble: chatBubbleIcon,
  chatAndVoice: chatAndVoiceIcon,
  candyBox: candyBoxIcon,
  edit: editIcon,
  coping: copingIcon,
  view: viewIcon,
  more: moreIcon,
  adduser: addUserIcon,
  candidatecount: candidateCountIcon,
  greatthan: greaterThanIcon,
  lessthan: lessthanIcon,
  "duplicate-alert": duplicateAlertIcon,
  "multi-tasking": multitaskingIcon,
  ITK: tenKeyIcon,
  ITT: typingtestIcon,
  Empathy: empathyIcon,
  empathy: empathyIcon,
  "technical-support": technicalSupportIcon,
  "sales-proficiency": salesProficiencyIcon,
  IBW: BusinessWritingIcon,
  "active-listening": activeListeningIcon,
  IBCS: computerSkill,
  "customer-service": customerServiceIcon,
  "drop-here": dropIcon,
  location: locationIcon,
  branding: brandingIcon,
  detail: detailIcon,
  add: addIcon,
  back: backIcon,
  delete: deleteIcon,
  remove: removeIcon,
  "candidate-count": candidateCountIcon,
  "great-than": greaterThanIcon,
  "less-than": lessthanIcon,
  "chevron-left-circle": chevronLeftCircleIcon,
  "chevron-left": leftarrowIcon,
  next: nextIcon,
  filter: filterIcon,
  invite: inviteIcon,
  toppoint: topIcon,
  bottompoint: bottomIcon,
  viewmore: viewmoreIcon,
  viewdetail: viewDetailIcon,
  editNew: editIconNew,
  del: delIcon,
  email: emailIcon,
  "search-light": searchLight,
  "not-found": notFoundIcon,
  "invite-white": inviteWhiteIcon,
  Published: publishedIcon,
  Expired: expiredIcon,
  RollBack: rollbackIcon,
  Draft: draftIcon,
  dropdown: dropdownIcon,
  branch: branchIcon,
  success: successIcon,
  cancellogo: cancelLogoIcon,
  question: questionIcon,
  questionMark: questionMark,
  addmore: addMoreIcon,
  cancelbutton: cancelButtonIcon,
  "fi-check": fiCheckButton,
  dashboardlogo: dashboardLogo,
  filter: filterLogo,
  intelliantelogo: intellianteLogo,
  layout: layoutIcon,
  vector: vectorIcon,
  bell: bellIcon,
  profilepic: profilePic,
  clear: clearIcon,
  withdraw: withdrawIcon,
  TopCircle: TopCircleIcon,
  BottomCircle: BottomCircleIcon,
  Logo: Logo,
  OTP: OTPIcon,
  MailIcon: MailIcon,
  info: infoIcon,
  cancelIcon: CancelIcon,
  IconMail: IconMail,
  Bg: BgIcon,
  LogoIcon: LogoIcon,
  previewDark: PreviewDarkIcon,
  preview: preview,
  mail: Mail,
  valid: Valid,
  invalid: Invalid,
  Eye: Eye,
  EyeOn: EyeOn,
  bg_icon: Bg,
  info_blue: infoBlue,
  cancelSquare: CancelSquareIcon,
  LocationNotFoundIcon: LocationNotFoundLogo,
  UploadFileIcon: UploadFileIcon,
  UserEmail: UserEmail,
  CsvIcon: CsvIcon,
  LoaderAnimation: LoaderAnimation,
  AddMore: AddMoreIcon,
  UploadBulk: UploadBulkIcon,
  TickRight: TickRight,
  TickWrong: TickWrong,
  Pencil: Pencil,
  ArrowBack: BackArrow,
  crossCancel: CrossCancelIcon,
  editSquare: EditSquareIcon,
  errorIcon: ErrorIcon,
  closeGrayIcon: CloseGrayIcon,
  errorSmall: ErrorSmallIcon,
  FileUpload: FileUpload,
  SuccessCircle: SuccessCircle,
  eyeSquare: eyeSquareIcon,
  infoWhite: InfoWhiteIcon,
  LeftArrow: LeftArrow,
  InvalidLocation: InvalidLocation,
  DustbinWhiteIcon: DustbinWhiteIcon,
  EditWhiteIcon: EditWhiteIcon,
  GrayCloseIcon: smallGrayCloseIcon,
  BlueInfoIcon: BlueInfoIcon,
  AnchorYellowLeft: AnchorYellowLeft,
  AnchorYellowRight: AnchorYellowRight,
  Warning: Warning,
  Warn: Warn,
  IOAS: overComingAdversity,
  IRDS: Responsibilty,
  MCCS: MCCS,
  viewReport: viewReport,
  viewProfileIcon: viewProfileIcon,
  Info: IIcon,
  Download: downloadIcon,
  "Ten Key Data Entry Test": tenKey,
  "Basic Computer Skills": basicComputerSkill,
  "Business Writing": businessWriting,
  "MCCS Voice Service": mcc,
  "MCCS Voice Sales": mcc,
  "MCCS Voice Support": mcc,
  "MCCS Voice Collections": mcc,
  "MCCS Chat Service": mcc,
  "MCCS Chat Sales": mcc,
  "MCCS Chat Support": mcc,
  "Multi-Channel Customer Support": mcc,
  "Responsibility and Dependability Skills": responsiblility,
  "Typing Test": typing,
  "Overcoming Adversity": overcommingAdversity,
  "Workplace Communication": workspace,
  ICS: communicationIcon,
  tickIcon,
  bigArrow: bigArrowIcon,
  ArmIconAlt,
  noList,
  Text: textInput,
  "Text Area": textArea,
  Dropdown: dropdown,
  "Radio Button": radioButton,
  Date: date,
  DateIcon: dateIcon,
  "Check Box": checkbox,
  Address: addressDetails,
  "Contact Details": contactDetail,
  "Education History": educationInformation,
  Resume: workAuthorization,
  "Employment History": employmentHistory,
  "Essential Skills": essentialSkills,
  "Full Name": fullName,
  "Voluntary Self Identification": voluntaryIdentification,
  "Disablity Disclosure Form": disability,
  "Highest Education Attainment": highestEducationAttainment,
  "Brief employment history": briefEmploymentHistory,
  "Terms & Conditions": termsAndConditions,
  "When Can You Work?": workTime,
  Column: column,
  Image: image,
  "Page Break": pageBreak,
  Section: section,
  Headings: heading,
  BlueInfoIcon: infoBlueIcon,
  trashRed: trashRed,
  copyWhite: copyWhite,
  RequiredIcon: RequiredIcon,
  Checked: Checked,
  TimerIcon: TimerIcon,
  noFilter,
  clearIconAlt,
  versionIcon,
  expiredIconAlt,
  completedIcon,
  inprogressIcon,
  TimerIcon,
  noFilter,
  clearIconAlt,
  versionIcon,
  expiredIconAlt,
  completedIcon,
  inprogressIcon,
  minimizeIcon,
  disabledIconAlt,
  arrowDown,
  arrowUp,
  XCancel: xCancel,
  "Transition Page": transitionPageIcon,
  assignUserIcon,
  Resume: resume,
  ResumeIcon: resumePdf,
  "Availability to Start": dateIcon,
  "Date of Birth": dateOfBirth,
  "Age Verification": ageVerification,
  "Work Authorization": workAuthorization,
  "Availability for other Job?": availabilityForOtherJob,
  Language: language,
  enrolledDate,
  designationIcon,
  locationsIcon,
  employerIcon,
  userIcon,
  applicantDataNotFilled,
  LockedGrey: lockedGray,
  _location: _location,
  _branding: _branding,
  _rolesAndPermission: _rolesAndPermission,
  _billing: _billing,
  _general: _general,
  _users: _users,
  company_edit: company_edit,
  company_delete: company_delete,
  company_assign_user: company_assign_user,
  filter_light: filter_light,
  company_close: company_close,
  paginationLeftArrow: paginationLeftArrow,
  paginationRightArrow: paginationRightArrow,
  checked_right: checkedRight,
  checked_wrong: checkedWrong,
  dndIcon: dndIcon,
  intellianteLogoHome: intellianteLogoHome,
  switchPortalIcon,
};

export default ICONS;
