import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";

import { SIGN_IN } from "containers/Routes/routes.js";

import LocalStorageManager, { CONSTANTS } from "utils/localStorageManager";

import { AuthSetupContext } from "contextApi/AuthSetupContext";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isLoggedIn } = useContext(AuthSetupContext);

  // Add your own authentication on the below line.
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn || LocalStorageManager.get(CONSTANTS.ACCESS_TOKEN) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: SIGN_IN.INDEX, state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
