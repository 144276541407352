import { t } from "i18next";

export const isInputValid = (value) => value.trim().length;

export const isPasswordInputValid = (value) =>
  value?.trim().length ? undefined : t("auth.error.passwordEmptyMessage");

export const isConfirmPasswordInputValid = (value) =>
  value?.trim().length
    ? undefined
    : t("auth.error.confirmPasswordEmptyMessage");
