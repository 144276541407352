import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { SIGN_IN } from "containers/Routes/routes";

import ModalMail from "assets/images/recovery-success.svg";

const NewPasswordModal = ({ isVisible }) => {
  if (!isVisible) return null;

  const { t } = useTranslation();
  let history = useHistory();

  const handleModalSubmit = () => {
    history.push(SIGN_IN.INDEX);
  };

  // const handleModalLink = () => {
  //   window.location.replace(process.env.REACT_APP_APPLICANT_DASHBOARD_URL);
  // };

  return (
    <div className="modal fade show d-block" id="successModal">
      <div
        className="modal-dialog modal-dialog-centered position-relative"
        id="modal-dialog"
      >
        <div
          className="modal-content text-center position-absolute"
          id="modal-content"
        >
          <div className="modal-body">
            <img className="modal-title mb-4" src={ModalMail} />
            <h5>
              {t("auth.passwordRecovery.newPasswordCongratulationMessage")}
            </h5>
            <p>{t("auth.passwordRecovery.passwordCreationMessage")}</p>
          </div>
          <div className="modal-footer justify-content-center">
            <button
              type="button"
              className="btn text-uppercase"
              data-bs-dismiss="modal"
              onClick={handleModalSubmit}
            >
              {t("auth.passwordRecovery.letsGoToSignIn")}
            </button>
          </div>
          {/* <div>
            <p data-bs-dismiss="modal" onClick={handleModalLink}>
              {t("auth.signUp.createAnAccount")}
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default NewPasswordModal;
