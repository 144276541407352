import React from "react";

import ICONS from "constants/icons";

const Icon = ({ name, onClick, className }) => (
  <img
    src={ICONS[name]}
    className={`${className} ${onClick ? "cursor-pointer" : ""}`}
    onClick={onClick}
  />
);

export const IntIcon = ({ name, ...props }) => {
  const IconComponent = ICONS[name];

  return <IconComponent {...props} />;
};

export default Icon;
