import React from "react";

import { AuthSetup } from "./AuthSetupContext";
import OfflineContext from "./OfflineContext";

const GameContext = ({ children }) => (
  <OfflineContext>
    <AuthSetup>{children}</AuthSetup>
  </OfflineContext>
);

export default GameContext;
