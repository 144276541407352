export const colourStyles = {
  control: (styles) => ({
    ...styles,
    borderRadius: "6px",
    minWidth: 240,
    borderColor: "#778583",
    backgroundColor: "black",
    "&:hover": {
      borderColor: "#778583",
    },
    boxShadow: "none",
  }),
  indicatorSeparator: () => {},
  menu: (styles) => ({
    ...styles,
    backgroundColor: "black",
    color: "white",
    border: "1px solid #828282",
    maxHeight: "160px",
    position: "absolute",
    padding: "0",
    zIndex: "1000",
  }),
  menuList: (styles) => ({
    ...styles,
    marginLeft: "10px",
    padding: "0",
    maxHeight: "160px",
  }),
  option: (styles, state) => ({
    ...styles,
    backgroundColor: "transparent !important",
    fontSize: "13px",
    color: state.isSelected ? "#FAFEFD" : "#B8C1BF",
    padding: "0.75em 2.5em 0.75em 0.9375em",
    borderBottom: "1px solid #828282",
    whiteSpace: "wrap",
    position: "relative",
    cursor: "pointer",
  }),
  placeholder: (styles) => ({
    ...styles,
    fontSize: "13px",
  }),
  singleValue: (styles) => ({ ...styles, color: "white", fontSize: "13px" }),
  input: (styles) => ({ ...styles, color: "white" }),
  ValueContainer: (styles) => ({ ...styles, padding: "3px 8px" }),
};

export const selectStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? "rgba(63, 212, 160, 0.05)"
      : "transparent !important",
    color: state.isSelected ? "#FAFEFD" : "#B8C1BF",
    padding: "0.75em 2.5em 0.75em 0.9375em",
    borderBottom: "1px solid #45504D",
    textTransform: "capitalize",
    whiteSpace: "wrap",
    position: "relative",
    cursor: "pointer",
  }),
  control: (provided, state) => ({
    ...provided,
    minWidth: 240,
    backgroundColor: "transparent",
    borderColor: state.isFocused ? "#FAFEFD" : "#B8C1BF",
    borderColor: "#B8C1BF",
    boxShadow: "none",
    borderRadius: "0.625em",
    margin: "1.0625em 1.0625em",
    padding: "3px 0.4375em",
    lineHeight: "1",
  }),
  menu: () => ({
    boxShadow: "none",
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    color: "#FAFEFD !important",
  }),
};
