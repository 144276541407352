function validateEmail(email) {
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(String(email).toLowerCase());
}

const validatePassword = (password) => {
  /**
   * Validates for:
   * ***************
   * Minimum length 8
   * At least one number
   * At least one uppercase and lowercase character
   * At least one special character
   *
   * Analyzing the regex
   * ********************
   * (?=.*[A-Z]) -> At least one uppercase letter
   * (?=.*[a-z]) => At least one lowercase letter
   * (?=.*[!@#$%^&*]) -> At least one special character
   * (?=.*[0-9]) -> At least one numeric value
   * {8,} -> At least 8 characters long
   */

  const passwordRegex =
    /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9])(?=.*[a-z]).{8,}$/;

  return passwordRegex.test(String(password));
};

export { validateEmail, validatePassword };
