import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import NewPasswordApi from "api/ChangePasswordApi/ChangePasswordApi";

import Checklist from "components/ResetPassword/Checklist";
import PasswordToggle from "components/ResetPassword/PasswordToggle";
import Icon from "components/Shared/Icon";

import { WINDOW_TARGET_TYPE } from "constants/constants";

const Profile = () => {
  const { t } = useTranslation();

  let history = useHistory();

  const [strength, setStrength] = useState(null);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [passwordError, setPasswordError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const [success, setSuccess] = useState("");
  const [error, setError] = useState([]);
  const [checkAll, setCheckAll] = useState(false);

  const changePassword = async (e) => {
    e.preventDefault();

    if (!oldPassword?.length) {
      setPasswordError(t("message.changePassword.currentPassword"));
    } else {
      setPasswordError("");
    }
    if (!newPassword?.length) {
      setNewPasswordError(t("message.changePassword.passwordError"));
    } else {
      setNewPasswordError("");
    }
    if (!confirmPassword?.length) {
      setConfirmPasswordError(t("message.changePassword.confirmPasswordError"));
    } else {
      setConfirmPasswordError("");
    }

    const body = {
      user: {
        passwords: {
          current_password: oldPassword ?? "",
          password: newPassword ?? "",
          password_confirmation: confirmPassword ?? "",
        },
      },
    };

    try {
      const res = await NewPasswordApi.updatePassword(body);

      if (res?.status === 200) {
        setSuccess(t("message.changePassword.passwordChangedSuccessfully"));
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setError([]);
        history.goBack();
      }
    } catch (error) {
      const { data } = error?.response;

      if (data.errors) {
        if (data.errors.current_password) {
          setError((prevErrors) => [
            ...prevErrors,
            t("message.changePassword.incorrectCurrentPassword"),
          ]);
        } else if (data.errors.base) {
          setError((prevErrors) => [...prevErrors, data.errors.base[0]]);
        }
      }
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setSuccess("");
    }, 3000);

    return () => clearTimeout(timer);
  }, [success]);

  useEffect(() => {
    setError([]);
  }, [oldPassword, newPassword, confirmPassword]);

  const CheckPasswordStrength = ({ strength }) => {
    if (strength) {
      return <p className="error mb-2">{t("auth.signUp.notStrong")}</p>;
    } else return null;
  };

  return (
    <div className="col-sm-12 col-md-6 change-password-container px-2 px-md-5 mb-md-5 pb-md-5">
      <span className="change-password-head-text d-block">
        {t("common.changePassword")}
      </span>
      <div className="col-12">
        <p className="mb-4 text-error">{error[0]}</p>
        <div className="input-field form-floating mt-4">
          <PasswordToggle
            password={oldPassword}
            setPassword={setOldPassword}
            placeholder={t("message.changePassword.currentPass")}
            checkError={passwordError}
            passwordError={passwordError}
            strength={strength}
          />
        </div>

        <div className="avoid_info d-flex position-relative align-items-center">
          <p className="my-3">{t("auth.signUp.toolTip.pw")}</p>
          <div>
            <a
              className="cursor-pointer"
              data-tip="custom show"
              data-event="click focus"
              data-for="global"
            >
              <Icon className="ms-2" name="questionMark" />
            </a>
            <ReactTooltip
              id="global"
              aria-haspopup="true"
              role="example"
              place="right"
              globalEventOff="click"
            >
              <p className="fw-bold">
                {t("auth.signUp.toolTip.password.pwTitle")}
              </p>
              <ul className="list-unstyled">
                <li className="d-flex">
                  <div className="me-2">
                    <Icon name="info" />{" "}
                  </div>
                  <div>{t("auth.signUp.toolTip.password.tip1")}</div>
                </li>
                <li className="d-flex">
                  <div className="me-2">
                    <Icon name="info" />{" "}
                  </div>
                  <div>
                    {t("auth.signUp.toolTip.password.tip2")}
                    <a
                      target={WINDOW_TARGET_TYPE.BLANK}
                      href="https://en.wikipedia.org/wiki/Wikipedia:10,000_most_common_passwords"
                    >
                      {t("auth.signUp.toolTip.password.tip2_link")}
                    </a>
                  </div>
                </li>
                <li className="d-flex">
                  <div className="me-2">
                    <Icon name="info" />{" "}
                  </div>
                  <div>
                    {t("auth.signUp.toolTip.password.tip3")}
                    <a
                      target={WINDOW_TARGET_TYPE.BLANK}
                      href="https://haveibeenpwned.com/Passwords"
                    >
                      {t("auth.signUp.toolTip.password.tip3_link")}
                    </a>
                    {t("auth.signUp.toolTip.password.tip3_end")}
                  </div>
                </li>
                <li className="d-flex">
                  <div className="me-2">
                    <Icon name="info" />{" "}
                  </div>
                  <div>{t("auth.signUp.toolTip.password.tip4")}</div>
                </li>
                <li className="d-flex">
                  <div className="me-2">
                    <Icon name="info" />{" "}
                  </div>
                  <div>{t("auth.signUp.toolTip.password.tip5")}</div>
                </li>
              </ul>
            </ReactTooltip>
          </div>
        </div>

        <div className="input-field form-floating">
          <PasswordToggle
            password={newPassword}
            setPassword={setNewPassword}
            placeholder={t("message.changePassword.newPassword")}
            checkError={newPasswordError}
            passwordError={newPasswordError}
            strength={strength}
          />
        </div>

        <div className="col-12">
          <CheckPasswordStrength
            strength={strength < 3 && newPassword?.length > 0}
          />
        </div>

        <Checklist
          password={newPassword ?? ""}
          confirmPassword={confirmPassword ?? ""}
          setStrength={setStrength}
          checkAll={checkAll}
          setCheckAll={setCheckAll}
        />

        <div className="input-field form-floating mt-4">
          <PasswordToggle
            password={confirmPassword}
            setPassword={setConfirmPassword}
            placeholder={t("auth.signUp.confirmPassword")}
            checkError={confirmPasswordError}
            passwordError={confirmPasswordError}
            strength={strength}
          />
        </div>

        {success && (
          <>
            <p className="text-primary my-3">{success}</p>
          </>
        )}

        <div className="col-12 mt-4 text-end">
          <button
            className="btn btn-outline-secondary me-3 px-5 rounded-1 text-uppercase"
            onClick={() => history.goBack()}
          >
            {t("common.cancel")}
          </button>
          <button
            className="btn btn-secondary text-uppercase"
            onClick={changePassword}
          >
            {t("common.savePassword")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Profile;
